
import { defineComponent } from 'vue';
import { formatDistance } from 'date-fns';
import { DocumentationFieldType, DocumentationFieldTypeVariant, ItemWithAssociations } from '@/types';
import ItemPerformanceCard from '@/views/dashboard/overview/item/ItemPerformanceCard.vue';
import ItemPerformanceCardMaterialsInfoPoints from '@/views/dashboard/overview/item/ItemPerformanceCardMaterialsInfoPoints.vue';
import flattenMaterialComponentHierarchy from '@/util/helpers/flattenMaterialComponentHierarchy';

export default defineComponent({
    components: { ItemPerformanceCard, ItemPerformanceCardMaterialsInfoPoints },
    props: {
        item: {
            type: Object as () => ItemWithAssociations,
            required: true,
        },
        isPublic: {
            type: Boolean,
            default: false,
        },
        sortOrder: {
            type: Array as () => Array<string>,
            default: () => [
                'identifiers',
                'documents',
                'labels',
                'materials',
                'recycled-upcycled-content',
                'design-for-circularity',
                'durability',
                'co2',
                'degassing',
                'waste-generated',
                'general-info',
                'design',
                'model',
            ],
        },
        filter: {
            type: Array as () => Array<string>,
            default: () => [],
        },
    },
    emits: ['expand-card-clicked'],
    data() {
        return {
            NOT_AVAILABLE_PROPERTY: null,
        };
    },
    computed: {
        performanceCards() {
            let itemAge: string | null = this.NOT_AVAILABLE_PROPERTY;
            if (this.$props.item.signOff) {
                const difference = Math.abs(new Date(this.$props.item.signOff).getTime() - new Date().getTime());
                const differenceInYears = Math.floor(difference / (1000 * 60 * 60 * 24 * 365));
                itemAge = differenceInYears >= 1 ? (itemAge = `${differenceInYears} year${differenceInYears === 1 ? '' : 's'}`) : formatDistance(new Date(), new Date(this.$props.item.signOff));
            }
            const formattedItemAge = typeof itemAge === 'string' ? itemAge.charAt(0).toUpperCase() + itemAge.slice(1) : this.NOT_AVAILABLE_PROPERTY;
            let itemDimensions =
                this.$props.item.product.dimensionsDiameter && this.$props.item.product.dimensionsDiameterUnit
                    ? `${this.$props.item.product.dimensionsDiameter} ${this.$props.item.product.dimensionsDiameterUnit}<br/>`
                    : this.NOT_AVAILABLE_PROPERTY;
            itemDimensions =
                this.$props.item.product.dimensionsWidth &&
                this.$props.item.product.dimensionsWidthUnit &&
                this.$props.item.product.dimensionsHeight &&
                this.$props.item.product.dimensionsHeightUnit &&
                this.$props.item.product.dimensionsDepth &&
                this.$props.item.product.dimensionsDepthUnit
                    ? `${itemDimensions || ''} W: ${this.$props.item.product.dimensionsWidth} ${this.$props.item.product.dimensionsWidthUnit}, H: ${this.$props.item.product.dimensionsHeight} ${
                          this.$props.item.product.dimensionsHeightUnit
                      }, D: ${this.$props.item.product.dimensionsDepth} ${this.$props.item.product.dimensionsDepthUnit}`
                    : itemDimensions?.substring(0, itemDimensions!.length - 5) || this.NOT_AVAILABLE_PROPERTY;

            let scope;
            switch (this.$props.item.product.co2Scope) {
                case '1':
                    scope = '1';
                    break;
                case '2':
                    scope = `1 - 2`;
                    break;
                case '3':
                    scope = '1 - 3';
                    break;
                default:
                    scope = this.NOT_AVAILABLE_PROPERTY;
            }

            const results = [
                {
                    id: 'identifiers',
                    title: 'Identifiers',
                    icon: '/assets/icons/ic_identifiers.svg',
                    infoPoints: [
                        { id: 'number', title: 'Product number', content: this.$props.item.product.number || this.NOT_AVAILABLE_PROPERTY },
                        { id: 'ean', title: 'EAN ID', content: this.$props.item.product.GTIN || this.NOT_AVAILABLE_PROPERTY },
                        { id: 'commodity', title: 'Commodity code', content: this.$props.item.product.commodityCode || this.NOT_AVAILABLE_PROPERTY },
                    ] as any,
                },
            ];
            if (this.isPublic) {
                results.push({
                    id: 'documents',
                    title: 'Documents',
                    icon: '/assets/icons/ic_documents_active.svg',
                    infoPoints: this.$props.item.product.documentations
                        ?.filter((currentDocumentation) => {
                            return ![
                                DocumentationFieldType.Label,
                                DocumentationFieldType.Ownership,
                                DocumentationFieldType.Co2Documentation,
                                DocumentationFieldType.MaterialDocumentation,
                                DocumentationFieldType.DegassingDocumentation,
                                DocumentationFieldType.Circularity,
                                DocumentationFieldType.DesignLink,
                            ].includes(currentDocumentation.fieldType);
                        })
                        .map((currentDocumentation) => {
                            return {
                                id: `documentation-${currentDocumentation.id}`,
                                title: currentDocumentation.name,
                                content: currentDocumentation.url ? `<a href="${currentDocumentation.url}" target="_blank" class="underline">Go to document</a>` : this.NOT_AVAILABLE_PROPERTY,
                            };
                        }),
                });
            }
            results.push(
                {
                    id: 'labels',
                    title: 'Labels',
                    icon: '/assets/icons/ic_labels.svg',
                    infoPoints: this.$props.item.product.documentations
                        ?.filter((currentDocumentation) => currentDocumentation.fieldType === DocumentationFieldType.Label)
                        .map((currentDocumentation) => {
                            return {
                                id: `documentation-${currentDocumentation.id}`,
                                title: currentDocumentation.name,
                                content: currentDocumentation.url ? `<a href="${currentDocumentation.url}" target="_blank" class="underline">Go to label</a>` : this.NOT_AVAILABLE_PROPERTY,
                            };
                        }),
                },
                {
                    id: 'materials',
                    title: 'Materials',
                    icon: '/assets/icons/ic_materials.svg',
                    infoPoints: this.item.components
                        ?.flatMap((currentComponent) => {
                            return flattenMaterialComponentHierarchy(currentComponent);
                        })
                        .map((currentComponent) => {
                            return {
                                id: `material-${currentComponent.id}`,
                                title: currentComponent.id,
                                content: currentComponent.articleNo || this.NOT_AVAILABLE_PROPERTY,
                            };
                        }),
                },
                {
                    id: 'recycled-upcycled-content',
                    title: 'Re/upcycled content',
                    icon: '/assets/icons/ic_recycling.svg',
                    infoPoints: this.item.components
                        ?.flatMap((currentComponent) => {
                            return flattenMaterialComponentHierarchy(currentComponent);
                        })
                        .map((currentComponent) => {
                            return {
                                id: `material-${currentComponent.id}`,
                                title: currentComponent.articleNo,
                                content: currentComponent.recycle && currentComponent.recycleUnit ? `${currentComponent.recycle} ${currentComponent.recycleUnit}` : this.NOT_AVAILABLE_PROPERTY,
                            };
                        }),
                },
                {
                    id: 'design-for-circularity',
                    title: 'Design for circularity',
                    icon: '/assets/icons/ic_design_for_circularity.svg',
                    infoPoints: [
                        { id: 'design-for-reduce', title: 'Design for reduce', productKey: 'reducable', fieldTypeVariant: DocumentationFieldTypeVariant.Reduce },
                        { id: 'design-for-reuse', title: 'Design for reuse', productKey: 'reusable', fieldTypeVariant: DocumentationFieldTypeVariant.Reuse },
                        { id: 'design-for-recycle', title: 'Design for recycle', productKey: 'reusable', fieldTypeVariant: DocumentationFieldTypeVariant.Recycle },
                        { id: 'design-for-recover', title: 'Design for recover', productKey: 'recoverable', fieldTypeVariant: DocumentationFieldTypeVariant.Recover },
                    ].map((currentInfoPoint) => {
                        const result = {
                            id: currentInfoPoint.id,
                            title: currentInfoPoint.title,
                            content: this.$props.item.product.documentations
                                ?.filter(
                                    (currentDocumentation) => currentDocumentation.fieldType === DocumentationFieldType.Circularity && currentDocumentation.fieldTypeVariant === currentInfoPoint.fieldTypeVariant
                                )
                                .map((currentDocumentation) => {
                                    return currentDocumentation.url ? `<a href="${currentDocumentation.url}" target="_blank" class="underline">Link</a>` : this.NOT_AVAILABLE_PROPERTY;
                                })
                                .join('<br />') as string | null,
                        };
                        if (!result.content) {
                            result.content = (this.item.product as any)[currentInfoPoint.productKey] ? 'Yes (unverified)' : this.NOT_AVAILABLE_PROPERTY;
                        }
                        return result;
                    }),
                },
                {
                    id: 'durability',
                    title: 'Durability',
                    icon: '/assets/icons/ic_durability.svg',
                    infoPoints: [
                        {
                            id: 'production-year',
                            title: 'Year of production',
                            content: this.$props.item.signOff ? (this as any).$format(new Date(this.$props.item.signOff), 'dd.MM.yyyy') : this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'life-expectancy',
                            title: 'Life expectancy',
                            content:
                                this.$props.item.product.durability && this.$props.item.product.durabilityUnit
                                    ? `${this.$props.item.product.durability} ${this.$props.item.product.durabilityUnit}`
                                    : this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'age',
                            title: 'Age',
                            content: formattedItemAge,
                        },
                    ],
                },
                {
                    id: 'co2',
                    title: 'CO2',
                    icon: '/assets/icons/ic_co2.svg',
                    infoPoints: [
                        {
                            id: 'date',
                            title: 'Date',
                            content: this.$props.item.product.co2Date ? (this as any).$format(new Date(this.$props.item.product.co2Date), 'dd.MM.yyyy') : this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'method',
                            title: 'Method',
                            content: this.$props.item.product.co2Method || this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'scope',
                            title: 'Scope',
                            content: scope || this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'total',
                            title: 'Total CO<span style="font-size: 80%; vertical-align: -0.35em">2</span>-e',
                            content:
                                this.$props.item.product.co2Total && this.$props.item.product.co2TotalUnit
                                    ? `${this.$props.item.product.co2Total} ${this.$props.item.product.co2TotalUnit}`
                                    : this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'validation',
                            title: 'Validation',
                            content:
                                this.$props.item.product.documentations
                                    ?.filter((currentDocumentation) => currentDocumentation.fieldType === DocumentationFieldType.Co2Documentation)
                                    .map((currentDocumentation) => {
                                        return currentDocumentation.url ? `<a href="${currentDocumentation.url}" target="_blank" class="underline">Documentation</a>` : this.NOT_AVAILABLE_PROPERTY;
                                    })
                                    .join('<br />') || this.NOT_AVAILABLE_PROPERTY,
                        },
                    ],
                },
                {
                    id: 'degassing',
                    title: 'Degassing',
                    icon: '/assets/icons/ic_degassing.svg',
                    infoPoints: [
                        {
                            id: 'class',
                            title: 'Degassing class',
                            content: this.$props.item.product.degassingClass || this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'formaldehyde',
                            title: 'Formaldehyde',
                            content:
                                typeof this.$props.item.product.formaldehyde === 'number' && this.$props.item.product.formaldehydeUnit
                                    ? `${this.$props.item.product.formaldehyde} ${this.$props.item.product.formaldehydeUnit}`
                                    : this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'tvoc',
                            title: `TVOC${this.$props.item.product.TVOCNumber || ''}`,
                            content:
                                typeof this.$props.item.product.TVOC === 'number' && this.$props.item.product.TVOCUnit
                                    ? `${this.$props.item.product.TVOC} ${this.$props.item.product.TVOCUnit}`
                                    : this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'validation',
                            title: 'Validation',
                            content:
                                this.$props.item.product.documentations
                                    ?.filter((currentDocumentation) => currentDocumentation.fieldType === DocumentationFieldType.DegassingDocumentation)
                                    .map((currentDocumentation) => {
                                        return currentDocumentation.url ? `<a href="${currentDocumentation.url}" target="_blank" class="underline">Documentation</a>` : this.NOT_AVAILABLE_PROPERTY;
                                    })
                                    .join('<br />') || this.NOT_AVAILABLE_PROPERTY,
                        },
                    ],
                },
                {
                    id: 'waste-generated',
                    title: 'Waste generated',
                    icon: '/assets/icons/ic_waste_generated.svg',
                    infoPoints: [
                        {
                            id: 'waste',
                            title: 'Waste',
                            content:
                                typeof this.$props.item.product.waste === 'number' && this.$props.item.product.wasteUnit
                                    ? `${this.$props.item.product.waste} ${this.$props.item.product.wasteUnit}`
                                    : this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'waste-description',
                            title: 'Waste description',
                            content: this.$props.item.product.wasteDescription || this.NOT_AVAILABLE_PROPERTY,
                        },
                    ],
                },
                {
                    id: 'general-info',
                    title: 'General info',
                    icon: '/assets/icons/ic_general_info.svg',
                    infoPoints: [
                        {
                            id: 'category',
                            title: 'Category',
                            content: (this.$props.item.product as any).category?.name || this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'primary-materials',
                            title: 'Primary materials',
                            content:
                                `${
                                    this.$props.item.product.primaryMaterial && this.$props.item.product.primaryMaterialVariant
                                        ? `${this.$props.item.product.primaryMaterial} ${this.$props.item.product.primaryMaterialVariant}`
                                        : ''
                                }${
                                    this.$props.item.product.secondaryMaterial && this.$props.item.product.secondaryMaterialVariant
                                        ? `<br />${this.$props.item.product.secondaryMaterial} ${this.$props.item.product.secondaryMaterialVariant}`
                                        : ''
                                }` || this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'weight',
                            title: 'Weight',
                            content:
                                typeof this.$props.item.product.weight && this.$props.item.product.weightUnit
                                    ? `${this.$props.item.product.weight} ${this.$props.item.product.weightUnit}`
                                    : this.NOT_AVAILABLE_PROPERTY,
                        },
                        {
                            id: 'dimensions',
                            title: 'Dimensions',
                            content: itemDimensions,
                        },
                    ],
                },
                {
                    id: 'design',
                    title: 'Design',
                    icon: '/assets/icons/ic_design.svg',
                    infoPoints: [
                        {
                            id: 'design',
                            title: 'Designer',
                            content: this.$props.item.product.designerName,
                        },
                        {
                            id: 'design-year',
                            title: 'Design year',
                            content: this.$props.item.product.designYear,
                        },
                        {
                            id: 'homepage',
                            title: 'Homepage',
                            content: this.$props.item.product.designUrl ? `<a href="${this.$props.item.product.designUrl}" target="_blank" class="underline">Homepage</a>` : this.NOT_AVAILABLE_PROPERTY,
                        },
                    ],
                },
                {
                    id: 'model',
                    title: 'Model',
                    icon: '/assets/icons/ic_model.svg',
                    infoPoints: [
                        {
                            id: 'product',
                            title: 'Product model',
                            content: this.$props.item.product.name,
                        },
                        {
                            id: 'description',
                            title: 'Description',
                            content: 'n/a',
                        },
                        {
                            id: 'link',
                            title: 'Link',
                            content: this.$props.item.product.url ? `<a href="${this.$props.item.product.url}" target="_blank" class="underline">Product page</a>` : this.NOT_AVAILABLE_PROPERTY,
                        },
                    ],
                }
            );
            return results
                .filter((currentResult) => !this.filter.includes(currentResult.id))
                .sort((a, b) => {
                    return this.sortOrder.indexOf(a.id) - this.sortOrder.indexOf(b.id);
                });
        },
    },
});
