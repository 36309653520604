
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import DashboardContentLayout from '@/components/layouts/dashboard/content/DashboardContentLayout.vue';
import CompanyUserForm from '@/views/dashboard/settings/CompanyUserForm.vue';
import { useMainStore, useUserStore } from '@/plugins/store';
import { Profile, User } from '@/types';

export default defineComponent({
    components: { DashboardContentLayout, CompanyUserForm },
    computed: {
        ...mapState(useMainStore, ['profile']),
        ...mapState(useUserStore, ['companyUsers']),
        activeCompanyUser(): User | null {
            return (
                this.companyUsers?.find((currentCompanyUser) => {
                    return `${currentCompanyUser.id}` === this.$route.params.id;
                }) || null
            );
        },
    },
    watch: {
        profile: {
            immediate: true,
            deep: true,
            handler(newValue: Profile) {
                if (!newValue || !newValue.companyId) {
                    return;
                }
                this.getCompanyUsers(newValue.companyId);
            },
        },
    },
    created() {
        this.getProfile();
    },
    methods: {
        ...mapActions(useUserStore, ['getCompanyUsers']),
        ...mapActions(useMainStore, ['getProfile']),
    },
});
