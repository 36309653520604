import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "flex w-full relative" }
const _hoisted_3 = { class: "flex w-full justify-center space-x-4" }
const _hoisted_4 = { class: "absolute right-0 top-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericTable = _resolveComponent("GenericTable")!
  const _component_AisHits = _resolveComponent("AisHits")!
  const _component_AisPagination = _resolveComponent("AisPagination")!
  const _component_AisHitsPerPage = _resolveComponent("AisHitsPerPage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AisHits, null, {
      default: _withCtx(({ items }) => [
        _createVNode(_component_GenericTable, {
          headers: _ctx.headers,
          items: items,
          "clickable-rows": "",
          onItemClicked: _ctx.onItemClicked
        }, null, 8, ["headers", "items", "onItemClicked"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AisPagination, { "class-names": {
                        'ais-Pagination': 'my-auto',
                        'ais-Pagination-list': 'space-x-2 flex h-full',
                        'ais-Pagination-link': 'px-3 pt-1 pb-2 hover:bg-green-300/20 block my-auto border h-9 aspect-square text-center rounded-full',
                    } })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AisHitsPerPage, {
          items: [
                        { label: '5 hits per page', value: 5, default: true },
                        { label: '25 hits per page', value: 25 },
                        { label: '50 hits per page', value: 50 },
                        { label: '100 hits per page', value: 100 },
                    ],
          "class-names": {
                        'ais-HitsPerPage': 'border-default rounded-md',
                        'ais-HitsPerPage-select': 'hits-per-page-select',
                    }
        })
      ])
    ])
  ]))
}