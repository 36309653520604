
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useMainStore } from '@/plugins/store';
import DashboardContentLayout from '@/components/layouts/dashboard/content/DashboardContentLayout.vue';
import ProductFilters from '@/views/dashboard/overview/products/filters/ProductFilters.vue';
import ProductsGrid from '@/views/dashboard/overview/products/ProductsGrid.vue';
import useAlgoliaHistoryMode from '@/util/composables/useAlgoliaHistoryMode';

export default defineComponent({
    components: { DashboardContentLayout, ProductFilters, ProductsGrid },
    setup() {
        return {
            ...useAlgoliaHistoryMode('products', /\/products($|\?|\/\?|\/$)/, false),
        };
    },
    computed: {
        ...mapState(useMainStore, ['searchClient', 'profile']),
    },
});
