import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompaniesTable = _resolveComponent("CompaniesTable")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, { heading: "Companies" }, {
    default: _withCtx(() => [
      _createVNode(_component_CompaniesTable)
    ]),
    _: 1
  }))
}