import axios from 'axios';
import { createToastInterface } from 'vue-toastification';
import * as nprogress from 'nprogress';
import ToastOptions from '@/plugins/toastification';
import { ApiClientMessage, LoggingLevel, RequestOptions } from '@/types';
import { auth } from '@/plugins/firebase';

export default class ApiClient {
    private static toast = createToastInterface(ToastOptions);

    private static BASE_URL = `${process.env.VUE_APP_API_SERVER_URL}`;

    private static numberOfOngoingRequestsWithLoadingIndicator = 0;

    private static config = {
        headers: {
            ...(Boolean(sessionStorage.getItem('impersonate_user')) && { 'impersonate-user': sessionStorage.getItem('impersonate_user') }),
        } as { 'x-access-token': string; 'impersonate-user': string },
    };

    static async send(message: ApiClientMessage) {
        const messageOptions = message.options || {};
        this.startLoadingIndicator(messageOptions);

        ApiClient.log('Calling endpoint = ', `${message.method.toLocaleUpperCase()} ${ApiClient.BASE_URL}/${message.url}`);
        if (message.url.includes('profile')) {
            ApiClient.log((await auth.currentUser?.getIdToken()) || '');
        }
        if (['put', 'patch', 'post'].includes(message.method.toLocaleLowerCase())) {
            ApiClient.log('With data = ', JSON.stringify(message.payload));
        }

        const headers = { ...this.config.headers };
        if (!message.options?.skipAuthCheck) {
            headers['x-access-token'] = (await auth.currentUser?.getIdToken()) || '';
        }

        const requestObject = {
            method: message.method,
            url: `${this.BASE_URL}/${message.url}`,
            data: message.payload,
            headers,
        };
        if (message.options?.responseType) {
            (requestObject as any).responseType = message.options.responseType;
        }
        try {
            const response = await axios.request(requestObject);
            ApiClient.log(`Response from = ${message.url}`, response);
            this.clearLoadingIndicator(messageOptions);
            return response.data;
        } catch (error: any) {
            this.clearLoadingIndicator(messageOptions);
            if (!messageOptions.skipErrorReporting) {
                // TODO: Update generic error text
                this.toast.error('Something went wrong!');
            }
            throw error;
        }
    }

    public static setAuthToken(authToken: string) {
        this.config.headers['x-access-token'] = authToken;
    }

    private static startLoadingIndicator(options: RequestOptions) {
        if (!options.skipLoadingIndicator) {
            this.numberOfOngoingRequestsWithLoadingIndicator++;
            nprogress.start();
        }
    }

    private static clearLoadingIndicator(options: RequestOptions) {
        if (!options.skipLoadingIndicator) {
            this.numberOfOngoingRequestsWithLoadingIndicator--;
            if (this.numberOfOngoingRequestsWithLoadingIndicator < 1) {
                nprogress.done();
            }
        }
    }

    private static log(message?: any, ...optionalParams: any[]) {
        if (process.env.VUE_APP_LOGGING_LEVEL === LoggingLevel.Debug) {
            console.log(message, ...optionalParams);
        }
    }
}
