import { defineStore } from 'pinia';
import { ApiRepository } from '@/plugins/api/ApiRepository';
import { ItemWithAssociations, ItemWithCompany, MaterialCategory, ProductCategory, Product, ProductWithReferences } from '@/types';

const useOverviewStore = defineStore('overview', {
    state: () => ({
        materialCategories: null as null | MaterialCategory[],
        productCategories: null as null | ProductCategory[],
        activeProduct: null as null | Product, // Used when navigating from a product to the list of its items
        items: null as null | ItemWithCompany[],
    }),
    actions: {
        getMaterialCategories(bustCache = false): Promise<MaterialCategory[]> {
            return ApiRepository.GetMaterialCategories({ cacheOptions: { store: this, key: 'materialCategories', bustCache } });
        },
        getProductCategories(bustCache = false): Promise<ProductCategory[]> {
            return ApiRepository.GetProductCategories({ cacheOptions: { store: this, key: 'productCategories', bustCache } });
        },
        getItem(id: string): Promise<ItemWithAssociations> {
            return ApiRepository.GetItem({ payload: { id } });
        },
        getPublicItem(tagId: string): Promise<ItemWithAssociations> {
            return ApiRepository.GetPublicItem({ payload: { tagId }, options: { skipErrorReporting: true, skipAuthCheck: true } });
        },
        getProduct(id: string): Promise<ProductWithReferences> {
            return ApiRepository.GetProduct({ payload: { id } });
        },
    },
});

export { useOverviewStore };
