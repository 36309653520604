
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { mapActions, mapState } from 'pinia';
import UserForm from '@/views/dashboard/settings/UserForm.vue';
import { User, UserForm as UserFormType } from '@/types';
import { useMainStore, useUserStore } from '@/plugins/store';

export default defineComponent({
    components: { UserForm },
    props: {
        activeCompanyUser: {
            type: Object as () => User | null,
            default: null,
        },
    },
    data() {
        return {
            toast: useToast(),
        };
    },
    computed: {
        ...mapState(useMainStore, ['profile']),
    },
    methods: {
        ...mapActions(useUserStore, ['editUser', 'getCompanyUsers']),
        async onSubmit(form: UserFormType) {
            if (this.activeCompanyUser) {
                await this.editUser(this.activeCompanyUser.id, form.firstName, form.lastName, form.phone);
                this.toast.success('User edited');
                if (this.profile && this.profile.companyId) {
                    this.getCompanyUsers(this.profile.companyId, true);
                } else {
                    this.toast.error("Couldn't fetch users again after update. Please reload page if you want the latest changes.");
                }
            } else {
                this.toast.error("Can't find user to edit");
            }
        },
    },
});
