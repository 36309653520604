import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemEventHistory = _resolveComponent("ItemEventHistory")!
  const _component_ItemDataHistory = _resolveComponent("ItemDataHistory")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ItemEventHistory, { item: _ctx.item }, null, 8, ["item"]),
    (_ctx.showDataHistory || _ctx.isSuperAdmin)
      ? (_openBlock(), _createBlock(_component_ItemDataHistory, {
          key: 0,
          item: _ctx.item
        }, null, 8, ["item"]))
      : _createCommentVNode("", true)
  ]))
}