import { defineStore } from 'pinia';
import { ApiRepository } from '@/plugins/api/ApiRepository';
import { ExcelImportType, Import, ImportTemplate, RequestOptions, UploadDataImportResults } from '@/types';
import downloadFile from '@/util/helpers/downloadFile';

const useImportsStore = defineStore('imports', {
    state: () => ({
        imports: null as null | Import[],
        importTemplates: null as null | ImportTemplate[],
    }),
    actions: {
        getImports(bustCache = false): Promise<Import[]> {
            return ApiRepository.GetImports({ cacheOptions: { store: this, key: 'imports', bustCache } });
        },
        getImportTemplates(bustCache = false): Promise<ImportTemplate> {
            return ApiRepository.GetImportTemplates({ cacheOptions: { store: this, key: 'importTemplates', bustCache } });
        },
        async downloadTemplate(template: ImportTemplate): Promise<void> {
            const response = await ApiRepository.DownloadTemplate({ payload: { id: template.id } });
            const blob = new Blob([response]);
            const fileName = template.filePath.split('/').slice(-1).join('');
            downloadFile(blob, fileName);
        },
        async downloadImport(currentImport: Import): Promise<void> {
            const response = await ApiRepository.DownloadImport({ payload: { id: currentImport.id } });
            const blob = new Blob([response]);
            const fileName = currentImport.filePath.split('_').slice(1).join('');
            downloadFile(blob, fileName);
        },
        async uploadDataImport(
            payload: { file?: File; filePath?: string; type: ExcelImportType },
            options?: RequestOptions
        ): Promise<{ success: boolean; errors?: any; filePath?: string; results?: UploadDataImportResults }> {
            if (payload.filePath) {
                return ApiRepository.UploadDataImport({ payload: { filePath: payload.filePath, type: payload.type }, options });
            }
            if (payload.file) {
                const formData = new FormData();
                formData.append('upload', payload.file);
                formData.append('type', payload.type);
                return ApiRepository.UploadDataImport({ payload: formData, options });
            }
            return { success: false };
        },
    },
});

export { useImportsStore };
