
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        source: {
            type: String,
            required: true,
        },
    },
    computed: {
        embedData(): { provider: string; embedId: string } | null {
            if (this.source.includes('youtube')) {
                const embedMatch = this.source.match(/watch\?v=(.*)&?/);
                if (embedMatch && embedMatch.length > 1) {
                    return { provider: 'youtube', embedId: embedMatch[1] };
                }
                return null;
            }
            if (this.source.includes('vimeo')) {
                const embedMatch = this.source.match(/vimeo\.com\/([a-zA-Z0-9]+)(?:\/|\?|$)/);
                if (embedMatch && embedMatch.length > 1) {
                    return { provider: 'vimeo', embedId: embedMatch[1] };
                }
                return null;
            }
            if (this.source.includes('.mp4')) {
                return { provider: 'mp4', embedId: this.source };
            }
            return null;
        },
    },
});
