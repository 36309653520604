import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-6" }
const _hoisted_2 = { class: "pt-6" }
const _hoisted_3 = { class: "pt-6" }
const _hoisted_4 = {
  key: 1,
  class: "border-t-default pt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyProfileForm = _resolveComponent("CompanyProfileForm")!
  const _component_CompanyUsersTable = _resolveComponent("CompanyUsersTable")!
  const _component_PersonalProfileForm = _resolveComponent("PersonalProfileForm")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isCompanyAdmin)
      ? (_openBlock(), _createBlock(_component_Tabs, {
          key: 0,
          "parent-hash-route-name": "Settings",
          "create-hash-routes": "",
          tabs: _ctx.tabs,
          "default-active-index": 0,
          animate: ""
        }, {
          "content-company": _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_CompanyProfileForm)
            ])
          ]),
          "content-users": _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_CompanyUsersTable)
            ])
          ]),
          "content-profile": _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_PersonalProfileForm)
            ])
          ]),
          _: 1
        }, 8, ["tabs"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_PersonalProfileForm)
        ]))
  ]))
}