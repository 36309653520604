
import { mapState } from 'pinia';
import { defineComponent, ref } from 'vue';
import { useMainStore } from '@/plugins/store';
import ClickOutside from '@/util/directives/ClickOutside';
import useEscapable from '@/util/composables/useEscapable';

export default defineComponent({
    directives: { ClickOutside },
    setup(props, context) {
        const expanded = ref(false);
        const { onUnmounted } = useEscapable(() => {
            expanded.value = false;
        });
        return { onUnmounted, expanded };
    },
    data() {
        return {
            inputValue: '',
        };
    },
    computed: {
        ...mapState(useMainStore, ['searchClient', 'profile']),
    },
    methods: {
        onButtonClicked() {
            this.expanded = !this.expanded;
            if (this.expanded) {
                this.$nextTick(() => {
                    (this.$refs['input-element'] as HTMLInputElement).focus();
                });
            } else {
                (this.$refs['input-element'] as HTMLInputElement).value = '';
            }
        },
        onInputChanged(event: any, refine: CallableFunction) {
            this.inputValue = event.currentTarget.value;
            refine(event.currentTarget.value);
        },
    },
});
