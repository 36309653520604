
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import CustomSelect from '@/components/ui/CustomSelect.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import { UserRole, Profile, User } from '@/types';
import formatUserRole from '@/util/helpers/formatUserRole';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';

export default defineComponent({
    components: { CustomInput, CustomButton, CustomSelect, ConfirmationModal },
    props: {
        user: {
            type: Object as () => User | Profile,
            default: null,
        },
        canEditRole: {
            type: Boolean,
            default: false,
        },
        submitButtonText: {
            type: String,
            default: '',
        },
        confirmationModalHeader: {
            type: String,
            default: 'Edit user?',
        },
        confirmationModalBody: {
            type: String,
            default: 'Are you sure you wish to edit this user?',
        },
    },
    emits: ['submit'],
    data() {
        return {
            toast: useToast(),
            form: {
                firstName: '',
                lastName: '',
                role: null as null | { text: string; value: string | null },
                email: '',
                phone: '',
            },
            PICKABLE_ROLES: [
                { text: formatUserRole(UserRole.CompanyAdmin), value: UserRole.CompanyAdmin },
                { text: formatUserRole(UserRole.Member), value: UserRole.Member },
            ],
        };
    },
    watch: {
        user: {
            immediate: true,
            deep: true,
            handler(newValue: Profile) {
                if (!newValue) {
                    return;
                }
                this.form.firstName = newValue.firstName || '';
                this.form.lastName = newValue.lastName || '';
                this.form.role = { text: formatUserRole(newValue.role), value: newValue.role };
                this.form.phone = newValue.phone || '';
                this.form.email = newValue.email;
            },
        },
    },
    methods: {
        async onSubmit() {
            this.$emit('submit', { ...this.form, role: this.form.role?.value || null });
        },
    },
});
