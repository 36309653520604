
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';
import DashboardContentLayout from '@/components/layouts/dashboard/content/DashboardContentLayout.vue';
import Import from '@/views/dashboard/import/data/Import.vue';
import TagsCompanyTableVue from '@/views/dashboard/import/tags/TagsCompanyTable.vue';
import { ExcelImportType } from '@/types';
import { useCompaniesStore } from '@/plugins/store';

export default defineComponent({
    components: { DashboardContentLayout, Import, TagsCompanyTableVue },
    data() {
        return {
            IMPORT_TYPE: ExcelImportType.Tags,
        };
    },
    methods: {
        ...mapActions(useCompaniesStore, ['getCompanies']),
        onImportSuccess() {
            this.getCompanies(true);
        },
    },
});
