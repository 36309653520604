export enum ProductFacet {
    Name = 'name',
    Number = 'number',
    PrimaryMaterial = 'primaryMaterial',
    PrimaryMaterialVariant = 'primaryMaterialVariant',
    SecondaryMaterial = 'secondaryMaterial',
    SecondaryMaterialVariant = 'secondaryMaterialVariant',
    Model = 'model',
    Description = 'description',
    DesignerName = 'designerName',
    DesignYear = 'designYear',
    DesignUrl = 'designUrl',
    GTIN = 'GTIN',
    CommodityCode = 'commodityCode',
    Co2Date = 'co2Date',
    Co2Total = 'co2Total',
    DegassingClass = 'degassingClass',
    Formaldehyde = 'formaldehyde',
    TVOC = 'TVOC',
    TVOCNumber = 'TVOCNumber',
    Durability = 'durability',
    Waste = 'waste',
    WasteDescription = 'wasteDescription',
    Reusable = 'circularity.reusable',
    Reducable = 'circularity.reducable',
    Recyclable = 'circularity.recyclable',
    Recoverable = 'circularity.recoverable',
    Weight = 'weight',
    WeightPacking = 'weightPacking',
    DimensionsHeight = 'dimensionsHeight',
    DimensionsWidth = 'dimensionsWidth',
    DimensionsDepth = 'dimensionsDepth',
    DimensionsDiameter = 'dimensionsDiameter',
    DimensionsUrl = 'dimensionsUrl',

    CategoryName = 'category.name',

    DocumentationName = 'documentation.name',
    DocumentationType = 'documentation.type',
    DocumentationFieldType = 'documentation.fieldType',
}
