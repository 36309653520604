import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "text-[18px] font-semibold text-center" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex space-x-4 justify-center" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    size: "w-120",
    padding: "p-6",
    "close-button": "",
    "open-from-toggle-position": _ctx.openFromTogglePosition
  }, {
    toggle: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "toggle", _normalizeProps(_guardReactiveProps(state)))
    ]),
    body: _withCtx(({ close }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.header), 1),
        _createElementVNode("div", {
          class: "text-center",
          innerHTML: _ctx.sanitizeHtml(_ctx.body, { allowedAttributes: { '*': ['class'] } })
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            class: "border-default py-2 px-3 font-semibold rounded-md",
            onClick: ($event: any) => (_ctx.onButtonClicked(false, close))
          }, "Cancel", 8, _hoisted_5),
          _createElementVNode("button", {
            type: "button",
            class: "border-default py-2 px-3 font-semibold text-green-600 rounded-md",
            onClick: ($event: any) => (_ctx.onButtonClicked(true, close))
          }, "Confirm", 8, _hoisted_6)
        ])
      ])
    ]),
    _: 3
  }, 8, ["open-from-toggle-position"]))
}