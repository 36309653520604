
import { defineComponent } from 'vue';
import DropDown from '@/components/common/DropDown.vue';
import { Facet, FacetType } from '@/types';

export default defineComponent({
    components: { DropDown },
    props: {
        facetTypes: {
            type: Array as () => FacetType[],
            required: true,
        },
        initialFilters: {
            type: Array as () => { name: Facet; value: any }[],
            default: [],
        },
        hideFacetTypeIndex: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            expandDropdown: false,
            activeFacetType: null as null | FacetType,
            activeFacet: null as null | Facet,
            filterCategorySearch: '',
            filtersMatchingSearch: [] as string[],
        };
    },
    computed: {
        activeFacetDisplayIndex() {
            return this.filtersMatchingSearch.findIndex((facetName) => facetName === this.activeFacet?.name) || 0;
        },
        activeFacetTypeIndex() {
            return this.facetTypes.findIndex((currentFacetType) => currentFacetType.text === this.activeFacetType?.text) || 0;
        },
    },
    watch: {
        filterCategorySearch() {
            this.setFiltersMatchingSearch();
        },
    },
    mounted() {
        /* There seems to be a bug in algolia searchinitial-state, so we have to click the filter buttons manually.
        The buttons aren't rendered immediately, so we try 20 times within 2 seconds to give them time to render */
        if (this.initialFilters && this.initialFilters.length) {
            let tries = 0;
            const interval = setInterval(() => {
                if (tries > 20) {
                    clearInterval(interval);
                } else {
                    this.initialFilters.forEach((currentFacet) => {
                        const buttonRefs = this.$refs[`init-facet-refine-button-${currentFacet.name}`] as HTMLElement[] | undefined;
                        if (buttonRefs && buttonRefs.length) {
                            (buttonRefs[0] as HTMLButtonElement).click();
                            clearInterval(interval);
                        }
                    });
                }
                tries++;
            }, 100);
        }
    },
    created() {
        if (this.facetTypes.length) {
            const initialIndex = this.hideFacetTypeIndex === -1 ? 0 : this.hideFacetTypeIndex + 1;
            this.activeFacetType = this.facetTypes[initialIndex];
            this.activeFacet = this.facetTypes[initialIndex].facets[0];
            this.filtersMatchingSearch = this.activeFacetType.facets.map((facet) => facet.name);
        }
    },
    methods: {
        getHideIndexOffset() {
            return this.hideFacetTypeIndex === -1 ? 0 : -1;
        },
        refineValue(refine: CallableFunction) {
            this.initialFilters?.forEach((currentInitialFilter) => {
                refine(currentInitialFilter.value);
            });
        },
        onFacetTypeClicked(facetType: FacetType) {
            this.activeFacetType = facetType;
            this.filterCategorySearch = '';
            this.setFiltersMatchingSearch();
            if (facetType.facets.length) {
                this.activeFacet = facetType.facets[0];
            }
        },
        setFiltersMatchingSearch() {
            this.filtersMatchingSearch =
                this.activeFacetType?.facets.filter((facet) => this.activeFacet?.name === facet.name || facet.text.toLowerCase().includes(this.filterCategorySearch.toLowerCase())).map((facet) => facet.name) ||
                [];
        },
        transformItems(items: any[]) {
            return items.map((item) => {
                const label = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/.test(item.label)
                    ? (this as any).$format(new Date(item.label), 'dd.MM.yyyy')
                    : item.label;
                return {
                    ...item,
                    label,
                };
            });
        },
    },
});
