
import { defineComponent, ref, computed } from 'vue';
import useEscapable from '@/util/composables/useEscapable';

export default defineComponent({
    props: {
        size: {
            type: String,
            default: '',
        },
        padding: {
            type: String,
            default: 'p-6',
        },
        closeButton: {
            type: Boolean,
            default: false,
        },
        openFromTogglePosition: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close'],
    setup(props, context) {
        const modalRoot = ref<null | HTMLDivElement>(null);
        const isOpen = ref<boolean>(false);
        const close = () => {
            context.emit('close');
            isOpen.value = false;
        };
        const open = () => {
            isOpen.value = true;
        };

        const state = computed(() => {
            return { isOpen: isOpen.value, open, close };
        });
        const { onUnmounted } = useEscapable(close);

        const animationDuration = props.openFromTogglePosition ? 400 : 200;
        const modalMethods = {
            beforeEnter(element: any) {
                element.style.transform = props.openFromTogglePosition
                    ? `scale(0.33) translateY(${(modalRoot.value!.getBoundingClientRect().top - window.innerHeight / 2) * 3.3}px) translateX(${
                          (modalRoot.value!.getBoundingClientRect().left - window.innerWidth / 2) * 3.3
                      }px)`
                    : 'scale(0.33)';
                element.style.opacity = '0';
            },
            enter(element: any) {
                requestAnimationFrame(() => {
                    element.style.opacity = '100';
                    element.style.transform = 'scale(1) translateY(0px) translateX(0px)';
                });
            },
            afterEnter(element: any) {
                element.style.opacity = null;
                element.style.transform = null;
            },
            beforeLeave(element: any) {
                element.style.opacity = '100';
                element.style.transform = 'scale(1) translateY(0px) translateX(0px)';
            },
            leave(element: any) {
                requestAnimationFrame(() => {
                    element.style.opacity = '0';
                    element.style.transform = props.openFromTogglePosition
                        ? `scale(0.33) translateY(${(modalRoot.value!.getBoundingClientRect().top - window.innerHeight / 2) * 3.3}px) translateX(${
                              (modalRoot.value!.getBoundingClientRect().left - window.innerWidth / 2) * 3.3
                          }px)`
                        : 'scale(0.33)';
                });
            },
            afterLeave(element: any) {
                element.style.transform = null;
                element.style.opacity = null;
            },
        };

        return { onUnmounted, open, close, isOpen, state, modalMethods, modalRoot, animationDuration };
    },
});
