import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper", true)!

  return (_openBlock(), _createBlock(_component_swiper, {
    class: "w-full",
    "slides-per-view": 1,
    "space-between": _ctx.spaceBetween,
    virtual: "",
    onSwiper: _ctx.onSwiper,
    onActiveIndexChange: _ctx.onActiveIndexChange
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (slide) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, {
          key: slide.index,
          "virtual-index": slide.index
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {
              id: slide.id,
              index: slide.index,
              value: slide.value
            })
          ]),
          _: 2
        }, 1032, ["virtual-index"]))
      }), 128))
    ]),
    _: 3
  }, 8, ["space-between", "onSwiper", "onActiveIndexChange"]))
}