import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative custom-checkbox pl-2" }
const _hoisted_2 = { class: "flex space-x-2 relative" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        id: _ctx.uid + '_input',
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedChecked) = $event)),
        class: "h-6 rounded-full",
        type: "checkbox"
      }, null, 8, _hoisted_3), [
        [_vModelCheckbox, _ctx.computedChecked]
      ]),
      _renderSlot(_ctx.$slots, "label", _normalizeProps(_guardReactiveProps({ uid: _ctx.uid })), () => [
        _createElementVNode("label", {
          for: _ctx.uid + '_input'
        }, _toDisplayString(_ctx.label), 9, _hoisted_4)
      ])
    ])
  ]))
}