import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Toast from 'vue-toastification';
import { format } from 'date-fns';
import InstantSearch from 'vue-instantsearch/vue3/es';
import VuePlyr from '@skjnldsv/vue-plyr';
import App from '@/App.vue';
import * as firebase from '@/plugins/firebase';
import router from '@/plugins/router';
import '@/assets/css/index.css';
import '@/plugins/nprogress';
import ToastOptions from '@/plugins/toastification';

const pinia = createPinia();
const app = createApp(App).use(router).use(pinia).use(VuePlyr).use(InstantSearch).use(Toast, ToastOptions);
app.config.globalProperties.$format = format;
app.mount('#app');
