
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            default: '',
        },
        checked: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uid: Math.random().toString(36).substring(6),
        };
    },
    computed: {
        computedChecked: {
            get(): boolean {
                return this.checked;
            },
            set(newValue: boolean) {
                this.$emit('update:checked', newValue);
            },
        },
    },
});
