import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "-mt-2" }
const _hoisted_2 = { class: "space-y-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Templates = _resolveComponent("Templates")!
  const _component_Import = _resolveComponent("Import")!
  const _component_ImportLog = _resolveComponent("ImportLog")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, { heading: "IMPORT DATA" }, {
    "top-right": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Templates)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Import, { type: _ctx.IMPORT_TYPE }, null, 8, ["type"]),
        _createVNode(_component_ImportLog)
      ])
    ]),
    _: 1
  }))
}