import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericTable = _resolveComponent("GenericTable")!
  const _component_ItemHistoryModal = _resolveComponent("ItemHistoryModal")!

  return (_openBlock(), _createBlock(_component_ItemHistoryModal, { ownership: _ctx.activeOwnership }, {
    toggle: _withCtx(({ open }) => [
      _createVNode(_component_GenericTable, {
        headers: _ctx.headers,
        items: _ctx.items,
        "clickable-rows": "",
        onItemClicked: (tableItem) => _ctx.onItemClicked(tableItem, open)
      }, null, 8, ["headers", "items", "onItemClicked"])
    ]),
    _: 1
  }, 8, ["ownership"]))
}