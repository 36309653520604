import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white h-full min-h-screen" }
const _hoisted_2 = { class: "max-w-dashboard mx-auto flex" }
const _hoisted_3 = { class: "flex-1 pr-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_TopBar = _resolveComponent("TopBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SideBar),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_TopBar),
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}