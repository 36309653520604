
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import useSubmissionState from '@/util/composables/useSubmissionState';
import { useMainStore, useUserStore } from '@/plugins/store';
import CustomInput from '@/components/ui/CustomInput.vue';
import { UserRole } from '@/types';

export default defineComponent({
    components: { CustomInput },
    setup() {
        const [userStore, mainStore, router, toast] = [useUserStore(), useMainStore(), useRouter(), useToast()];

        const userEmail = ref('');
        const userPassword = ref('');
        const validForm = computed(() => Boolean(userEmail.value && userPassword.value));

        const dashboardOnlySupportsCompanyUsersErrorMessage = 'Dashboard is only supported for company users';

        const signInSubmissionState = useSubmissionState(async () => {
            try {
                await userStore.signIn({ payload: { email: userEmail.value, password: userPassword.value } });
                const profile = await mainStore.getProfile(true);
                if (profile.companyId || profile.role === UserRole.SuperAdmin) {
                    router.push({ name: 'Dashboard' });
                } else {
                    toast.error(dashboardOnlySupportsCompanyUsersErrorMessage);
                }
            } catch (e) {
                if (e.message?.includes('auth/multi-factor-auth-required')) {
                    toast.error(dashboardOnlySupportsCompanyUsersErrorMessage);
                } else if (e.message?.includes('auth/wrong-password')) {
                    toast.error('Wrong password');
                } else if (e.message?.includes('auth/user-not-found')) {
                    toast.error('User does not exist');
                }
            }
        });

        return { userEmail, userPassword, signInSubmissionState, validForm };
    },
});
