
import { mapActions, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { useMainStore, useOverviewStore } from '@/plugins/store';
import DashboardContentLayout from '@/components/layouts/dashboard/content/DashboardContentLayout.vue';
import { ProductWithReferences } from '@/types';
import ProductGeneralInformation from '@/views/dashboard/overview/product/ProductGeneralInformation.vue';
import ProductReferences from '@/views/dashboard/overview/product/ProductReferences.vue';
import ProductActions from '@/views/dashboard/overview/product/ProductActions.vue';

export default defineComponent({
    components: { DashboardContentLayout, ProductGeneralInformation, ProductReferences, ProductActions },
    data() {
        return {
            product: null as null | ProductWithReferences,
        };
    },
    computed: {
        ...mapWritableState(useMainStore, ['breadcrumbs']),
        headings() {
            if (this.product) {
                let subheader = '';
                subheader += this.product.model || '';
                if (this.product.model && this.product.description) subheader += ` <span class="opacity-20 font-bold">•</span> `;
                subheader += this.product.description || '';
                return {
                    header: this.product.name,
                    subheader,
                };
            }
            return { header: 'Finding product model...', subheader: '' };
        },
    },
    watch: {
        product: {
            immediate: true,
            handler(newValue: ProductWithReferences | null) {
                if (newValue) {
                    let productBreadcumpPath = '';
                    if (newValue.model) productBreadcumpPath += `, `;
                    productBreadcumpPath += newValue.model || '';
                    if (newValue.description) productBreadcumpPath += `, `;
                    productBreadcumpPath += newValue.description || '';
                    this.breadcrumbs = [
                        { text: 'Product models', route: { name: 'Products' } },
                        { text: `${newValue.name}${productBreadcumpPath}`, route: { name: this.$route.name, params: this.$route.params } },
                    ];
                    document.title = `Micollect - ${newValue.name}`;
                }
            },
        },
    },
    async created() {
        if (!this.$route.params.id) {
            this.$router.push({ name: 'Products' });
            return;
        }
        this.product = await this.getProduct(this.$route.params.id as string);
    },
    methods: {
        ...mapActions(useOverviewStore, ['getProduct']),
    },
});
