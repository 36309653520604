import { defineStore } from 'pinia';
import { ApiRepository } from '@/plugins/api/ApiRepository';
import { Company, CompanyWithUsers } from '@/types';

const useCompaniesStore = defineStore('companies', {
    state: () => ({
        companies: null as CompanyWithUsers[] | null,
    }),
    actions: {
        async createCompanyAndAdminUser(payload: {
            companyName: string;
            companyRegistrationNumber: string;
            companyEmail: string;
            companyWebsite: string;
            companyCountry: string;
            companyFoundationYear: number;
            userEmail: string;
            userFirstName: string;
            userLastName: string;
            userPhone: string;
        }): Promise<Company> {
            return ApiRepository.CreateCompany({ payload });
        },
        async editCompany(payload: { id: number; name: string; registration: string; website: string; email: string; country: string | null; foundationYear: number | null; logo: string | null }) {
            return ApiRepository.EditCompany({ payload });
        },
        async getCompanies(bustCache = false): Promise<CompanyWithUsers[]> {
            return ApiRepository.GetCompanies({ cacheOptions: { store: this, key: 'companies', bustCache } });
        },
    },
});

export { useCompaniesStore };
