
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        defaultActiveIndex: {
            type: Number,
            default: 0,
        },
        tabs: {
            type: Array as () => { id: string; name: string; data?: any }[],
            default: [],
        },
        tabPadding: {
            type: String,
            default: 'pb-4',
        },
        animate: {
            type: Boolean,
            default: false,
        },
        createHashRoutes: {
            type: Boolean,
            default: false,
        },
        parentHashRouteName: {
            type: String,
            default: null,
        },
        parentHashRouteParams: {
            type: Object,
            default: null,
        },
    },
    emits: ['active-index-changed'],
    data() {
        return {
            currentActiveIndex: 0,
        };
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(to) {
                if (this.createHashRoutes && to.name === this.parentHashRouteName) {
                    const matchedRouteIndex = Math.max(
                        this.tabs.findIndex((currentTab) => {
                            return `#${currentTab.id}` === to.hash;
                        }),
                        0
                    );
                    this.currentActiveIndex = this.createHashRoutes ? matchedRouteIndex : this.defaultActiveIndex;
                }
            },
        },
    },
    methods: {
        onTabClicked(index: number) {
            this.currentActiveIndex = index;
            this.$emit('active-index-changed', index);
        },
    },
});
