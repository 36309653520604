
import { defineComponent } from 'vue';
import { Documentation, DocumentationType, ProductWithReferences } from '@/types';
import Tabs from '@/components/common/Tabs.vue';
import DocumentationList from '@/components/common/DocumentationList.vue';

export default defineComponent({
    components: { Tabs, DocumentationList },
    props: {
        product: {
            type: Object as () => ProductWithReferences,
            required: true,
        },
    },
    data() {
        return {
            tabs: [
                { id: 'documents', name: 'Documents' },
                { id: 'images', name: 'Images' },
            ],
        };
    },
    computed: {
        documents(): Documentation[] {
            return (
                this.product?.documentations?.filter((currentDocumentation) => {
                    return currentDocumentation.type !== DocumentationType.Image;
                }) || []
            );
        },
        images(): Documentation[] {
            return (
                this.product?.documentations?.filter((currentDocumentation) => {
                    return currentDocumentation.type === DocumentationType.Image;
                }) || []
            );
        },
    },
    methods: {
        getDocumentationIcon(documentation: Documentation) {
            switch (documentation.type) {
                case 'image': {
                    return '/assets/icons/ic_document_type_image.svg';
                }
                case 'video': {
                    return '/assets/icons/ic_document_type_video.svg';
                }
                default: {
                    return '/assets/icons/ic_document_type_pdf.svg';
                }
            }
        },
    },
});
