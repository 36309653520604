import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "text-[18px] font-semibold" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.component.category?.name || 'Unknown'), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoPoints, (infoPoint) => {
      return (_openBlock(), _createElementBlock("div", {
        key: infoPoint.title
      }, [
        _createElementVNode("div", {
          class: "text-[15px] opacity-70",
          innerHTML: _ctx.sanitizeHtml(infoPoint.title, { allowedAttributes: { '*': ['class', 'style'], a: ['href', 'target'] } })
        }, null, 8, _hoisted_3),
        _createElementVNode("div", {
          class: "font-medium",
          innerHTML: _ctx.sanitizeHtml(infoPoint.content, { allowedAttributes: { '*': ['class', 'style'], a: ['href', 'target'] } })
        }, null, 8, _hoisted_4)
      ]))
    }), 128))
  ]))
}