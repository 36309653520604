
import { defineComponent } from 'vue';
import { ColDef } from 'ag-grid-community';
import { mapActions, mapState } from 'pinia';
import GenericTable from '@/components/tables/GenericTable.vue';
import { useCompaniesStore } from '@/plugins/store';
import InitialsCellRenderer from '@/components/tables/InitialsCellRenderer.vue';
import Card from '@/components/common/Card.vue';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';

export default defineComponent({
    /* eslint-disable vue/no-unused-components */
    components: { GenericTable, InitialsCellRenderer, Card, ConfirmationModal },
    data() {
        return {
            headers: [
                {
                    headerName: 'Name',
                    field: 'name',
                    sortable: true,
                },
                {
                    headerName: 'Registration',
                    field: 'registration',
                    sortable: true,
                },
                { headerName: 'Email', field: 'email', sortable: true },
                { headerName: 'Tags', field: 'tagsCount.total', sortable: true },
                { headerName: 'Tags in use', field: 'tagsCount.used', sortable: true },
            ] as ColDef[],
        };
    },
    computed: {
        ...mapState(useCompaniesStore, ['companies']),
    },
    created() {
        this.getCompanies();
    },
    methods: {
        ...mapActions(useCompaniesStore, ['getCompanies']),
    },
});
