
import { defineComponent } from 'vue';
import { Documentation } from '@/types';

export default defineComponent({
    props: {
        documentations: {
            type: Array as () => Documentation[],
            default: [],
        },
        imageIcons: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getDocumentationIcon(documentation: Documentation) {
            switch (documentation.type) {
                case 'image': {
                    return '/assets/icons/ic_document_type_image.svg';
                }
                case 'video': {
                    return '/assets/icons/ic_document_type_video.svg';
                }
                default: {
                    return '/assets/icons/ic_document_type_pdf.svg';
                }
            }
        },
    },
});
