import { createRouter, createWebHistory } from 'vue-router';
import { useToast } from 'vue-toastification';
import { routes } from '@/plugins/router/routes';
import { useMainStore } from '@/plugins/store';
import { auth } from '@/plugins/firebase';
import { UserRole } from '@/types';

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    document.title = `MiCollect - ${to.meta.documentTitle ? to.meta.documentTitle : 'Tag, Trace & Transform'}`;

    const [mainStore, toast] = [useMainStore(), useToast()];
    mainStore.breadcrumbs = [];
    if (!to.meta?.skipAuthenticationGuard) {
        const authenticatedCompanyUser: boolean = await new Promise((resolve, reject) => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                if (user) {
                    mainStore.getProfile().then((profile) => {
                        if (profile.companyId || profile.role === UserRole.SuperAdmin) {
                            resolve(true);
                        } else {
                            toast.error('Dashboard is only supported for company users');
                            resolve(false);
                        }
                    });
                } else {
                    unsubscribe();
                    resolve(false);
                }
            }, reject);
        });

        if (authenticatedCompanyUser) {
            next();
        } else {
            next({ name: 'SignIn' });
        }
    } else {
        next();
    }
});

export default router;
