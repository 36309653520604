
/* Copied from https://github.com/makeabledk/vue-ui/tree/main/src/components/trap-focus/src */
import { defineComponent } from 'vue';
import moveFocusInside, { focusInside, focusIsHidden } from 'focus-lock';

function deferAction(action: any) {
    const setImmediate = (window as any).setImmediate;
    if (typeof setImmediate !== 'undefined') {
        setImmediate(action);
    } else {
        setTimeout(action, 1);
    }
}
let lastActiveTrap: any = 0;
let lastActiveFocus: any = null;
const focusOnBody = () => document && document.activeElement === document.body;
const isFreeFocus = () => focusOnBody() || focusIsHidden();
const activateTrap = () => {
    let result: any = false;
    if (lastActiveTrap) {
        const { observed, onActivation } = lastActiveTrap;
        if (!isFreeFocus() || !lastActiveFocus) {
            if (observed && !focusInside(observed)) {
                onActivation();
                result = moveFocusInside(observed, lastActiveFocus);
            }
            lastActiveFocus = document && document.activeElement;
        }
    }
    return result;
};
const reducePropsToState = (propsList: any) => {
    return propsList.filter(({ disabled }: { disabled: any }) => !disabled).slice(-1)[0];
};
const handleStateChangeOnClient = (trap: any) => {
    if (lastActiveTrap !== trap) {
        lastActiveTrap = null;
    }
    lastActiveTrap = trap;
    if (trap) {
        activateTrap();
        deferAction(activateTrap);
    }
};
let instances: any[] = [];
const emitChange = () => {
    handleStateChangeOnClient(reducePropsToState(instances));
};
const onTrap = (event: any) => {
    if (activateTrap() && event) {
        // prevent scroll jump
        event.stopPropagation();
        event.preventDefault();
    }
};
const onBlur = () => {
    deferAction(activateTrap);
};
const attachHandler = () => {
    document.addEventListener('focusin', onTrap, true);
    document.addEventListener('focusout', onBlur);
};
const detachHandler = () => {
    document.removeEventListener('focusin', onTrap, true);
    document.removeEventListener('focusout', onBlur);
};
export default defineComponent({
    props: {
        returnFocus: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        noFocusGuards: {
            type: Boolean,
        },
    },
    data() {
        return {
            data: {} as any,
            hidden: '', //    "width: 1px;height: 0px;padding: 0;overflow: hidden;position: fixed;top: 0;left: 0;"
            originalFocusedElement: null as any,
        };
    },
    computed: {
        guardsEnabled() {
            return !(this.disabled || this.noFocusGuards);
        },
    },
    watch: {
        disabled(now, before) {
            if (now && !before) {
                this.focusOriginal();
            }
            this.data.disabled = this.disabled;
            emitChange();
        },
    },
    mounted() {
        this.data.vue = this;
        this.data.observed = this.$el.querySelector('[data-lock]');
        this.data.disabled = this.disabled;
        this.data.onActivation = () => {
            this.originalFocusedElement = this.originalFocusedElement || document.activeElement;
        };
        if (!instances.length) {
            attachHandler();
        }
        instances.push(this.data);
        emitChange();
    },
    unmounted() {
        instances = instances.filter(({ vue }) => vue !== this);
        if (!instances.length) {
            detachHandler();
        }
        if (this.returnFocus && this.originalFocusedElement && this.originalFocusedElement.focus) {
            this.originalFocusedElement.focus();
        }
        emitChange();
    },
    methods: {
        focusOriginal() {
            if (this.returnFocus && this.originalFocusedElement && this.originalFocusedElement.focus) {
                this.originalFocusedElement.focus();
            }
        },
        onBlur() {
            deferAction(emitChange);
        },
    },
});
