import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-6" }
const _hoisted_2 = { class: "font-semibold text-1.5xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericTable = _resolveComponent("GenericTable")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companiesWithFormattedUsers, (company) => {
      return (_openBlock(), _createBlock(_component_Card, {
        key: company.id,
        class: "p-4 border"
      }, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(company.name) + " (" + _toDisplayString(company.type) + ")", 1),
          _createVNode(_component_ConfirmationModal, {
            header: "Switch to user?",
            body: _ctx.switchToUserConfirmationModalBody,
            onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeCompanyUser = null)),
            onConfirm: _ctx.onConfirmSwitchToUser
          }, {
            toggle: _withCtx(({ open }) => [
              _createVNode(_component_GenericTable, {
                "clickable-rows": "",
                headers: _ctx.headers,
                items: company.formattedUsers,
                onItemClicked: (companyUser) => _ctx.onItemClicked(companyUser, open)
              }, null, 8, ["headers", "items", "onItemClicked"])
            ]),
            _: 2
          }, 1032, ["body", "onConfirm"])
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}