
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useToast } from 'vue-toastification';
import { useCompaniesStore, useMainStore } from '@/plugins/store';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import { Profile } from '@/types';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';

export default defineComponent({
    components: { CustomInput, CustomButton, ConfirmationModal },
    data() {
        return {
            toast: useToast(),
            form: {
                name: '',
                registration: '',
                website: '',
                country: '',
                email: '',
                logo: '',
                foundationYear: '' as number | string,
            },
        };
    },
    computed: {
        ...mapState(useMainStore, ['profile']),
    },
    watch: {
        profile: {
            immediate: true,
            deep: true,
            handler(newValue: Profile) {
                if (!newValue || !newValue.company) {
                    return;
                }
                this.form.name = newValue.company.name;
                this.form.registration = newValue.company.registration;
                this.form.website = newValue.company.website || '';
                this.form.country = newValue.company.country || '';
                this.form.foundationYear = newValue.company.foundationYear || '';
                this.form.email = newValue.company.email || '';
                this.form.logo = newValue.company.logo || '';
            },
        },
    },
    created() {
        this.getProfile();
    },
    methods: {
        ...mapActions(useMainStore, ['getProfile']),
        ...mapActions(useCompaniesStore, ['editCompany']),
        async onSubmit() {
            if (!this.profile || !this.profile.companyId) return;
            await this.editCompany({
                id: this.profile.companyId,
                name: this.form.name,
                registration: this.form.registration,
                website: this.form.website,
                email: this.form.email,
                country: this.form.country || null,
                foundationYear: parseInt(this.form.foundationYear as string) || null,
                logo: this.form.logo || null,
            });
            this.toast.success('Company updated');
            await this.getProfile(true);
        },
    },
});
