
import { computed, defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import Modal from '@/components/common/Modal.vue';
import CancelButton from '@/components/ui/CancelButton.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import CustomInput from '@/components/ui/CustomInput.vue';
import useSubmissionState from '@/util/composables/useSubmissionState';
import { useUserStore } from '@/plugins/store';
import { UserRole } from '@/types';

export default defineComponent({
    components: { Modal, CustomInput, CustomButton, CancelButton },
    setup() {
        const [userStore, toast] = [useUserStore(), useToast()];

        const [email, close_button] = [ref(''), ref<HTMLButtonElement | null>(null)];
        const validForm = computed(() => Boolean(email.value));

        const signInSubmissionState = useSubmissionState(async () => {
            await userStore.inviteUserToCompany(email.value, UserRole.Member); // TODO: Make userrole dynamic based on selection
            toast.success('User invited');
            close_button?.value?.click();
        });

        const onClose = () => {
            email.value = '';
        };

        return { email, signInSubmissionState, validForm, onClose, close_button };
    },
});
