import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  controls: "",
  playsinline: ""
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["data-plyr-provider", "data-plyr-embed-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuePlyr = _resolveComponent("VuePlyr")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.embedData)
      ? (_openBlock(), _createBlock(_component_VuePlyr, { key: 0 }, {
          default: _withCtx(() => [
            (_ctx.embedData.provider === 'mp4')
              ? (_openBlock(), _createElementBlock("video", _hoisted_2, [
                  _createElementVNode("source", {
                    src: _ctx.embedData.embedId,
                    type: "video/mp4"
                  }, null, 8, _hoisted_3)
                ]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  "data-plyr-provider": _ctx.embedData.provider,
                  "data-plyr-embed-id": _ctx.embedData.embedId
                }, null, 8, _hoisted_4))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}