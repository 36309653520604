
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import Settings from '@/components/layouts/dashboard/topBar/Settings.vue';
import TagSearch from '@/components/layouts/dashboard/topBar/TagSearch.vue';
import Notifications from '@/components/layouts/dashboard/topBar/Notifications.vue';
import Import from '@/components/layouts/dashboard/topBar/Import.vue';
import Breadcrumbs from '@/components/layouts/dashboard/topBar/Breadcrumbs.vue';
import { useMainStore } from '@/plugins/store';

export default defineComponent({
    components: { Settings, TagSearch, Notifications, Import, Breadcrumbs },
    computed: {
        ...mapState(useMainStore, ['companyIsBrandOwner']),
    },
});
