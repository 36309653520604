export default {
    mounted(el: any, binding: { value: (event: Event) => any }) {
        el.event = (event: Event) => {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event);
            }
        };
        document.body.addEventListener('click', el.event);
    },

    beforeUnmount(el: any) {
        document.body.removeEventListener('click', el.event);
    },
};
