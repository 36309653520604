
import { defineComponent } from 'vue';
import ProductsSortingOrderDropdown from '@/views/dashboard/overview/products/filters/ProductsSortingOrderDropdown.vue';
import AlgoliaSearchInput from '@/components/common/algolia/AlgoliaSearchInput.vue';
import AlgoliaChosenFilters from '@/components/common/algolia/AlgoliaChosenFilters.vue';
import AlgoliaFilterPicker from '@/components/common/algolia/AlgoliaFilterPicker.vue';
import { FacetType, ProductFacet } from '@/types';

export default defineComponent({
    components: { ProductsSortingOrderDropdown, AlgoliaSearchInput, AlgoliaChosenFilters, AlgoliaFilterPicker },
    data() {
        return {
            facetTexts: {
                [ProductFacet.Name]: 'Name',
                [ProductFacet.Number]: 'Number',
                [ProductFacet.PrimaryMaterial]: 'Primary material',
                [ProductFacet.PrimaryMaterialVariant]: 'Primary material variant',
                [ProductFacet.SecondaryMaterial]: 'Secondary material',
                [ProductFacet.SecondaryMaterialVariant]: 'Secondary material variant',
                [ProductFacet.Model]: 'Model',
                [ProductFacet.Description]: 'Description',
                [ProductFacet.DesignerName]: 'Designer name',
                [ProductFacet.DesignYear]: 'Design year',
                [ProductFacet.GTIN]: 'GTIN',
                [ProductFacet.CommodityCode]: 'Commodity code',
                [ProductFacet.Co2Date]: 'Co2 date',
                [ProductFacet.Co2Total]: 'Co2-e',
                [ProductFacet.DegassingClass]: 'Degassing class',
                [ProductFacet.Formaldehyde]: 'Formaldehyde',
                [ProductFacet.TVOC]: 'TVOC',
                [ProductFacet.TVOCNumber]: 'TVOC number',
                [ProductFacet.Durability]: 'Durability',
                [ProductFacet.Waste]: 'Waste',
                [ProductFacet.WasteDescription]: 'Waste description',
                [ProductFacet.CategoryName]: 'Category name',
                [ProductFacet.DocumentationName]: 'Documentation name',
                [ProductFacet.DocumentationType]: 'Documentation type',
                [ProductFacet.DocumentationFieldType]: 'Documentation field type',
                [ProductFacet.Reducable]: 'Reducable',
                [ProductFacet.Reusable]: 'Reusable',
                [ProductFacet.Recyclable]: 'Recyclable',
                [ProductFacet.Recoverable]: 'Recoverable',
                [ProductFacet.Weight]: 'Weight',
                [ProductFacet.WeightPacking]: 'Weight packing',
                [ProductFacet.DimensionsHeight]: 'Dimensions height',
                [ProductFacet.DimensionsWidth]: 'Dimensions width',
                [ProductFacet.DimensionsDepth]: 'Dimensions depth',
                [ProductFacet.DimensionsDiameter]: 'Dimensions diameter',
                [ProductFacet.DimensionsUrl]: 'Dimensions url',
            },
        };
    },
    computed: {
        facetTypes(): FacetType[] {
            return [
                {
                    text: 'Product master data',
                    facets: [
                        { text: this.facetTexts[ProductFacet.Name], name: ProductFacet.Name, searchPlaceholder: 'Type name' },
                        { text: this.facetTexts[ProductFacet.Number], name: ProductFacet.Number, searchPlaceholder: 'Type number' },
                        { text: this.facetTexts[ProductFacet.Model], name: ProductFacet.Model, searchPlaceholder: 'Type model name', missingPropertyName: ProductFacet.Model },
                        { text: this.facetTexts[ProductFacet.Description], name: ProductFacet.Description, searchPlaceholder: 'Type description', missingPropertyName: ProductFacet.Description },
                        { text: 'Category', name: ProductFacet.CategoryName, searchPlaceholder: 'Type category name' },
                        { text: this.facetTexts[ProductFacet.PrimaryMaterial], name: ProductFacet.PrimaryMaterial, searchPlaceholder: 'Type primary material' },
                        { text: this.facetTexts[ProductFacet.PrimaryMaterialVariant], name: ProductFacet.PrimaryMaterialVariant, searchPlaceholder: 'Type primary material variant' },
                        {
                            text: this.facetTexts[ProductFacet.SecondaryMaterial],
                            name: ProductFacet.SecondaryMaterial,
                            searchPlaceholder: 'Type secondary material',
                            missingPropertyName: ProductFacet.SecondaryMaterial,
                        },
                        {
                            text: this.facetTexts[ProductFacet.SecondaryMaterialVariant],
                            name: ProductFacet.SecondaryMaterialVariant,
                            searchPlaceholder: 'Type secondary material variant',
                            missingPropertyName: ProductFacet.SecondaryMaterialVariant,
                        },

                        { text: this.facetTexts[ProductFacet.DesignerName], name: ProductFacet.DesignerName, searchPlaceholder: 'Type designer name', missingPropertyName: ProductFacet.DesignerName },
                        { text: this.facetTexts[ProductFacet.DesignYear], name: ProductFacet.DesignYear, searchPlaceholder: 'Type design year', missingPropertyName: ProductFacet.DesignYear },
                        { text: this.facetTexts[ProductFacet.GTIN], name: ProductFacet.GTIN, searchPlaceholder: 'Type GTIN', missingPropertyName: ProductFacet.GTIN },
                        { text: this.facetTexts[ProductFacet.CommodityCode], name: ProductFacet.CommodityCode, searchPlaceholder: 'Type commodity code', missingPropertyName: ProductFacet.CommodityCode },
                        { text: this.facetTexts[ProductFacet.Co2Date], name: ProductFacet.Co2Date, searchPlaceholder: 'Type Co2 date', missingPropertyName: ProductFacet.Co2Date },
                        { text: this.facetTexts[ProductFacet.Co2Total], name: ProductFacet.Co2Total, searchPlaceholder: 'Type Co2-e', missingPropertyName: ProductFacet.Co2Total },
                        { text: this.facetTexts[ProductFacet.DegassingClass], name: ProductFacet.DegassingClass, searchPlaceholder: 'Type degassing class', missingPropertyName: ProductFacet.DegassingClass },
                        { text: this.facetTexts[ProductFacet.Formaldehyde], name: ProductFacet.Formaldehyde, searchPlaceholder: 'Type formaldehyde', missingPropertyName: ProductFacet.Formaldehyde },
                        { text: this.facetTexts[ProductFacet.TVOC], name: ProductFacet.TVOC, searchPlaceholder: 'Type TVOC', missingPropertyName: ProductFacet.TVOC },
                        { text: this.facetTexts[ProductFacet.TVOCNumber], name: ProductFacet.TVOCNumber, searchPlaceholder: 'Type TVOC number', missingPropertyName: ProductFacet.TVOCNumber },
                        { text: this.facetTexts[ProductFacet.Durability], name: ProductFacet.Durability, searchPlaceholder: 'Type durability', missingPropertyName: ProductFacet.Durability },
                        { text: this.facetTexts[ProductFacet.Waste], name: ProductFacet.Waste, searchPlaceholder: 'Type waste', missingPropertyName: ProductFacet.Waste },
                        {
                            text: this.facetTexts[ProductFacet.WasteDescription],
                            name: ProductFacet.WasteDescription,
                            searchPlaceholder: 'Type waste description',
                            missingPropertyName: ProductFacet.WasteDescription,
                        },
                        { text: this.facetTexts[ProductFacet.Reducable], name: ProductFacet.Reducable, searchPlaceholder: 'Type reducable', missingPropertyName: ProductFacet.Reducable },
                        { text: this.facetTexts[ProductFacet.Reusable], name: ProductFacet.Reusable, searchPlaceholder: 'Type reusable', missingPropertyName: ProductFacet.Reusable },
                        { text: this.facetTexts[ProductFacet.Recyclable], name: ProductFacet.Recyclable, searchPlaceholder: 'Type recyclable', missingPropertyName: ProductFacet.Recyclable },
                        { text: this.facetTexts[ProductFacet.Recoverable], name: ProductFacet.Recoverable, searchPlaceholder: 'Type recoverable', missingPropertyName: ProductFacet.Recoverable },
                        { text: this.facetTexts[ProductFacet.Weight], name: ProductFacet.Weight, searchPlaceholder: 'Type weight', missingPropertyName: ProductFacet.Weight },
                        { text: this.facetTexts[ProductFacet.WeightPacking], name: ProductFacet.WeightPacking, searchPlaceholder: 'Type weight packing', missingPropertyName: ProductFacet.WeightPacking },
                        {
                            text: this.facetTexts[ProductFacet.DimensionsHeight],
                            name: ProductFacet.DimensionsHeight,
                            searchPlaceholder: 'Type dimensions height',
                            missingPropertyName: ProductFacet.DimensionsHeight,
                        },
                        {
                            text: this.facetTexts[ProductFacet.DimensionsWidth],
                            name: ProductFacet.DimensionsWidth,
                            searchPlaceholder: 'Type dimensions width',
                            missingPropertyName: ProductFacet.DimensionsWidth,
                        },
                        {
                            text: this.facetTexts[ProductFacet.DimensionsDepth],
                            name: ProductFacet.DimensionsDepth,
                            searchPlaceholder: 'Type dimensions depth',
                            missingPropertyName: ProductFacet.DimensionsDepth,
                        },
                        {
                            text: this.facetTexts[ProductFacet.DimensionsDiameter],
                            name: ProductFacet.DimensionsDiameter,
                            searchPlaceholder: 'Type dimensions diameter',
                            missingPropertyName: ProductFacet.DimensionsDiameter,
                        },
                        {
                            text: this.facetTexts[ProductFacet.DimensionsUrl],
                            name: ProductFacet.DimensionsUrl,
                            searchPlaceholder: 'Type dimensions url',
                            missingPropertyName: ProductFacet.DimensionsUrl,
                        },
                    ],
                },
                {
                    text: 'Documentation',
                    facets: [
                        { text: 'Name', name: ProductFacet.DocumentationName, searchPlaceholder: 'Type name' },
                        { text: 'Type', name: ProductFacet.DocumentationType, searchPlaceholder: 'Type documentation type' },
                        { text: 'Field type', name: ProductFacet.DocumentationFieldType, searchPlaceholder: 'Type field type' },
                    ],
                },
            ];
        },
    },
});
