
import { computed, defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/plugins/store';
import AuthLayout from '@/components/layouts/auth/AuthLayout.vue';
import useSubmissionState from '@/util/composables/useSubmissionState';

export default defineComponent({
    components: { AuthLayout },
    setup(props, context) {
        const [userStore, toast, router] = [useUserStore(), useToast(), useRouter()];
        const updateSuccess = ref(false);

        if (!router.currentRoute.value.params.id) {
            router.push({ name: 'NotFound' });
            return {};
        }

        const validForm = computed(() => Boolean(router.currentRoute.value.params.id));
        const submissionState = useSubmissionState(async () => {
            await userStore.confirmEmailUpdate(`${router.currentRoute.value.params.id}`);
            updateSuccess.value = true;
            toast.success('Email updated successfully');
        });

        return { submissionState, validForm, updateSuccess };
    },
});
