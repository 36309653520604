import { defineStore } from 'pinia';
import { ApiRepository } from '@/plugins/api/ApiRepository';
import { RequestOptions, User, UserRole } from '@/types';

const useUserStore = defineStore('users', {
    state: () => ({
        companyUsers: null as null | User[],
        impersonatingUser: sessionStorage.getItem('impersonate_user'),
    }),
    actions: {
        getCompanyUsers(companyId: number, bustCache = false): Promise<User[]> {
            return ApiRepository.GetCompanyUsers({ payload: { id: companyId }, cacheOptions: { store: this, key: 'companyUsers', bustCache } });
        },
        async signIn(message: { payload: { email: string; password: string } }) {
            return ApiRepository.SignIn(message);
        },
        async signOut() {
            await ApiRepository.SignOut();
            window.location.href = '/auth/sign-in';
        },
        async requestPasswordReset(message: { payload: { email: string } }) {
            return ApiRepository.RequestPasswordReset(message);
        },
        async editUser(id: string, firstName: string, lastName: string, phone: string) {
            return ApiRepository.EditUser({ payload: { id, firstName, lastName, phone } });
        },
        async confirmEmailUpdate(id: string) {
            return ApiRepository.ConfirmEmailUpdate({ payload: { emailUpdateId: id }, options: { skipAuthCheck: true } });
        },
        async impersonateUser(email: string) {
            sessionStorage.setItem('impersonate_user', email);
            if (window.location.href.includes('/settings#personal-profile')) {
                window.location.reload();
            } else {
                window.location.href = '/settings#personal-profile';
            }
        },
        async stopImpersonation() {
            sessionStorage.removeItem('impersonate_user');
            if (window.location.href.includes('/settings#personal-profile')) {
                window.location.reload();
            } else {
                window.location.href = '/settings#personal-profile';
            }
        },
        async inviteUserToCompany(email: string, role: UserRole) {
            return ApiRepository.InviteUserToCompany({ payload: { email, role } });
        },
        async acceptInvitationToCompany(
            payload: { email: string; phone: string; firstName: string; lastName: string; companyId: string; expire: number; role: UserRole; sign: string; password: string },
            options?: RequestOptions
        ) {
            const userCreateResponse = await ApiRepository.AcceptInvitationToCompany({ payload, options });
            await ApiRepository.RegisterUser({ payload: { email: payload.email, password: payload.password } });
            return userCreateResponse;
        },
    },
});

export { useUserStore };
