import { Method } from 'axios';
import { CacheOptions, ExcelImportType, RequestOptions, UserRole } from '@/types';
import ApiClient from '@/plugins/api/ApiClient';
import FirebaseClient from '@/plugins/api/FirebaseClient';
import AlgoliaClient from '@/plugins/api/AlgoliaClient';

const ApiRepository = {
    SignIn: (message: { payload: { email: string; password: string } }) => {
        return FirebaseClient.signIn(message.payload.email, message.payload.password);
    },
    SignOut: () => {
        return FirebaseClient.signOut();
    },
    RegisterUser: (message: { payload: { email: string; password: string } }) => {
        return FirebaseClient.register(message.payload.email, message.payload.password);
    },
    RequestPasswordReset: (message: { payload: { email: string } }) => {
        return FirebaseClient.requestPasswordReset(message.payload.email);
    },
    CreateCompany: (message: {
        payload: {
            companyName: string;
            companyRegistrationNumber: string;
            companyEmail: string;
            companyWebsite: string;
            companyCountry: string;
            companyFoundationYear: number;
            userEmail: string;
            userFirstName: string;
            userLastName: string;
            userPhone: string;
        };
    }) => {
        return sendRequestWithCache({ method: 'PUT', url: 'api/companies', ...message });
    },
    GetCompanies: (message: { options?: RequestOptions; cacheOptions?: CacheOptions }) => {
        return sendRequestWithCache({ method: 'GET', url: 'api/companies?include=users,tags_count', ...message });
    },
    GetProfile: (message: { options?: RequestOptions; cacheOptions?: CacheOptions }) => {
        return sendRequestWithCache({ method: 'GET', url: 'api/profile?include=company,keys,tasks,tags_count', ...message });
    },
    GetMaterialCategories: (message: { options?: RequestOptions; cacheOptions?: CacheOptions }) => {
        return sendRequestWithCache({ method: 'GET', url: 'api/material-categories', ...message });
    },
    GetProductCategories: (message: { options?: RequestOptions; cacheOptions?: CacheOptions }) => {
        return sendRequestWithCache({ method: 'GET', url: 'api/product-categories', ...message });
    },
    GetProduct: (message: { payload: { id: string }; options?: RequestOptions; cacheOptions?: CacheOptions }) => {
        return sendRequestWithCache({
            method: 'GET',
            url: `api/products/${message.payload.id}?include[]=documentation&include[]=imports&include[]=components&include[]=items_count`,
            ...{ options: message.options, cacheOptions: message.cacheOptions },
        });
    },
    GetItem: (message: { payload: { id: string }; options?: RequestOptions; cacheOptions?: CacheOptions }) => {
        return sendRequestWithCache({
            method: 'GET',
            url: `api/items/${message.payload.id}?include=product,tag,components,documentations,imports,history,owner,company`,
            ...{ options: message.options, cacheOptions: message.cacheOptions },
        });
    },
    GetPublicItem: (message: { payload: { tagId: string }; options?: RequestOptions; cacheOptions?: CacheOptions }) => {
        return sendRequestWithCache({
            method: 'GET',
            url: `api/items/tags/${message.payload.tagId}?include=product,tag,components,documentations,imports,history,owner,company`,
            ...{ options: message.options, cacheOptions: message.cacheOptions },
        });
    },
    GetCompanyUsers: (message: { payload: { id: number }; options?: RequestOptions; cacheOptions?: CacheOptions }) => {
        return sendRequestWithCache({
            method: 'GET',
            url: `api/companies/${message.payload.id}/users`,
            ...{ options: message.options, cacheOptions: message.cacheOptions },
        });
    },
    EditUser: (message: { payload: { id: string; firstName: string; lastName: string; phone: string }; options?: RequestOptions }) => {
        return sendRequestWithCache({ method: 'PATCH', url: `api/users/${message.payload.id}`, payload: { ...message.payload, id: undefined }, options: message.options });
    },
    EditCompany: (message: {
        payload: { id: number; name: string; registration: string; website: string; email: string; country: string | null; foundationYear: number | null; logo: string | null };
        options?: RequestOptions;
    }) => {
        return sendRequestWithCache({ method: 'PATCH', url: `api/companies/${message.payload.id}`, payload: { ...message.payload, id: undefined }, options: message.options });
    },
    GetImports: (message: { options?: RequestOptions; cacheOptions?: CacheOptions }) => {
        return sendRequestWithCache({ method: 'GET', url: 'api/imports', ...message });
    },
    GetImportTemplates: (message: { options?: RequestOptions; cacheOptions?: CacheOptions }) => {
        return sendRequestWithCache({ method: 'GET', url: 'api/imports/templates', ...message });
    },
    GetAlgoliaClient: (message: { payload: { id: string; key: string } }) => {
        return AlgoliaClient.getClient(message.payload.id, message.payload.key);
    },
    UploadDataImport: (message: { payload: FormData | { filePath: string; type: ExcelImportType }; options?: RequestOptions }) => {
        return sendRequestWithCache({ method: 'POST', url: 'api/imports', ...message });
    },
    DownloadImport: (message: { payload: { id: number }; options?: RequestOptions }) => {
        return sendRequestWithCache({ method: 'GET', url: `api/imports/${message.payload.id}/download`, ...{ options: { ...message.options, responseType: 'blob' } } });
    },
    DownloadTemplate: (message: { payload: { id: number }; options?: RequestOptions }) => {
        return sendRequestWithCache({ method: 'GET', url: `api/imports/templates/${message.payload.id}/download`, ...{ options: { ...message.options, responseType: 'blob' } } });
    },
    ConfirmEmailUpdate: (message: { payload: { emailUpdateId: string }; options?: RequestOptions }) => {
        return sendRequestWithCache({ method: 'POST', url: `api/email-updates/${message.payload.emailUpdateId}/confirm`, options: message.options });
    },
    InviteUserToCompany: (message: { payload: { email: string; role: string }; options?: RequestOptions }) => {
        return sendRequestWithCache({ method: 'POST', url: `api/users/invite`, ...message });
    },
    AcceptInvitationToCompany: (message: {
        payload: { email: string; phone: string; firstName: string; lastName: string; companyId: string; expire: number; role: UserRole; sign: string };
        options?: RequestOptions;
    }) => {
        return sendRequestWithCache({ method: 'PUT', url: `api/companies/${message.payload.companyId}/users`, options: message.options, payload: { ...message.payload, companyId: undefined } });
    },
};

async function sendRequestWithCache(message: { method: Method; url: string; payload?: any; cacheOptions?: CacheOptions; options?: RequestOptions }) {
    if (message.cacheOptions && message.cacheOptions.store && message.cacheOptions.key) {
        if ((message.cacheOptions.store as { [key: string]: any })[message.cacheOptions.key] !== null && !message.cacheOptions.bustCache) {
            return (message.cacheOptions.store as { [key: string]: any })[message.cacheOptions.key];
        }
        const result = await ApiClient.send({ method: message.method, url: message.url, options: message?.options, payload: message.payload });
        (message.cacheOptions.store as { [key: string]: any })[message.cacheOptions.key] = result;
        return result;
    }
    return ApiClient.send({ method: message.method, url: message.url, options: message.options, payload: message.payload });
}

export { ApiRepository };
