
import { mapActions } from 'pinia';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { useImportsStore, useMainStore } from '@/plugins/store';
import { ExcelImportType, UploadDataImportResults } from '@/types';
import ImportResults from '@/views/dashboard/import/data/ImportResults.vue';
import Spinner from '@/components/common/Spinner.vue';

export default defineComponent({
    components: { ImportResults, Spinner },
    props: {
        type: {
            type: String as () => ExcelImportType,
            required: true,
        },
    },
    emits: ['import-success'],
    data() {
        return {
            uploadInProgress: false,
            filePath: '',
            uploadResults: null as null | UploadDataImportResults,
            errorDetected: false,
            successDetected: false,
            toast: useToast(),
        };
    },
    methods: {
        ...mapActions(useImportsStore, ['uploadDataImport', 'getImports']),
        ...mapActions(useMainStore, ['getProfile']),
        async onFileInputChange(event: Event) {
            const inputElement = event.target as HTMLInputElement;
            if (inputElement && inputElement.files && inputElement.files.length) {
                this.uploadInProgress = true;
                try {
                    const uploadResult = await this.uploadDataImport({ file: inputElement.files[0], type: this.$props.type }, { skipErrorReporting: true });
                    if (uploadResult.filePath && uploadResult.results) {
                        this.filePath = uploadResult.filePath;
                        this.uploadResults = uploadResult.results;
                        this.uploadInProgress = false;
                    } else {
                        this.toast.error('Something went wrong during the import');
                    }
                } catch (error: any) {
                    this.toast.error('Something went wrong during the import');
                }
                this.uploadInProgress = false;
            }
        },
        async onConfirmImport() {
            this.uploadInProgress = true;
            try {
                const uploadResult = await this.uploadDataImport({ filePath: this.filePath, type: this.$props.type }, { skipErrorReporting: true });
                if (uploadResult.success) {
                    this.toast.success('Import success', { timeout: 3000 });
                    this.getImports(true);
                    this.getProfile(true);
                    this.$emit('import-success');
                    this.uploadResults = null;
                    this.filePath = '';
                    this.errorDetected = false;
                    this.successDetected = false;
                }
            } catch (error: any) {
                this.toast.error('Something went wrong during the import');
            }
            this.uploadInProgress = false;
        },
        onReset() {
            this.filePath = '';
            this.uploadResults = null;
            this.errorDetected = false;
            this.successDetected = false;
        },
    },
});
