import { UserRole } from '@/types';

export default function formatUserRole(role: UserRole) {
    return {
        [UserRole.SuperAdmin]: 'Super admin',
        [UserRole.CompanyAdmin]: 'Admin',
        [UserRole.Member]: 'Member',
        [UserRole.AppUser]: 'App user',
    }[role];
}
