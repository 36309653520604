import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onSubmit"]
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createBlock(_component_ConfirmationModal, {
    header: _ctx.confirmationModalHeader,
    body: _ctx.confirmationModalBody,
    "open-from-toggle-position": false,
    onConfirm: _ctx.onSubmit
  }, {
    toggle: _withCtx(({ open }) => [
      _createElementVNode("form", {
        class: "space-y-6 max-w-150 flex flex-col pb-8",
        onSubmit: _withModifiers(open, ["prevent"])
      }, [
        _createVNode(_component_CustomInput, {
          value: _ctx.form.firstName,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.firstName) = $event)),
          label: "First name",
          "show-required-indication": ""
        }, null, 8, ["value"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.form.lastName,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.lastName) = $event)),
          label: "Last name",
          "show-required-indication": ""
        }, null, 8, ["value"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.form.phone,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.phone) = $event)),
          disabled: "",
          label: "Phone number",
          "show-required-indication": ""
        }, null, 8, ["value"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.form.email,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.email) = $event)),
          disabled: "",
          label: "E-mail",
          "show-required-indication": ""
        }, null, 8, ["value"]),
        _createVNode(_component_CustomSelect, {
          value: _ctx.form.role,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.role) = $event)),
          disabled: !_ctx.canEditRole,
          items: _ctx.PICKABLE_ROLES,
          label: "Role",
          width: "w-full",
          "item-value": "value",
          "item-text": "text",
          "show-required-indication": ""
        }, null, 8, ["value", "disabled", "items"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CustomButton, {
            icon: "/assets/icons/ic_edit.svg",
            text: _ctx.submitButtonText
          }, null, 8, ["text"])
        ])
      ], 40, _hoisted_1)
    ]),
    _: 1
  }, 8, ["header", "body", "onConfirm"]))
}