import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductFilters = _resolveComponent("ProductFilters")!
  const _component_ProductsGrid = _resolveComponent("ProductsGrid")!
  const _component_AisInstantSearch = _resolveComponent("AisInstantSearch")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, { heading: "Product models" }, {
    default: _withCtx(() => [
      (_ctx.profile && _ctx.searchClient)
        ? (_openBlock(), _createBlock(_component_AisInstantSearch, {
            key: 0,
            routing: _ctx.routing,
            "index-name": `${_ctx.profile.keys.algolia.prefix}products`,
            "search-client": _ctx.searchClient
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ProductFilters),
                _createElementVNode("div", null, [
                  _createVNode(_component_ProductsGrid)
                ])
              ])
            ]),
            _: 1
          }, 8, ["routing", "index-name", "search-client"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}