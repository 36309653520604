
import { mapState } from 'pinia';
import { defineComponent } from 'vue';
import { useMainStore } from '@/plugins/store';

export default defineComponent({
    computed: {
        ...mapState(useMainStore, ['breadcrumbs']),
    },
});
