
import { mapActions } from 'pinia';
import { defineComponent } from 'vue';
import { useImportsStore } from '@/plugins/store';
import { Import } from '@/types';

export default defineComponent({
    props: {
        params: {
            type: Object as () => { value: Import },
            required: true,
        },
    },
    methods: {
        ...mapActions(useImportsStore, ['downloadImport']),
        onClick() {
            this.downloadImport(this.$props.params.value);
        },
    },
});
