import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-5 grid-rows-2 grid-flow-col h-[18rem] gap-1" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "col-span-2 bg-green-300/[0.3]"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "px-4 pt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Swiper = _resolveComponent("Swiper")!
  const _component_Progress = _resolveComponent("Progress")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Swiper, {
      index: _ctx.currentIndex,
      "onUpdate:index": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentIndex) = $event)),
      class: "h-[18rem]",
      items: _ctx.imageGroups,
      "space-between": 8
    }, {
      default: _withCtx(({ value }) => [
        _createElementVNode("div", _hoisted_1, [
          (value.length > 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([[{ 'row-span-2': value.length === 2 }], "col-span-2 bg-green-300/[0.3] flex"])
              }, [
                _createElementVNode("img", {
                  class: _normalizeClass(["w-full h-full", value[1].classes]),
                  src: value[1].source
                }, null, 10, _hoisted_2)
              ], 2))
            : _createCommentVNode("", true),
          (value.length > 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("img", {
                  class: _normalizeClass(["w-full h-full", value[2].classes]),
                  src: value[2].source
                }, null, 10, _hoisted_4)
              ]))
            : _createCommentVNode("", true),
          (value.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass([[{ '': value.length === 3 }, { 'col-span-5': value.length === 1 }], "row-span-2 col-span-3 bg-green-300/[0.3] flex"])
              }, [
                _createElementVNode("img", {
                  class: _normalizeClass(["max-w-full max-h-full m-auto", value[0].classes]),
                  src: value[0].source
                }, null, 10, _hoisted_5)
              ], 2))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["index", "items"]),
    (_ctx.imageGroups.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_Progress, {
            steps: _ctx.imageGroups.length,
            "active-step": _ctx.currentIndex
          }, null, 8, ["steps", "active-step"])
        ]))
      : _createCommentVNode("", true)
  ]))
}