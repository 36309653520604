import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6 gap-y-8" }
const _hoisted_2 = { class: "bg-green-300/[0.3] flex justify-center items-center py-10" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "space-y-4 flex flex-col h-full justify-between" }
const _hoisted_5 = { class: "text-1.5xl font-medium" }
const _hoisted_6 = { class: "font-medium truncate" }
const _hoisted_7 = { class: "font-medium truncate" }
const _hoisted_8 = { class: "text-[13px]" }
const _hoisted_9 = { class: "opacity-80" }
const _hoisted_10 = { class: "opacity-80" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AisHits = _resolveComponent("AisHits")!

  return (_openBlock(), _createBlock(_component_AisHits, null, {
    default: _withCtx(({ items }) => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items, (product) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: product.id,
            to: { name: 'Product', params: { id: product.id } },
            class: "block border-b-default space-y-5 pb-5 flex flex-col"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  class: "max-w-4/5 h-52",
                  src: product.image
                }, null, 8, _hoisted_3)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, _toDisplayString(product.category?.name || ''), 1),
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(product.name), 1),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(product.model), 1),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(product.description), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, "Product number: " + _toDisplayString(product.number), 1),
                  _createElementVNode("div", _hoisted_10, "Last updated: " + _toDisplayString(_ctx.$format(new Date(product.updatedOn), 'dd.MM.yyyy')), 1)
                ])
              ])
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}