
import { defineComponent } from 'vue';
import { ColDef } from 'ag-grid-community';
import { ItemHistory, ItemWithAssociations } from '@/types';
import GenericTable from '@/components/tables/GenericTable.vue';
import DownloadImportCellRenderer from '@/components/tables/DownloadImportCellRenderer.vue';
import ItemHistoryModal from '@/views/dashboard/overview/item/ItemHistoryModal.vue';

export default defineComponent({
    /* eslint-disable vue/no-unused-components */
    components: { GenericTable, DownloadImportCellRenderer, ItemHistoryModal },
    props: {
        item: {
            type: Object as () => ItemWithAssociations,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                { headerName: '', field: 'icon', sortable: false, cellRenderer: 'IconCellRenderer', width: 60 },
                { headerName: 'Time', field: 'time', sortable: true, cellRenderer: 'DateCellRenderer' },
                { headerName: 'Activity type', field: 'type', sortable: true },
                { headerName: 'By', field: 'by', sortable: true },
                { headerName: '', field: 'action', sortable: false, width: 20, cellRenderer: 'ActionsCellRenderer' },
            ] as ColDef[],
            activeOwnership: null as null | ItemHistory,
        };
    },
    computed: {
        items() {
            return (
                this.item.history?.map((currentHistory) => {
                    return {
                        icon: currentHistory.type === 'creation' ? '/assets/icons/ic_production.svg' : '/assets/icons/ic_change_of_ownership.svg',
                        time: currentHistory.creationDate,
                        type: currentHistory.title,
                        by: currentHistory.data.company?.name || (currentHistory.data as any).user?.name || 'N/A',
                        value: currentHistory,
                    };
                }) || ([] as any[])
            ).sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
        },
    },
    methods: {
        onItemClicked(item: { [key: string]: any; value: ItemHistory }, open: CallableFunction) {
            this.activeOwnership = item.value;
            open();
        },
    },
});
