import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-183c1649"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid md:grid-cols-2 xl:grid-cols-3 1.5xl:grid-cols-4 gap-4 md:gap-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemPerformanceCardMaterialsInfoPoints = _resolveComponent("ItemPerformanceCardMaterialsInfoPoints")!
  const _component_ItemPerformanceCard = _resolveComponent("ItemPerformanceCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.performanceCards, (performanceCard) => {
      return (_openBlock(), _createBlock(_component_ItemPerformanceCard, {
        id: performanceCard.id,
        key: performanceCard.title,
        class: "info-point-card bg-green-300/75 odd:bg-green-300/25 md:!bg-green-300/25 md:[&:nth-child(4n)]:!bg-green-300/75 md:[&:nth-child(4n+1)]:!bg-green-300/75 xl:!bg-green-300/75 xl:odd:!bg-green-300/25 1.5xl:[&:nth-child(n)]:!bg-green-300/25",
        title: performanceCard.title,
        icon: performanceCard.icon,
        "info-points": performanceCard.infoPoints,
        "is-public": _ctx.isPublic,
        onExpandClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('expand-card-clicked')))
      }, {
        "info-points-materials": _withCtx(() => [
          _createVNode(_component_ItemPerformanceCardMaterialsInfoPoints, { item: _ctx.item }, null, 8, ["item"])
        ]),
        _: 2
      }, 1032, ["id", "title", "icon", "info-points", "is-public"]))
    }), 128))
  ]))
}