
import { defineComponent } from 'vue';
import { DocumentationType, ItemWithAssociations } from '@/types';
import Swiper from '@/components/common/Swiper.vue';
import Progress from '@/components/common/Progress.vue';

export default defineComponent({
    components: { Swiper, Progress },
    props: {
        item: {
            type: Object as () => ItemWithAssociations,
            required: true,
        },
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    computed: {
        images() {
            const result: { source: string; classes: string }[] = [];
            if (this.item.product.image) result.push({ source: this.item.product.image, classes: '' });
            if (this.item.product.company?.logoUrl) result.push({ source: this.item.product.company?.logoUrl, classes: 'max-w-[85%] m-auto max-w-full max-h-full !w-auto !h-auto' });
            if (this.item.product.documentations) {
                result.push(
                    ...this.item.product.documentations
                        .filter((currentDocumentation) => currentDocumentation.type === DocumentationType.Image)
                        .map((currentDocumentation) => {
                            return { source: currentDocumentation.url, classes: '' };
                        })
                );
            }
            return result;
        },
        imageGroups() {
            return this.images.reduce((resultArray, item, index) => {
                const chunkIndex = index < 3 ? 0 : index - 2;
                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = { index: chunkIndex, id: `slide-${chunkIndex}`, value: [] };
                }
                resultArray[chunkIndex].value.push(item);
                return resultArray;
            }, [] as { index: number; id: string; value: { source: string; classes: string }[] }[]);
        },
    },
});
