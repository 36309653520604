
import { defineComponent } from 'vue';
import { ItemWithAssociations } from '@/types';
import DocumentationList from '@/components/common/DocumentationList.vue';

export default defineComponent({
    components: { DocumentationList },
    props: {
        item: {
            type: Object as () => ItemWithAssociations,
            required: true,
        },
    },
});
