
import { defineComponent } from 'vue';
import { ItemWithAssociations } from '@/types';
import ComponentMaterialModal from '@/components/common/componentModals/ComponentMaterialModal.vue';

export default defineComponent({
    components: { ComponentMaterialModal },
    props: {
        item: {
            type: Object as () => ItemWithAssociations,
            required: true,
        },
    },
});
