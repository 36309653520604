
import { defineComponent } from 'vue';
import { ProductWithReferences } from '@/types';
import ProductDocumentations from '@/views/dashboard/overview/product/ProductDocumentations.vue';
import ProductMaterials from '@/views/dashboard/overview/product/ProductMaterials.vue';
import ProductBillOfMaterials from '@/views/dashboard/overview/product/ProductBillOfMaterials.vue';
import ProductLog from '@/views/dashboard/overview/product/ProductLog.vue';

export default defineComponent({
    components: { ProductDocumentations, ProductMaterials, ProductBillOfMaterials, ProductLog },
    props: {
        product: {
            type: Object as () => ProductWithReferences,
            required: true,
        },
    },
});
