import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-6"
}
const _hoisted_2 = { class: "flex space-x-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductActions = _resolveComponent("ProductActions")!
  const _component_ProductReferences = _resolveComponent("ProductReferences")!
  const _component_ProductGeneralInformation = _resolveComponent("ProductGeneralInformation")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    "content-wrapper-classes": null,
    heading: _ctx.headings.header,
    subheading: _ctx.headings.subheader
  }, {
    "top-right": _withCtx(() => [
      (_ctx.product)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass({ 'pt-3': _ctx.headings.subheader, '-mt-2': !_ctx.headings.subheader })
          }, [
            _createVNode(_component_ProductActions, { product: _ctx.product }, null, 8, ["product"])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.product)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ProductReferences, {
                product: _ctx.product,
                class: "w-2/3 pt-10 border-r-default"
              }, null, 8, ["product"]),
              _createVNode(_component_ProductGeneralInformation, {
                product: _ctx.product,
                class: "w-1/3 pt-3"
              }, null, 8, ["product"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["heading", "subheading"]))
}