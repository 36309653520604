
/* Source:
https://github.com/scriptcoded/vue-3-card-swiper/blob/main/src/components/Swiper.vue
*/

import { nextTick, PropType, toRefs, watch, defineComponent } from 'vue';
import SwiperCore, { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.min.css';

SwiperCore.use([Virtual]);
export interface SlideItem {
    index: number;
    id: string;
    value: any;
}
export default defineComponent({
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        items: {
            type: Array as PropType<SlideItem[]>,
            required: true,
        },
        spaceBetween: {
            type: Number,
            default: 0,
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    emits: ['update:index'],
    setup(props, context) {
        const { items, index } = toRefs(props);
        let swiper: SwiperCore;
        const counterChanges = (newItems: SlideItem[], oldItems: SlideItem[]) => {
            if (swiper) {
                const lowest = Math.min(...newItems.map((i) => i.index));
                const oldLowest = Math.min(...oldItems.map((i) => i.index));
                const newSlides = -(lowest - oldLowest);
                nextTick(() => {
                    for (let i = 0; i < newSlides; i++) {
                        swiper.slideNext(0);
                    }
                });
            }
        };
        watch(items, counterChanges);
        const reactToIndex = () => {
            const activeItem = items.value[swiper.activeIndex];
            if (index.value !== activeItem.index) {
                const swiperIndex = items.value.findIndex((item) => item.index === index.value);
                swiper.slideTo(swiperIndex, 0);
            }
        };
        watch(index, reactToIndex);
        const onSwiper = (swiperIn: any) => {
            swiper = swiperIn;
            reactToIndex();
        };
        const onActiveIndexChange = () => {
            const activeItem = items.value[swiper.activeIndex];
            context.emit('update:index', activeItem.index);
        };
        return {
            onSwiper,
            onActiveIndexChange,
        };
    },
});
