
import { defineComponent } from 'vue';
import { ProductWithReferences } from '@/types';

export default defineComponent({
    props: {
        product: {
            type: Object as () => ProductWithReferences,
            required: true,
        },
    },
    methods: {
        onCreateReportClicked() {
            alert('Not yet implemented');
        },
    },
});
