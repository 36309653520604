import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = {
  key: 0,
  class: "space-y-4"
}
const _hoisted_3 = { class: "text-2xl font-semibold" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    size: "w-120",
    padding: "p-6",
    "open-from-toggle-position": false,
    "close-button": ""
  }, {
    toggle: _withCtx((state) => [
      _renderSlot(_ctx.$slots, "toggle", _normalizeProps(_guardReactiveProps(state)))
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.ownership)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.ownership.title), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoPoints, (infoPoint) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: infoPoint.title
                }, [
                  _createElementVNode("div", {
                    class: "text-[15px] opacity-70",
                    innerHTML: _ctx.sanitizeHtml(infoPoint.title, { allowedAttributes: { '*': ['class', 'style'], a: ['href', 'target'] } })
                  }, null, 8, _hoisted_4),
                  _createElementVNode("div", {
                    class: "font-medium",
                    innerHTML: _ctx.sanitizeHtml(infoPoint.content, { allowedAttributes: { '*': ['class', 'style'], a: ['href', 'target'] } })
                  }, null, 8, _hoisted_5)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }))
}