import { RouteRecordRaw } from 'vue-router';
import Auth from '@/views/auth/Auth.vue';
import SignIn from '@/views/auth/SignIn.vue';
import Register from '@/views/auth/Register.vue';
import ForgotPassword from '@/views/auth/ForgotPassword.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';
import Products from '@/views/dashboard/overview/products/Products.vue';
import Product from '@/views/dashboard/overview/product/Product.vue';
import Items from '@/views/dashboard/overview/items/Items.vue';
import Item from '@/views/dashboard/overview/item/Item.vue';
import Reuse from '@/views/dashboard/insights/reuse/Reuse.vue';
import Data from '@/views/dashboard/import/data/Data.vue';
import Tags from '@/views/dashboard/import/tags/Tags.vue';
import Settings from '@/views/dashboard/settings/Settings.vue';
import Companies from '@/views/dashboard/admin/companies/Companies.vue';
import User from '@/views/dashboard/settings/User.vue';
import Public from '@/views/public/Public.vue';
import PublicItem from '@/views/public/items/PublicItem.vue';
import EmailUpdate from '@/views/auth/emailUpdate/EmailUpdate.vue';
import UserInvite from '@/views/auth/userInvite/UserInvite.vue';
import NotFound from '@/views/NotFound.vue';
import { UserRole, SideBarNavigationGroup } from '@/types';

export const routes: RouteRecordRaw[] = [
    {
        path: '/auth',
        name: 'Auth',
        redirect: '/auth/sign-in',
        component: Auth,
        children: [
            {
                path: '/auth/sign-in',
                name: 'SignIn',
                component: SignIn,
                meta: { skipAuthenticationGuard: true },
            },
            {
                path: '/auth/register',
                name: 'Register',
                component: Register,
                meta: { skipAuthenticationGuard: true },
            },
            {
                path: '/auth/forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
                meta: { skipAuthenticationGuard: true },
            },
        ],
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        redirect: '/items',
        children: [
            {
                path: '/data',
                name: 'Data',
                component: Data,
                meta: {
                    sideBarNavigationGroup: SideBarNavigationGroup.Import,
                    sideBarNavigationName: 'Data',
                    iconInactiveSource: '/assets/icons/ic_data_inactive.svg',
                    iconActiveSource: '/assets/icons/ic_data_active.svg',
                    documentTitle: 'Data',
                    requiresRoles: [UserRole.CompanyAdmin, UserRole.Member],
                    requiresMainStoreGetter: 'companyIsBrandOwner',
                },
            },
            {
                path: '/tags',
                name: 'Tags',
                component: Tags,
                meta: {
                    sideBarNavigationGroup: SideBarNavigationGroup.Import,
                    sideBarNavigationName: 'Tags',
                    iconInactiveSource: '/assets/icons/ic_micollect_tag_ids_inactive.svg',
                    iconActiveSource: '/assets/icons/ic_micollect_tag_ids_active.svg',
                    documentTitle: 'Tags',
                    requiresRoles: [UserRole.SuperAdmin],
                },
            },
            {
                path: '/products',
                name: 'Products',
                component: Products,
                meta: {
                    sideBarNavigationGroup: SideBarNavigationGroup.Overview,
                    sideBarNavigationName: 'Product models',
                    iconInactiveSource: '/assets/icons/ic_product_models_inactive.svg',
                    iconActiveSource: '/assets/icons/ic_product_models_active.svg',
                    documentTitle: 'Products',
                    requiresMainStoreGetter: 'companyIsBrandOwnerOrUserIsSuperAdmin',
                },
            },
            {
                path: '/products/:id',
                name: 'Product',
                component: Product,
                meta: { documentTitle: 'Product', parentRouteName: 'Products' },
            },
            {
                path: '/items',
                name: 'Items',
                component: Items,
                meta: {
                    sideBarNavigationGroup: SideBarNavigationGroup.Overview,
                    sideBarNavigationName: 'Items',
                    iconInactiveSource: '/assets/icons/ic_items_inactive.svg',
                    iconActiveSource: '/assets/icons/ic_items_active.svg',
                    documentTitle: 'Items',
                },
            },
            {
                path: '/items/:id',
                name: 'Item',
                component: Item,
                meta: { documentTitle: 'Item', parentRouteName: 'Items' },
            },
            {
                path: '/reuse',
                name: 'Reuse',
                component: Reuse,
                meta: {
                    sideBarNavigationGroup: SideBarNavigationGroup.Insights,
                    sideBarNavigationName: 'Reuse',
                    iconInactiveSource: '/assets/icons/ic_reuse_inactive.svg',
                    iconActiveSource: '/assets/icons/ic_reuse_active.svg',
                    documentTitle: 'Reuse',
                    requiresRoles: ['DISABLED'],
                },
            },
            {
                path: '/settings',
                name: 'Settings',
                component: Settings,
                meta: { documentTitle: 'Settings' },
            },
            {
                path: '/companies',
                name: 'Companies',
                component: Companies,
                meta: {
                    sideBarNavigationGroup: SideBarNavigationGroup.Administrate,
                    sideBarNavigationName: 'Companies',
                    iconInactiveSource: '/assets/icons/ic_reuse_inactive.svg',
                    iconActiveSource: '/assets/icons/ic_reuse_active.svg',
                    documentTitle: 'Companies',
                },
            },
            {
                path: '/users/:id',
                name: 'User',
                component: User,
                meta: { documentTitle: 'User' },
            },
        ],
    },
    {
        path: '/public',
        name: 'Public',
        component: Public,
        redirect: '/not-found',
        children: [
            {
                path: '/public/items/:id',
                name: 'PublicItem',
                component: PublicItem,
                meta: { documentTitle: 'Item', skipAuthenticationGuard: true },
            },
        ],
    },
    {
        path: '/email-updates/:id',
        name: 'EmailUpdate',
        component: EmailUpdate,
        meta: { documentTitle: 'Update email', skipAuthenticationGuard: true },
    },
    {
        path: '/user/invite',
        name: 'UserInvite',
        component: UserInvite,
        meta: { skipAuthenticationGuard: true },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: { skipAuthenticationGuard: true },
    },
];
