
import { defineComponent } from 'vue';
import PublicLayout from '@/components/layouts/public/PublicLayout.vue';
import PreloadSpinner from '@/components/common/PreloadSpinner.vue';

export default defineComponent({
    components: { PublicLayout, PreloadSpinner },
    data() {
        return {
            showPreloadSpinner: process.env.NODE_ENV !== 'development',
        };
    },
    created() {
        setTimeout(() => {
            this.showPreloadSpinner = false;
        }, 2000);
    },
});
