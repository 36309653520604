
import { defineComponent } from 'vue';
import { ColDef } from 'ag-grid-community';
import { ProductWithReferences } from '@/types';
import GenericTable from '@/components/tables/GenericTable.vue';
import DownloadImportCellRenderer from '@/components/tables/DownloadImportCellRenderer.vue';

export default defineComponent({
    /* eslint-disable vue/no-unused-components */
    components: { GenericTable, DownloadImportCellRenderer },
    props: {
        product: {
            type: Object as () => ProductWithReferences,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                { headerName: 'File name', field: 'fileName', sortable: true },
                { headerName: 'Type', field: 'type', sortable: true },
                { headerName: 'Uploaded by', field: 'userEmail', sortable: true },
                { headerName: 'Date', field: 'creationDate', sortable: true },
                { headerName: '', field: 'import', sortable: false, cellRenderer: 'DownloadImportCellRenderer', width: 68 },
            ] as ColDef[],
        };
    },
    computed: {
        items() {
            return (
                this.product.imports
                    ?.map((currentImport) => {
                        const filePathConstituents = currentImport.import.filePath.split('/');
                        const filePathWithTimestampConstituents = filePathConstituents[filePathConstituents.length - 1].split('_');
                        return {
                            fileName: filePathWithTimestampConstituents.slice(1).join(''),
                            import: currentImport.import,
                            type: currentImport.effectType,
                            creationDate: (this as any).$format(new Date(currentImport.import.creationDate), 'dd.MM.yyyy'),
                            userEmail: currentImport.import.user.email,
                        };
                    })
                    .sort((a, b) => {
                        return new Date(b.import.creationDate).getTime() - new Date(a.import.creationDate).getTime();
                    }) || []
            );
        },
    },
});
