export enum ItemFacet {
    ProductName = 'product.name',
    ProductNumber = 'product.number',
    ProductPrimaryMaterial = 'product.primaryMaterial',
    ProductPrimaryMaterialVariant = 'product.primaryMaterialVariant',
    ProductSecondaryMaterial = 'product.secondaryMaterial',
    ProductSecondaryMaterialVariant = 'product.secondaryMaterialVariant',
    Product = 'product.model',
    ProductDescription = 'product.description',
    ProductDesignerName = 'product.designerName',
    ProductDesignYear = 'product.designYear',
    ProductDesignUrl = 'product.designUrl',
    ProductGTIN = 'product.GTIN',
    ProductCommodityCode = 'product.commodityCode',
    ProductCo2Date = 'product.co2Date',
    ProductCo2Total = 'product.co2Total',
    ProductDegassingClass = 'product.degassingClass',
    ProductFormaldehyde = 'product.formaldehyde',
    ProductTVOC = 'product.TVOC',
    ProductTVOCNumber = 'product.TVOCNumber',
    ProductDurability = 'product.durability',
    ProductWaste = 'product.waste',
    ProductWasteDescription = 'product.wasteDescription',
    ProductReusable = 'product.circularity.reusable',
    ProductReducable = 'product.circularity.reducable',
    ProductRecyclable = 'product.circularity.recyclable',
    ProductRecoverable = 'product.circularity.recoverable',
    ProductWeight = 'product.weight',
    ProductWeightPacking = 'product.weightPacking',
    ProductDimensionsHeight = 'product.dimensionsHeight',
    ProductDimensionsWidth = 'product.dimensionsWidth',
    ProductDimensionsDepth = 'product.dimensionsDepth',
    ProductDimensionsDiameter = 'product.dimensionsDiameter',
    ProductDimensionsUrl = 'product.dimensionsUrl',

    ProductCategoryName = 'product.category.name',

    ProductDocumentationName = 'product.documentation.name',
    ProductDocumentationType = 'product.documentation.type',
    ProductDocumentationFieldType = 'product.documentation.fieldType',

    OwnerName = 'owner.name',

    FacilityId = 'facilityId',
    OrderNumber = 'orderNo',
    ArticleNumber = 'articleNo',
    SignOffId = 'signOffId',
    AssemblyBatchNumber = 'assemblyBatchNo',
}
