
import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import sanitizeHtml from 'sanitize-html';
import { useMainStore, useOverviewStore } from '@/plugins/store';
import DashboardContentLayout from '@/components/layouts/dashboard/content/DashboardContentLayout.vue';
import ItemsTable from '@/views/dashboard/overview/items/ItemsTable.vue';
import ItemFilters from '@/views/dashboard/overview/items/filters/ItemFilters.vue';
import useAlgoliaHistoryMode from '@/util/composables/useAlgoliaHistoryMode';

export default defineComponent({
    components: { DashboardContentLayout, ItemsTable, ItemFilters },
    setup() {
        return {
            ...useAlgoliaHistoryMode('items', /\/items($|\?|\/\?|\/$)/, true),
        };
    },
    computed: {
        ...mapState(useMainStore, ['searchClient', 'profile']),
        ...mapWritableState(useOverviewStore, ['activeProduct']),
        headings(): { header: string; subheader: string; image: string } | null {
            if (this.activeProduct) {
                return {
                    header: this.activeProduct.name,
                    subheader: sanitizeHtml(
                        [this.activeProduct.model, this.activeProduct.description].filter((currentsubheaderComponent) => currentsubheaderComponent).join(` <span class="opacity-20 font-bold">•</span> `),
                        { allowedAttributes: { '*': ['class'] } }
                    ),
                    image: this.activeProduct.image,
                };
            }
            return null;
        },
    },
    unmounted() {
        this.activeProduct = null;
    },
});
