import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "divide-y-default" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropDown = _resolveComponent("DropDown")!

  return (_openBlock(), _createBlock(_component_DropDown, {
    expand: _ctx.expandDropdown,
    "onUpdate:expand": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expandDropdown) = $event)),
    "dropdown-class": "w-full"
  }, {
    "dropdown-content": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortingOrders, (sortingOrder) => {
          return (_openBlock(), _createElementBlock("button", {
            key: sortingOrder,
            class: "p-4 w-full text-left hover:bg-gray-100 last:rounded-b-md first:rounded-t-md",
            type: "button",
            onClick: ($event: any) => (_ctx.onSortingOrderClicked(sortingOrder))
          }, _toDisplayString(sortingOrder), 9, _hoisted_2))
        }), 128))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("button", {
        class: "justify-between flex items-center w-52 border-default rounded-md pl-4 pr-3 h-14",
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandDropdown = !_ctx.expandDropdown))
      }, [
        _createElementVNode("div", null, _toDisplayString(_ctx.activeSortingOrder), 1),
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            class: _normalizeClass([{ 'rotate-180': _ctx.expandDropdown }, "transition-transform h-3 w-4"]),
            src: "/assets/icons/ic_chevron_down.svg"
          }, null, 2)
        ])
      ])
    ]),
    _: 1
  }, 8, ["expand"]))
}