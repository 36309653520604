
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useImportsStore } from '@/plugins/store';

export default defineComponent({
    computed: {
        ...mapState(useImportsStore, ['importTemplates']),
    },
    created() {
        this.getImportTemplates();
    },
    methods: {
        ...mapActions(useImportsStore, ['getImportTemplates', 'downloadTemplate']),
        async onDownloadTemplateClicked() {
            if (this.importTemplates?.length === 1) {
                await this.downloadTemplate(this.importTemplates[0]);
            }
        },
    },
});
