import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardContentHeader = _resolveComponent("DashboardContentHeader")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass([{ 'divide-y divide-grey-900/20': _ctx.hasBottomBorder }, "w-full pt-10 pb-6 pl-10 mx-auto"]),
    style: {"min-height":"calc(100vh - 6.5rem)"}
  }, [
    _createVNode(_component_DashboardContentHeader, {
      heading: _ctx.heading,
      subheading: _ctx.subheading,
      class: "pb-6"
    }, {
      header: _withCtx(() => [
        _renderSlot(_ctx.$slots, "header")
      ]),
      "top-right": _withCtx(() => [
        _renderSlot(_ctx.$slots, "top-right")
      ]),
      _: 3
    }, 8, ["heading", "subheading"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.contentWrapperClasses)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}