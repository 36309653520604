import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericTable = _resolveComponent("GenericTable")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_InviteCompanyUserModal = _resolveComponent("InviteCompanyUserModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GenericTable, {
      headers: _ctx.headers,
      items: _ctx.items,
      "clickable-rows": "",
      onItemClicked: _ctx.onUserClicked
    }, null, 8, ["headers", "items", "onItemClicked"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InviteCompanyUserModal, null, {
        toggle: _withCtx(({ open }) => [
          _createVNode(_component_CustomButton, {
            type: "button",
            icon: "/assets/icons/ic_import.svg",
            text: "Invite user",
            onClick: open
          }, null, 8, ["onClick"])
        ]),
        _: 1
      })
    ])
  ]))
}