
import { defineComponent } from 'vue';
import DropDown from '@/components/common/DropDown.vue';

export default defineComponent({
    components: { DropDown },
    data() {
        return {
            expandDropdown: false,
            activeSortingOrder: null as null | string,
            sortingOrders: ['Newest added', 'Oldest added', 'Newest updated', 'Oldest updated'],
        };
    },
    created() {
        this.activeSortingOrder = this.sortingOrders[0];
    },
    methods: {
        onSortingOrderClicked(sortingOrder: string) {
            this.activeSortingOrder = sortingOrder;
            this.expandDropdown = false;
        },
    },
});
