
import { defineComponent } from 'vue';
import { AgGridVue } from 'ag-grid-vue3';
import { ColDef, GridOptions } from 'ag-grid-community';
import GenericCellRenderer from '@/components/tables/GenericCellRenderer.vue';
import DateCellRenderer from '@/components/tables/DateCellRenderer.vue';
import IconCellRenderer from '@/components/tables/IconCellRenderer.vue';
import ActionsCellRenderer from '@/components/tables/ActionsCellRenderer.vue';

export default defineComponent({
    /* eslint-disable vue/no-unused-components */
    components: { AgGridVue, GenericCellRenderer, DateCellRenderer, IconCellRenderer, ActionsCellRenderer },
    props: {
        headers: {
            type: Array as () => ColDef[],
            default: [],
        },
        items: {
            type: Array as () => any[],
            default: [],
        },
        options: {
            type: Object,
            default: {},
        },
        width: {
            type: String,
            default: 'width: 100%',
        },
        clickableRows: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['item-clicked'],
    data() {
        return {
            gridOptions: {
                suppressCellFocus: true,
                headerHeight: 42,
                suppressRowHoverHighlight: true,
                rowHeight: 76,
                rowStyle: { 'max-height': '64px' },
                suppressRowClickSelection: !this.$props.clickableRows,
                ...this.$props.options,
            } as GridOptions,
        };
    },
    computed: {
        headersWithCellRenderers(): ColDef[] {
            return this.$props.headers.map((currentHeader) => {
                return { ...currentHeader, cellRenderer: currentHeader.cellRenderer || 'GenericCellRenderer' };
            });
        },
    },
    methods: {
        onGridReady() {
            this.gridOptions.api?.sizeColumnsToFit();
            this.gridOptions.api?.setDomLayout('autoHeight');
        },
        onSelectionChanged() {
            const selectedRows = this.gridOptions.api?.getSelectedRows();
            this.gridOptions.api?.deselectAll();
            if (selectedRows && selectedRows.length) {
                this.$emit('item-clicked', selectedRows[0]);
            }
        },
        onFirstDataRendered(params: GridOptions) {
            params.api?.sizeColumnsToFit();
        },
        onGridSizeChanged(params: GridOptions) {
            params.api?.sizeColumnsToFit();
        },
    },
});
