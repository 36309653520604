
import { defineComponent } from 'vue';
import * as sanitizeHtml from 'sanitize-html';
import Modal from '@/components/common/Modal.vue';
import { ItemHistory, ItemCreationHistory, ItemOwnershipHistory } from '@/types';

export default defineComponent({
    components: { Modal },
    props: {
        ownership: {
            type: Object as () => ItemHistory | null,
            default: null,
        },
    },
    computed: {
        infoPoints() {
            if (!this.ownership) return [];
            if (this.ownership.type === 'creation') {
                return [
                    { title: 'Time', content: (this as any).$format(new Date(this.ownership.creationDate), 'dd.MM.yyyy') },
                    { title: 'By', content: (this.ownership.data as ItemCreationHistory)?.qualityController },
                    { title: 'Batch', content: (this.ownership.data as ItemCreationHistory)?.qualityBatch },
                    { title: 'Location', content: (this.ownership.data as ItemCreationHistory)?.location },
                ];
            }
            return [
                { title: 'Time', content: (this as any).$format(new Date(this.ownership.creationDate), 'dd.MM.yyyy') },
                { title: 'From', content: (this.ownership.data as ItemOwnershipHistory).from.name },
                { title: 'To', content: (this.ownership.data as ItemOwnershipHistory).user?.name || (this.ownership.data as ItemOwnershipHistory).company?.name || 'Unknown' },
                { title: 'Order ID', content: (this.ownership.data as ItemOwnershipHistory).orderId },
            ].filter((currentInfoPoint) => currentInfoPoint.content);
        },
    },
    methods: {
        sanitizeHtml: sanitizeHtml as unknown as Function,
    },
});
