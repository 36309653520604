
import { defineComponent } from 'vue';
import Modal from '@/components/common/Modal.vue';

export default defineComponent({
    components: { Modal },
    data() {
        return {
            DOWNLOAD_APP_REFERENCE: process.env.VUE_APP_APP_DEEP_LINK,
        };
    },
});
