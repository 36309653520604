import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex space-x-4" }
const _hoisted_2 = {
  key: 0,
  class: "py-3 px-1.5 bg-green-300/[0.3] flex justify-center items-center"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "space-y-2 my-auto" }
const _hoisted_5 = { class: "text-left my-auto text-3xl whitespace-nowrap uppercase font-semibold" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "space-y-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemFilters = _resolveComponent("ItemFilters")!
  const _component_ItemsTable = _resolveComponent("ItemsTable")!
  const _component_AisInstantSearch = _resolveComponent("AisInstantSearch")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, { heading: "Items" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.headings)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("img", {
                class: "w-24",
                src: _ctx.headings.image
              }, null, 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.headings?.header ? _ctx.headings.header : 'Items'), 1),
          (_ctx.headings)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _ctx.headings.subheader
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.profile && _ctx.searchClient && _ctx.routing)
        ? (_openBlock(), _createBlock(_component_AisInstantSearch, {
            key: 0,
            routing: _ctx.routing,
            "index-name": `${_ctx.profile.keys.algolia.prefix}items`,
            "search-client": _ctx.searchClient
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_ItemFilters),
                _createVNode(_component_ItemsTable)
              ])
            ]),
            _: 1
          }, 8, ["routing", "index-name", "search-client"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}