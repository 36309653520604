import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Import = _resolveComponent("Import")!
  const _component_TagsCompanyTableVue = _resolveComponent("TagsCompanyTableVue")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, { heading: "TAGS" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Import, {
          type: _ctx.IMPORT_TYPE,
          onImportSuccess: _ctx.onImportSuccess
        }, null, 8, ["type", "onImportSuccess"]),
        _createVNode(_component_TagsCompanyTableVue)
      ])
    ]),
    _: 1
  }))
}