
import { computed, defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import useSubmissionState from '@/util/composables/useSubmissionState';
import { useCompaniesStore } from '@/plugins/store';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import CreatePersonalProfileModal from '@/views/auth/CreatePersonalProfileModal.vue';

export default defineComponent({
    components: { CustomInput, CustomCheckbox, CreatePersonalProfileModal },
    setup() {
        const [companiesStore, toast] = [useCompaniesStore(), useToast()];
        const [termsOfUseLink, privacyPolicyLink] = [ref(process.env.VUE_APP_TERMS_OF_USE_LINK), ref(process.env.VUE_APP_PRIVACY_POLICY_LINK)];
        const [
            companyName,
            companyRegistrationNumber,
            companyEmail,
            companyWebsite,
            companyCountry,
            companyFoundationYear,
            userEmail,
            userFirstName,
            userLastName,
            userPhone,
            acceptTermsAndConditions,
            disableForm,
        ] = [ref(''), ref(''), ref(''), ref(''), ref(''), ref(''), ref(''), ref(''), ref(''), ref(''), ref(false), ref(false)];

        /* eslint-disable no-control-regex */
        const emailRegex =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        const validForm = computed(() => {
            return (
                Boolean(
                    !disableForm.value &&
                        companyName.value &&
                        companyRegistrationNumber.value &&
                        companyEmail.value &&
                        companyWebsite.value &&
                        companyCountry.value &&
                        userEmail.value &&
                        userFirstName.value &&
                        userLastName.value &&
                        userPhone.value &&
                        acceptTermsAndConditions.value
                ) &&
                emailRegex.test(userEmail.value) &&
                Boolean(parseInt(companyFoundationYear.value as string))
            );
        });

        const createCompanySubmissionState = useSubmissionState(async () => {
            await companiesStore.createCompanyAndAdminUser({
                companyName: companyName.value,
                companyRegistrationNumber: companyRegistrationNumber.value,
                companyEmail: companyEmail.value,
                companyWebsite: companyWebsite.value,
                companyCountry: companyCountry.value,
                companyFoundationYear: parseInt(companyFoundationYear.value),
                userEmail: userEmail.value,
                userFirstName: userFirstName.value,
                userLastName: userLastName.value,
                userPhone: userPhone.value,
            });
            toast.success('Thank you for your registration. We will get back to you as soon as possible.', { timeout: 7000 });
            disableForm.value = true;
        });

        return {
            companyName,
            companyRegistrationNumber,
            companyEmail,
            companyWebsite,
            companyCountry,
            companyFoundationYear,
            userEmail,
            userFirstName,
            userLastName,
            userPhone,
            acceptTermsAndConditions,
            createCompanySubmissionState,
            validForm,
            termsOfUseLink,
            privacyPolicyLink,
        };
    },
});
