
import { defineComponent } from 'vue';
import Modal from '@/components/common/Modal.vue';
import { Traceability } from '@/types';

export default defineComponent({
    components: { Modal },
    props: {
        traceability: {
            type: Object as () => Traceability,
            required: true,
        },
    },
    computed: {
        infoPoints() {
            return [
                { title: 'Article number', content: this.traceability.articleNo },
                { title: 'Material weight', content: this.traceability.weight && this.traceability.weightUnit ? `${this.traceability.weight} ${this.traceability.weightUnit}` : null },
                { title: 'Supplier', content: null },
                { title: 'Material origin', content: this.traceability.materialOrigin || null },
                { title: 'Source', content: this.traceability.materialSource || null },
                { title: 'Supplier', content: this.traceability.supplier || null },
                { title: 'Recyclable', content: this.traceability.recyclable ? 'Yes' : 'No' },
                { title: 'Biodegradable', content: this.traceability.biodegradable ? 'Yes' : 'No' },
            ].filter((currentInfoPoint) => currentInfoPoint.content);
        },
    },
});
