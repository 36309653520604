
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        onButtonClicked() {
            alert('Not yet implemented');
        },
    },
});
