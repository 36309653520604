
import { defineComponent } from 'vue';
import * as sanitizeHtml from 'sanitize-html';
import Modal from '@/components/common/Modal.vue';

export default defineComponent({
    components: { Modal },
    props: {
        header: {
            type: String,
            default: '',
        },
        body: {
            type: String,
            default: '',
        },
        openFromTogglePosition: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['confirm', 'cancel'],
    methods: {
        onButtonClicked(confirm: boolean, close: CallableFunction) {
            this.$emit(confirm ? 'confirm' : 'cancel');
            close();
        },
        sanitizeHtml: sanitizeHtml as unknown as Function,
    },
});
