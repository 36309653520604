import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "collapse-expand-enter-active",
    "leave-active-class": "collapse-expand-leave-active",
    onBeforeEnter: _ctx.beforeEnter,
    onEnter: _ctx.enter,
    onAfterEnter: _ctx.afterEnter,
    onBeforeLeave: _ctx.beforeLeave,
    onLeave: _ctx.leave,
    onAfterLeave: _ctx.afterLeave
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["onBeforeEnter", "onEnter", "onAfterEnter", "onBeforeLeave", "onLeave", "onAfterLeave"]))
}