import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserForm = _resolveComponent("UserForm")!

  return (_openBlock(), _createBlock(_component_UserForm, {
    user: _ctx.activeCompanyUser,
    "confirmation-modal-header": "Edit user?",
    "confirmation-modal-body": "Are you sure you wish to edit this user?",
    "submit-button-text": "Edit user",
    "can-edit-role": _ctx.activeCompanyUser?.id !== _ctx.profile?.id,
    onSubmit: _ctx.onSubmit
  }, null, 8, ["user", "can-edit-role", "onSubmit"]))
}