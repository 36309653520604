
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
            show: false,
            DOWNLOAD_APP_REFERENCE: process.env.VUE_APP_APP_DEEP_LINK,
        };
    },
    created() {
        setTimeout(() => {
            this.show = Boolean(this.DOWNLOAD_APP_REFERENCE);
        }, 2000);
    },
});
