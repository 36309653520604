
import { defineComponent } from 'vue';
import Modal from '@/components/common/Modal.vue';
import { MaterialComponent } from '@/types';
import ComponentMaterialInfo from '@/components/common/componentModals/ComponentMaterialInfo.vue';
import ComponentTraceabilityModal from '@/components/common/componentModals/ComponentTraceabilityModal.vue';

export default defineComponent({
    name: 'ComponentMaterialModal',
    components: { Modal, ComponentMaterialInfo, ComponentTraceabilityModal },
    props: {
        component: {
            type: Object as () => MaterialComponent | null,
            default: null,
        },
        openFromTogglePosition: {
            type: Boolean,
            default: false,
        },
    },
});
