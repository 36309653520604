import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-12 flex flex-col pr-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductDocumentations = _resolveComponent("ProductDocumentations")!
  const _component_ProductMaterials = _resolveComponent("ProductMaterials")!
  const _component_ProductBillOfMaterials = _resolveComponent("ProductBillOfMaterials")!
  const _component_ProductLog = _resolveComponent("ProductLog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProductDocumentations, { product: _ctx.product }, null, 8, ["product"]),
    _createVNode(_component_ProductMaterials, { product: _ctx.product }, null, 8, ["product"]),
    _createVNode(_component_ProductBillOfMaterials, { product: _ctx.product }, null, 8, ["product"]),
    _createVNode(_component_ProductLog, { product: _ctx.product }, null, 8, ["product"])
  ]))
}