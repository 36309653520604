
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { ItemWithAssociations } from '@/types';
import ItemDataHistory from '@/views/dashboard/overview/item/ItemDataHistory.vue';
import ItemEventHistory from '@/views/dashboard/overview/item/ItemEventHistory.vue';
import { useMainStore } from '@/plugins/store';

export default defineComponent({
    components: { ItemDataHistory, ItemEventHistory },
    props: {
        item: {
            type: Object as () => ItemWithAssociations,
            required: true,
        },
    },
    computed: {
        ...mapState(useMainStore, ['profile', 'isSuperAdmin']),
        showDataHistory(): boolean {
            return this.item.product.companyId === this.profile?.companyId;
        },
    },
});
