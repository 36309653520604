import { defineStore } from 'pinia';
import { RouteRecordName } from 'vue-router';
import { SearchClient } from 'algoliasearch/lite';
import { ApiRepository } from '@/plugins/api/ApiRepository';
import { UserRole, CompanyType, Profile } from '@/types';

const useMainStore = defineStore('main', {
    state: () => ({
        breadcrumbs: [] as { text: string; route: string | { name: RouteRecordName | null | undefined; params?: any } }[],
        profile: null as null | Profile,
        searchClient: null as null | SearchClient,
    }),
    getters: {
        isCompanyAdmin(): boolean {
            return this.profile?.role === UserRole.CompanyAdmin || false;
        },
        isSuperAdmin(): boolean {
            return this.profile?.role === UserRole.SuperAdmin || false;
        },
        companyIsBrandOwner(): boolean {
            return this.profile?.company?.type === CompanyType.BrandOwner || false;
        },
        companyIsBrandOwnerOrUserIsSuperAdmin(): boolean {
            return this.companyIsBrandOwner || this.isSuperAdmin;
        },
    },
    actions: {
        async getProfile(bustCache = false): Promise<Profile> {
            const profile: Profile = await ApiRepository.GetProfile({ cacheOptions: { store: this, key: 'profile', bustCache } });
            this.searchClient = await ApiRepository.GetAlgoliaClient({ payload: { id: profile.keys.algolia.id, key: profile.keys.algolia.key } });
            return profile;
        },
    },
});

export { useMainStore };
