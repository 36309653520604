
import { defineComponent } from 'vue';
import { mapWritableState } from 'pinia';
import { useOverviewStore } from '@/plugins/store';
import AlgoliaSearchInput from '@/components/common/algolia/AlgoliaSearchInput.vue';
import AlgoliaChosenFilters from '@/components/common/algolia/AlgoliaChosenFilters.vue';
import AlgoliaFilterPicker from '@/components/common/algolia/AlgoliaFilterPicker.vue';
import { FacetType, ItemFacet } from '@/types';

export default defineComponent({
    components: { AlgoliaSearchInput, AlgoliaChosenFilters, AlgoliaFilterPicker },
    data() {
        return {
            facetTexts: {
                [ItemFacet.ProductCategoryName]: 'Category',
                [ItemFacet.ProductName]: 'Name',
                [ItemFacet.ProductNumber]: 'Number',
                [ItemFacet.ProductPrimaryMaterial]: 'Primary material',
                [ItemFacet.ProductPrimaryMaterialVariant]: 'Primary material variant',
                [ItemFacet.ProductSecondaryMaterial]: 'Secondary material',
                [ItemFacet.ProductSecondaryMaterialVariant]: 'Secondary material variant',
                [ItemFacet.Product]: 'Model',
                [ItemFacet.ProductDescription]: 'Description',
                [ItemFacet.ProductDesignerName]: 'Designer name',
                [ItemFacet.ProductDesignYear]: 'Design year',
                [ItemFacet.ProductDesignUrl]: 'Design url',
                [ItemFacet.ProductGTIN]: 'GTIN',
                [ItemFacet.ProductCommodityCode]: 'Commodity code',
                [ItemFacet.ProductCo2Date]: 'Co2 date',
                [ItemFacet.ProductCo2Total]: 'Co2-e',
                [ItemFacet.ProductDegassingClass]: 'Degassing class',
                [ItemFacet.ProductFormaldehyde]: 'Formaldehyde',
                [ItemFacet.ProductTVOC]: 'TVOC',
                [ItemFacet.ProductTVOCNumber]: 'TVOC Number',
                [ItemFacet.ProductDurability]: 'Durability',
                [ItemFacet.ProductWaste]: 'Waste',
                [ItemFacet.ProductWasteDescription]: 'Waste description',

                [ItemFacet.ProductReducable]: 'Reducable',
                [ItemFacet.ProductReusable]: 'Reusable',
                [ItemFacet.ProductRecyclable]: 'Recyclable',
                [ItemFacet.ProductRecoverable]: 'Recoverable',
                [ItemFacet.ProductWeight]: 'Weight',
                [ItemFacet.ProductWeightPacking]: 'Weight packing',
                [ItemFacet.ProductDimensionsHeight]: 'Dimensions height',
                [ItemFacet.ProductDimensionsWidth]: 'Dimensions width',
                [ItemFacet.ProductDimensionsDepth]: 'Dimensions depth',
                [ItemFacet.ProductDimensionsDiameter]: 'Dimensions diameter',
                [ItemFacet.ProductDimensionsUrl]: 'Dimensions url',

                [ItemFacet.ProductDocumentationName]: 'Name',
                [ItemFacet.ProductDocumentationType]: 'Type',
                [ItemFacet.ProductDocumentationFieldType]: 'Field type',

                [ItemFacet.OwnerName]: 'Owner',

                [ItemFacet.FacilityId]: 'Facility id',
                [ItemFacet.OrderNumber]: 'Order number',
                [ItemFacet.ArticleNumber]: 'Article number',
                [ItemFacet.SignOffId]: 'Sign off id',
                [ItemFacet.AssemblyBatchNumber]: 'Assembly batch number',
            },
            hideFacetTypeIndex: -1,
        };
    },
    computed: {
        facetTypes(): FacetType[] {
            return [
                {
                    text: 'Product data',
                    facets: [
                        { text: this.facetTexts[ItemFacet.ProductName], name: ItemFacet.ProductName, searchPlaceholder: 'Type name' },
                        { text: this.facetTexts[ItemFacet.ProductNumber], name: ItemFacet.ProductNumber, searchPlaceholder: 'Type number' },
                        { text: this.facetTexts[ItemFacet.Product], name: ItemFacet.Product, searchPlaceholder: 'Type model', missingPropertyName: ItemFacet.Product },
                        {
                            text: this.facetTexts[ItemFacet.ProductDescription],
                            name: ItemFacet.ProductDescription,
                            searchPlaceholder: 'Type description',
                            missingPropertyName: ItemFacet.ProductDescription,
                        },
                        { text: this.facetTexts[ItemFacet.ProductCategoryName], name: ItemFacet.ProductCategoryName, searchPlaceholder: 'Type category name' },
                        { text: this.facetTexts[ItemFacet.ProductPrimaryMaterial], name: ItemFacet.ProductPrimaryMaterial, searchPlaceholder: 'Type primary material' },
                        { text: this.facetTexts[ItemFacet.ProductPrimaryMaterialVariant], name: ItemFacet.ProductPrimaryMaterialVariant, searchPlaceholder: 'Type primary material variant' },
                        {
                            text: this.facetTexts[ItemFacet.ProductSecondaryMaterial],
                            name: ItemFacet.ProductSecondaryMaterial,
                            searchPlaceholder: 'Type secondary material',
                            missingPropertyName: ItemFacet.ProductSecondaryMaterial,
                        },
                        {
                            text: this.facetTexts[ItemFacet.ProductSecondaryMaterialVariant],
                            name: ItemFacet.ProductSecondaryMaterialVariant,
                            searchPlaceholder: 'Type secondary material variant',
                            missingPropertyName: ItemFacet.ProductSecondaryMaterialVariant,
                        },
                        {
                            text: this.facetTexts[ItemFacet.ProductDesignerName],
                            name: ItemFacet.ProductDesignerName,
                            searchPlaceholder: 'Type designer name',
                            missingPropertyName: ItemFacet.ProductDesignerName,
                        },
                        {
                            text: this.facetTexts[ItemFacet.ProductDesignYear],
                            name: ItemFacet.ProductDesignYear,
                            searchPlaceholder: 'Type design year',
                            missingPropertyName: ItemFacet.ProductDesignYear,
                        },
                        { text: this.facetTexts[ItemFacet.ProductDesignUrl], name: ItemFacet.ProductDesignUrl, searchPlaceholder: 'Type design url', missingPropertyName: ItemFacet.ProductDesignUrl },
                        { text: this.facetTexts[ItemFacet.ProductGTIN], name: ItemFacet.ProductGTIN, searchPlaceholder: 'Type GTIN', missingPropertyName: ItemFacet.ProductGTIN },
                        {
                            text: this.facetTexts[ItemFacet.ProductCommodityCode],
                            name: ItemFacet.ProductCommodityCode,
                            searchPlaceholder: 'Type commodity code',
                            missingPropertyName: ItemFacet.ProductCommodityCode,
                        },
                        { text: this.facetTexts[ItemFacet.ProductCo2Date], name: ItemFacet.ProductCo2Date, searchPlaceholder: 'Type Co2 date', missingPropertyName: ItemFacet.ProductCo2Date },
                        { text: this.facetTexts[ItemFacet.ProductCo2Total], name: ItemFacet.ProductCo2Total, searchPlaceholder: 'Type Co2-e', missingPropertyName: ItemFacet.ProductCo2Total },
                        {
                            text: this.facetTexts[ItemFacet.ProductDegassingClass],
                            name: ItemFacet.ProductDegassingClass,
                            searchPlaceholder: 'Type degassing class',
                            missingPropertyName: ItemFacet.ProductDegassingClass,
                        },
                        {
                            text: this.facetTexts[ItemFacet.ProductFormaldehyde],
                            name: ItemFacet.ProductFormaldehyde,
                            searchPlaceholder: 'Type formaldehyde',
                            missingPropertyName: ItemFacet.ProductFormaldehyde,
                        },
                        { text: this.facetTexts[ItemFacet.ProductTVOC], name: ItemFacet.ProductTVOC, searchPlaceholder: 'Type TVOC', missingPropertyName: ItemFacet.ProductTVOC },
                        { text: this.facetTexts[ItemFacet.ProductTVOCNumber], name: ItemFacet.ProductTVOCNumber, searchPlaceholder: 'Type TVOC number', missingPropertyName: ItemFacet.ProductTVOCNumber },
                        { text: this.facetTexts[ItemFacet.ProductDurability], name: ItemFacet.ProductDurability, searchPlaceholder: 'Type durability', missingPropertyName: ItemFacet.ProductDurability },
                        { text: this.facetTexts[ItemFacet.ProductWaste], name: ItemFacet.ProductWaste, searchPlaceholder: 'Type waste', missingPropertyName: ItemFacet.ProductWaste },
                        {
                            text: this.facetTexts[ItemFacet.ProductWasteDescription],
                            name: ItemFacet.ProductWasteDescription,
                            searchPlaceholder: 'Type waste description',
                            missingPropertyName: ItemFacet.ProductWasteDescription,
                        },
                        { text: this.facetTexts[ItemFacet.ProductReducable], name: ItemFacet.ProductReducable, searchPlaceholder: 'Type reducable', missingPropertyName: ItemFacet.ProductReducable },
                        { text: this.facetTexts[ItemFacet.ProductReusable], name: ItemFacet.ProductReusable, searchPlaceholder: 'Type reusable', missingPropertyName: ItemFacet.ProductReusable },
                        { text: this.facetTexts[ItemFacet.ProductRecyclable], name: ItemFacet.ProductRecyclable, searchPlaceholder: 'Type recyclable', missingPropertyName: ItemFacet.ProductRecyclable },
                        { text: this.facetTexts[ItemFacet.ProductRecoverable], name: ItemFacet.ProductRecoverable, searchPlaceholder: 'Type recoverable', missingPropertyName: ItemFacet.ProductRecoverable },
                        { text: this.facetTexts[ItemFacet.ProductWeight], name: ItemFacet.ProductWeight, searchPlaceholder: 'Type weight', missingPropertyName: ItemFacet.ProductWeight },
                        {
                            text: this.facetTexts[ItemFacet.ProductWeightPacking],
                            name: ItemFacet.ProductWeightPacking,
                            searchPlaceholder: 'Type weight packing',
                            missingPropertyName: ItemFacet.ProductWeightPacking,
                        },
                        {
                            text: this.facetTexts[ItemFacet.ProductDimensionsHeight],
                            name: ItemFacet.ProductDimensionsHeight,
                            searchPlaceholder: 'Type dimensions height',
                            missingPropertyName: ItemFacet.ProductDimensionsHeight,
                        },
                        {
                            text: this.facetTexts[ItemFacet.ProductDimensionsWidth],
                            name: ItemFacet.ProductDimensionsWidth,
                            searchPlaceholder: 'Type dimensions width',
                            missingPropertyName: ItemFacet.ProductDimensionsWidth,
                        },
                        {
                            text: this.facetTexts[ItemFacet.ProductDimensionsDepth],
                            name: ItemFacet.ProductDimensionsDepth,
                            searchPlaceholder: 'Type dimensions depth',
                            missingPropertyName: ItemFacet.ProductDimensionsDepth,
                        },
                        {
                            text: this.facetTexts[ItemFacet.ProductDimensionsDiameter],
                            name: ItemFacet.ProductDimensionsDiameter,
                            searchPlaceholder: 'Type dimensions diameter',
                            missingPropertyName: ItemFacet.ProductDimensionsDiameter,
                        },
                        {
                            text: this.facetTexts[ItemFacet.ProductDimensionsUrl],
                            name: ItemFacet.ProductDimensionsUrl,
                            searchPlaceholder: 'Type dimensions url',
                            missingPropertyName: ItemFacet.ProductDimensionsUrl,
                        },
                    ],
                },
                {
                    text: 'Item data',
                    facets: [
                        { text: this.facetTexts[ItemFacet.FacilityId], name: ItemFacet.FacilityId, searchPlaceholder: 'Type facility id' },
                        { text: this.facetTexts[ItemFacet.OrderNumber], name: ItemFacet.OrderNumber, searchPlaceholder: 'Type order number' },
                        { text: this.facetTexts[ItemFacet.ArticleNumber], name: ItemFacet.ArticleNumber, searchPlaceholder: 'Type article number' },
                        { text: this.facetTexts[ItemFacet.SignOffId], name: ItemFacet.SignOffId, searchPlaceholder: 'Type sign off id', missingPropertyName: ItemFacet.SignOffId },
                        { text: this.facetTexts[ItemFacet.AssemblyBatchNumber], name: ItemFacet.AssemblyBatchNumber, searchPlaceholder: 'Type assembly batch number' },
                    ],
                },
                {
                    text: 'Product documentation',
                    facets: [
                        { text: this.facetTexts[ItemFacet.ProductDocumentationName], name: ItemFacet.ProductDocumentationName, searchPlaceholder: 'Type name' },
                        { text: this.facetTexts[ItemFacet.ProductDocumentationType], name: ItemFacet.ProductDocumentationType, searchPlaceholder: 'Type documentation type' },
                        { text: this.facetTexts[ItemFacet.ProductDocumentationFieldType], name: ItemFacet.ProductDocumentationFieldType, searchPlaceholder: 'Type field type' },
                    ],
                },
                {
                    text: 'Ownership',
                    facets: [{ text: this.facetTexts[ItemFacet.OwnerName], name: ItemFacet.OwnerName, searchPlaceholder: 'Type owner name' }],
                },
            ];
        },
        ...mapWritableState(useOverviewStore, ['activeProduct']),
        initialFilters(): { name: ItemFacet; value: string }[] {
            if (this.activeProduct) {
                return [{ name: ItemFacet.ProductNumber, value: this.activeProduct.number }];
            }
            return [];
        },
    },
    created() {
        if (this.activeProduct) {
            this.hideFacetTypeIndex = 0;
        }
    },
    methods: {
        onFilterDeactivated(filter: any) {
            if (filter.attribute === ItemFacet.ProductNumber) {
                this.activeProduct = null;
                this.hideFacetTypeIndex = -1;
            }
        },
    },
});
