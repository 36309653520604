
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useMainStore } from '@/plugins/store';
import Tabs from '@/components/common/Tabs.vue';
import CompanyProfileForm from '@/views/dashboard/settings/CompanyProfileForm.vue';
import CompanyUsersTable from '@/views/dashboard/settings/CompanyUsersTable.vue';
import PersonalProfileForm from '@/views/dashboard/settings/PersonalProfileForm.vue';

export default defineComponent({
    components: { Tabs, CompanyProfileForm, CompanyUsersTable, PersonalProfileForm },
    computed: {
        ...mapState(useMainStore, ['profile', 'isCompanyAdmin']),
        tabs() {
            return [
                { id: 'company', name: 'Company' },
                { id: 'users', name: 'Users' },
                { id: 'profile', name: 'Profile' },
            ];
        },
    },
});
