
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            DOWNLOAD_APP_REFERENCE: process.env.VUE_APP_APP_DEEP_LINK,
            passedTimeThreshold: false,
            passedScrollThreshold: false,
            manuallyClosed: false,
            downloadAppPerks: ['Images and documentation', 'Request ownership', 'See history and manage items'],
        };
    },
    computed: {
        show() {
            return this.passedTimeThreshold && this.passedScrollThreshold && !this.manuallyClosed;
        },
    },
    watch: {
        show(newValue: boolean) {
            if (newValue) {
                document.querySelector('html')!.style.overflow = 'hidden';
            } else {
                document.querySelector('html')!.style.removeProperty('overflow');
            }
        },
    },
    created() {
        setTimeout(() => {
            this.passedTimeThreshold = true;
        }, 5000);
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const [scrollY, windowHeight, documentHeight] = [window.scrollY, window.innerHeight, document.body.offsetHeight];
            if (scrollY > (documentHeight - windowHeight) / 2) {
                this.passedScrollThreshold = true;
            }
        },
        /* Called by parent component */
        forceShow() {
            this.passedTimeThreshold = true;
            this.passedScrollThreshold = true;
            this.manuallyClosed = false;
        },
    },
});
