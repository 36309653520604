export enum DocumentationFieldType {
    'Guide' = 'guide',
    'Label' = 'label',
    'Description' = 'description',
    'Ownership' = 'ownership',
    'Co2Documentation' = 'co2 documentation',
    'MaterialDocumentation' = 'material documentation',
    'DegassingDocumentation' = 'degassing documentation',
    'Circularity' = 'circularity',
    'DesignLink' = 'design link',
}