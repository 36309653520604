
import { defineComponent } from 'vue';
import * as sanitizeHtml from 'sanitize-html';
import { MaterialComponent } from '@/types';

export default defineComponent({
    props: {
        component: {
            type: Object as () => MaterialComponent,
            required: true,
        },
    },
    computed: {
        infoPoints() {
            return [
                { title: 'Material', content: this.component.category?.name || 'N/A' },
                { title: 'Article number', content: this.component.articleNo || 'N/A' },
                { title: 'Article name', content: this.component.articleName || 'N/A' },
                { title: 'Material weight', content: this.component.weight && this.component.weightUnit ? `${this.component.weight} ${this.component.weightUnit}` : 'N/A' },
                { title: 'Recycle/upcycle value', content: this.component.recycle && this.component.recycleUnit ? `${this.component.recycle} ${this.component.recycleUnit}` : 'N/A' },
                { title: 'Co2-e', content: this.component.co2 && this.component.co2Unit ? `${this.component.co2} ${this.component.co2Unit}` : 'N/A' },
                { title: 'Material origin', content: this.component.origin },
                { title: 'Source', content: this.component.source },
                { title: 'Recyclable', content: this.component.recyclable ? 'Yes' : 'No' },
                { title: 'Biodegradable', content: this.component.biodegradable ? 'Yes' : 'No' },
                { title: 'Supplier', content: this.component.supplier || 'N/A' },
                { title: 'Description', content: this.component.description || 'N/A' },
            ].filter((currentInfoPoint) => currentInfoPoint.content);
        },
    },
    methods: {
        sanitizeHtml: sanitizeHtml as unknown as Function,
    },
});
