
import { defineComponent } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboard/content/DashboardContentLayout.vue';
import Import from '@/views/dashboard/import/data/Import.vue';
import ImportLog from '@/views/dashboard/import/data/ImportLog.vue';
import Templates from '@/views/dashboard/import/data/Templates.vue';
import { ExcelImportType } from '@/types';

export default defineComponent({
    components: { DashboardContentLayout, Import, ImportLog, Templates },
    data() {
        return {
            IMPORT_TYPE: ExcelImportType.Default,
        };
    },
});
