import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-white font-semibold flex bg-green-600 hover:bg-green-600/95 rounded-lg px-3 py-4 space-x-1.5" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.icon,
            class: "h-4 aspect-square my-auto"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}