import { ref, computed } from 'vue';

/*
 * Used by forms, buttons etc
 * Prevents submission while a previous submission is still ongoing.
 * Also prevents multiple submissions within 1 second
 * Returns a pending attribute to that buttons can listen to if they want to indicate they're disabled
 */
export default function useSubmissionState(callback: CallableFunction) {
    const minTimePending = ref(false);
    const callbackPending = ref(false);
    const pending = computed(() => minTimePending.value || callbackPending.value);

    const onSubmit = async (validSubmission = true) => {
        if (!validSubmission) {
            return;
        }
        if (callbackPending.value === false) {
            callbackPending.value = true;
            minTimePending.value = true;
            setTimeout(() => {
                minTimePending.value = false;
            }, 1000);
            try {
                const result = await new Promise((resolve) => {
                    const callbackResult = callback();
                    resolve(callbackResult);
                });
                callbackPending.value = false;
                return result;
            } catch (error) {
                callbackPending.value = false;
                throw error;
            }
        }
    };

    return { pending, onSubmit };
}
