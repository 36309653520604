import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onSubmit"]
const _hoisted_2 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomInput = _resolveComponent("CustomInput")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ConfirmationModal = _resolveComponent("ConfirmationModal")!

  return (_openBlock(), _createBlock(_component_ConfirmationModal, {
    header: "Edit company?",
    body: "Are you sure you wish to edit your company?",
    "open-from-toggle-position": false,
    onConfirm: _ctx.onSubmit
  }, {
    toggle: _withCtx(({ open }) => [
      _createElementVNode("form", {
        class: "space-y-6 max-w-150 flex flex-col",
        onSubmit: _withModifiers(open, ["prevent"])
      }, [
        _createVNode(_component_CustomInput, {
          value: _ctx.form.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
          label: "Name",
          "show-required-indication": ""
        }, null, 8, ["value"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.form.website,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.website) = $event)),
          label: "Website url",
          "show-required-indication": ""
        }, null, 8, ["value"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.form.email,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.email) = $event)),
          label: "Email",
          "show-required-indication": ""
        }, null, 8, ["value"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.form.country,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.country) = $event)),
          label: "Country"
        }, null, 8, ["value"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.form.foundationYear,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.foundationYear) = $event)),
          type: "number",
          label: "Year of foundation"
        }, null, 8, ["value"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.form.logo,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.logo) = $event)),
          label: "Logo"
        }, null, 8, ["value"]),
        _createVNode(_component_CustomInput, {
          value: _ctx.form.registration,
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.registration) = $event)),
          disabled: "",
          label: "Registration number"
        }, null, 8, ["value"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CustomButton, {
            icon: "/assets/icons/ic_edit.svg",
            text: "Update company"
          })
        ])
      ], 40, _hoisted_1)
    ]),
    _: 1
  }, 8, ["onConfirm"]))
}