
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useToast } from 'vue-toastification';
import { useMainStore, useUserStore } from '@/plugins/store';
import { UserForm as UserFormType } from '@/types';
import UserForm from '@/views/dashboard/settings/UserForm.vue';

export default defineComponent({
    components: { UserForm },
    data() {
        return {
            toast: useToast(),
        };
    },
    computed: {
        ...mapState(useMainStore, ['profile']),
    },
    methods: {
        ...mapActions(useUserStore, ['editUser']),
        ...mapActions(useMainStore, ['getProfile']),
        async onSubmit(form: UserFormType) {
            if (!this.profile) return;
            await this.editUser(this.profile.id, form.firstName, form.lastName, form.phone);
            this.toast.success('Profile updated');
            await this.getProfile(true);
        },
    },
});
