import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full pb-24" }
const _hoisted_2 = { class: "space-y-4 flex items-center flex-col" }
const _hoisted_3 = {
  key: 1,
  class: "px-4 space-y-2 w-full max-w-dashboard"
}
const _hoisted_4 = { class: "text-left text-3xl font-bold uppercase" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "flex space-x-1.5 bg-green-300/75 rounded-lg py-1 px-2 items-center mr-auto" }
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 3,
  class: "px-4 w-full max-w-dashboard"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PublicItemImageGrid = _resolveComponent("PublicItemImageGrid")!
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_ItemPerformance = _resolveComponent("ItemPerformance")!
  const _component_DownloadAppSnackbarPrompt = _resolveComponent("DownloadAppSnackbarPrompt")!
  const _component_DownloadAppFullscreenPrompt = _resolveComponent("DownloadAppFullscreenPrompt")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.item)
        ? (_openBlock(), _createBlock(_component_PublicItemImageGrid, {
            key: 0,
            class: "w-full max-w-dashboard",
            item: _ctx.item
          }, null, 8, ["item"]))
        : _createCommentVNode("", true),
      (_ctx.item)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.item.product.name), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", null, [
                  _createElementVNode("img", {
                    class: "h-4.5",
                    src: _ctx.item.claimable ? '/assets/icons/ic_claimable.svg' : '/assets/icons/ic_not_claimable.svg'
                  }, null, 8, _hoisted_7)
                ]),
                _createElementVNode("span", null, _toDisplayString(_ctx.item.claimable ? 'Claimable' : 'Not claimable'), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.video)
        ? (_openBlock(), _createBlock(_component_VideoPlayer, {
            key: 2,
            source: _ctx.video,
            class: "w-full px-4 lg:max-w-[90vw] xl:max-w-[70vw]"
          }, null, 8, ["source"]))
        : _createCommentVNode("", true),
      (_ctx.item)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_ItemPerformance, {
              filter: _ctx.filterPerformanceCards,
              "sort-order": _ctx.performanceCardSorting,
              "is-public": "",
              item: _ctx.item,
              onExpandCardClicked: _ctx.onExpandCardClicked
            }, null, 8, ["filter", "sort-order", "item", "onExpandCardClicked"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_DownloadAppSnackbarPrompt)
    ]),
    (_ctx.item)
      ? (_openBlock(), _createBlock(_component_DownloadAppFullscreenPrompt, {
          key: 0,
          ref: "full-screen-prompt",
          item: _ctx.item
        }, null, 8, ["item"]))
      : _createCommentVNode("", true)
  ]))
}