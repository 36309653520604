import { onUnmounted } from 'vue';

export default function useEscapable(onEscape: CallableFunction) {
    const escapeHandler = (e: { key: string }) => {
        if (e.key === 'Escape') {
            onEscape();
        }
    };

    document.addEventListener('keydown', escapeHandler);

    onUnmounted(() => {
        document.removeEventListener('keydown', escapeHandler);
    });

    return { onUnmounted };
}
