import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PreloadSpinner = _resolveComponent("PreloadSpinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PublicLayout = _resolveComponent("PublicLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PreloadSpinner, { show: _ctx.showPreloadSpinner }, null, 8, ["show"]),
    _createVNode(_component_PublicLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ]))
}