import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyUserForm = _resolveComponent("CompanyUserForm")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    heading: _ctx.activeCompanyUser ? `${_ctx.activeCompanyUser.firstName} ${_ctx.activeCompanyUser.lastName}` : 'User'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_CompanyUserForm, { "active-company-user": _ctx.activeCompanyUser }, null, 8, ["active-company-user"])
    ]),
    _: 1
  }, 8, ["heading"]))
}