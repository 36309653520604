
import { defineComponent } from 'vue';
import { ColDef } from 'ag-grid-community';
import { MaterialComponent, ProductWithReferences } from '@/types';
import GenericTable from '@/components/tables/GenericTable.vue';
import ComponentMaterialModal from '@/components/common/componentModals/ComponentMaterialModal.vue';

export default defineComponent({
    components: { GenericTable, ComponentMaterialModal },
    props: {
        product: {
            type: Object as () => ProductWithReferences,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                { headerName: 'BoM ID', field: 'bomId', sortable: true, minWidth: 230 },
                { headerName: 'Name', field: 'name', sortable: true },
                { headerName: 'Article No.', field: 'articleNo', sortable: true },
                { headerName: 'Latest update', field: 'updatedOn', sortable: true },
                { headerName: '', field: 'action', sortable: false, width: 20, cellRenderer: 'ActionsCellRenderer' },
            ] as ColDef[],
            activeComponent: null as null | MaterialComponent,
        };
    },
    computed: {
        items() {
            return (
                this.product.components?.map((currentComponent) => {
                    return {
                        bomId: currentComponent.id,
                        name: currentComponent.category?.name || 'n/a',
                        articleNo: currentComponent.articleNo,
                        updatedOn: (this as any).$format(new Date(currentComponent.updatedOn), 'dd.MM.yyyy'),
                        value: currentComponent,
                    };
                }) || []
            );
        },
    },
    methods: {
        onItemClicked(component: { [key: string]: any; value: MaterialComponent }, open: CallableFunction) {
            this.activeComponent = component.value;
            open();
        },
    },
});
