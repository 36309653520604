import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgGridVue = _resolveComponent("AgGridVue")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ 'clickable-rows': _ctx.clickableRows }, "generic-table overflow-x-auto relative max-w-full grid overflow-hidden rounded-md"]),
    style: _normalizeStyle(_ctx.width)
  }, [
    _createVNode(_component_AgGridVue, {
      class: "ag-theme-material",
      "animate-rows": true,
      "row-selection": "single",
      "grid-options": _ctx.gridOptions,
      "column-defs": _ctx.headersWithCellRenderers,
      "row-data": _ctx.items,
      "default-col-def": {
                wrapText: true,
            },
      onSelectionChanged: _ctx.onSelectionChanged,
      onGridReady: _ctx.onGridReady,
      onGridSizeChanged: _ctx.onGridSizeChanged,
      onFirstDataRendered: _ctx.onFirstDataRendered
    }, null, 8, ["grid-options", "column-defs", "row-data", "onSelectionChanged", "onGridReady", "onGridSizeChanged", "onFirstDataRendered"])
  ], 6))
}