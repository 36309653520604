
import { defineComponent } from 'vue';
import DashboardContentHeader from '@/components/layouts/dashboard/content/DashboardContentHeader.vue';

export default defineComponent({
    components: { DashboardContentHeader },
    props: {
        heading: {
            type: String,
            default: '',
        },
        subheading: {
            type: String,
            default: '',
        },
        contentWrapperClasses: {
            type: String,
            default: 'pt-8',
        },
        hasBottomBorder: {
            type: Boolean,
            default: true,
        },
    },
});
