import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex space-x-2" }
const _hoisted_2 = {
  key: 0,
  class: "h-3.5 my-auto opacity-30",
  src: "/assets/icons/ic_chevron_right.svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (breadcrumb, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: breadcrumb.text,
        class: "flex space-x-2"
      }, [
        _createVNode(_component_router_link, {
          class: _normalizeClass({ 'opacity-30 max-w-36 truncate': index !== _ctx.breadcrumbs.length - 1, 'line-clamp-1': index === _ctx.breadcrumbs.length - 1 }),
          to: breadcrumb.route
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(breadcrumb.text), 1)
          ]),
          _: 2
        }, 1032, ["class", "to"]),
        (index !== _ctx.breadcrumbs.length - 1)
          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}