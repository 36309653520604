
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        facetTexts: {
            type: Object as () => { [key: string]: string },
            required: true,
        },
    },
    emits: ['filter-deactivated'],
    methods: {
        getFilterName(filterLabel: string, refinementLabel: string) {
            if (filterLabel === '_missingProperties') {
                return `${this.facetTexts[refinementLabel] || 'Unknown Filter'}:`;
            }
            return `${this.facetTexts[filterLabel] || 'Unknown Filter'}:`;
        },
        getFilterValue(filterLabel: string, refinementLabel: string) {
            if (filterLabel === '_missingProperties') {
                return 'Not applicable (N/A)';
            }
            return refinementLabel;
        },
    },
});
