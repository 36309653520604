import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsTabs = _resolveComponent("SettingsTabs")!
  const _component_DashboardContentLayout = _resolveComponent("DashboardContentLayout")!

  return (_openBlock(), _createBlock(_component_DashboardContentLayout, {
    "has-bottom-border": false,
    "content-wrapper-classes": null,
    heading: "Settings"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_SettingsTabs)
    ]),
    _: 1
  }))
}