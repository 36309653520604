
import { defineComponent } from 'vue';
import * as sanitizeHtml from 'sanitize-html';

export default defineComponent({
    props: {
        heading: {
            type: String,
            default: '',
        },
        subheading: {
            type: String,
            default: '',
        },
    },
    methods: {
        sanitizeHtml: sanitizeHtml as unknown as Function,
    },
});
