
import { mapActions, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import * as sanitizeHtml from 'sanitize-html';
import { useMainStore, useOverviewStore } from '@/plugins/store';
import { ItemWithAssociations } from '@/types';
import DashboardContentLayout from '@/components/layouts/dashboard/content/DashboardContentLayout.vue';
import ItemPerformance from '@/components/common/ItemPerformance.vue';
import ItemHistory from '@/views/dashboard/overview/item/ItemHistory.vue';
import ItemDocumentations from '@/views/dashboard/overview/item/ItemDocumentations.vue';
import Tabs from '@/components/common/Tabs.vue';
import getOwnerName from '@/util/helpers/getOwnerName';

export default defineComponent({
    components: { DashboardContentLayout, ItemPerformance, Tabs, ItemHistory, ItemDocumentations },
    data() {
        return {
            item: null as null | ItemWithAssociations,
            tabs: [
                { id: 'item-performance', name: 'Item performance' },
                { id: 'item-documentations', name: 'Docs & guides' },
                { id: 'item-history', name: 'Item history' },
            ],
        };
    },
    computed: {
        ...mapWritableState(useMainStore, ['breadcrumbs']),
        headings() {
            if (this.item) {
                let subheader = '';
                subheader += this.item.product.model || '';
                if (this.item.product.model && this.item.product.description) subheader += ` <span class="opacity-20 font-bold">•</span> `;
                subheader += this.item.product.description || '';
                return {
                    header: `Item ${this.item.tagId}`,
                    subheader,
                };
            }
            return { header: 'Finding item...', subheader: '' };
        },
    },
    watch: {
        item: {
            immediate: true,
            handler(newValue: ItemWithAssociations | null) {
                if (newValue) {
                    let productBreadcumpPath = '';
                    if (newValue.product.model) productBreadcumpPath += `, `;
                    productBreadcumpPath += newValue.product.model || '';
                    if (newValue.product.description) productBreadcumpPath += `, `;
                    productBreadcumpPath += newValue.product.description || '';
                    this.breadcrumbs = [
                        { text: 'Product models', route: { name: 'Products' } },
                        {
                            text: `${newValue.product.name}${productBreadcumpPath}`,
                            route: { name: 'Product', params: { id: newValue.product.id } },
                        },
                        {
                            text: 'Items',
                            route: { name: 'Items' },
                        },
                        {
                            text: `Item ${newValue.tagId}`,
                            route: { name: 'Item', params: { id: newValue.id } },
                        },
                    ];
                    document.title = `Micollect - ${newValue.tagId}`;
                }
            },
        },
        $route: {
            immediate: true,
            handler() {
                if (this.$route.params.id && this.$route.params.id !== this.item?.id) {
                    this.getItem(this.$route.params.id as string).then((item) => {
                        this.item = item;
                    });
                }
            },
        },
    },
    methods: {
        ...mapActions(useOverviewStore, ['getItem']),
        sanitizeHtml: sanitizeHtml as unknown as Function,
        getOwnerName,
    },
});
