
import { defineComponent } from 'vue';

export default defineComponent({
    methods: {
        beforeEnter(element: any) {
            requestAnimationFrame(() => {
                if (!element.style.height) {
                    element.style.height = '0px';
                }

                element.style.display = null;
            });
        },
        enter(element: any) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    element.style.height = `${element.scrollHeight}px`;
                });
            });
        },
        afterEnter(element: any) {
            element.style.height = null;
        },
        beforeLeave(element: any) {
            requestAnimationFrame(() => {
                if (!element.style.height) {
                    element.style.height = `${element.offsetHeight}px`;
                }
            });
        },
        leave(element: any) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    element.style.height = '0px';
                });
            });
        },
        afterLeave(element: any) {
            element.style.height = null;
        },
    },
});
