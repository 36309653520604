import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "mx-auto max-w-dashboard" }
const _hoisted_3 = { class: "flex justify-between items-center pt-8 pl-10" }
const _hoisted_4 = { class: "ml-auto flex" }
const _hoisted_5 = { class: "px-4" }
const _hoisted_6 = { class: "px-4" }
const _hoisted_7 = {
  key: 0,
  class: "pl-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_TagSearch = _resolveComponent("TagSearch")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_Import = _resolveComponent("Import")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Breadcrumbs),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_TagSearch)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_Settings)
            ]),
            (_ctx.companyIsBrandOwner)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_Import)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}