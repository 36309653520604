
import { mapActions } from 'pinia';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import { useOverviewStore } from '@/plugins/store';
import { ItemWithAssociations } from '@/types';
import PublicItemImageGrid from '@/views/public/items/PublicItemImageGrid.vue';
import ItemPerformance from '@/components/common/ItemPerformance.vue';
import DownloadAppSnackbarPrompt from '@/views/public/items/DownloadAppSnackbarPrompt.vue';
import DownloadAppFullscreenPrompt from '@/views/public/items/DownloadAppFullscreenPrompt.vue';
import VideoPlayer from '@/components/common/VideoPlayer.vue';

export default defineComponent({
    components: { PublicItemImageGrid, ItemPerformance, DownloadAppSnackbarPrompt, DownloadAppFullscreenPrompt, VideoPlayer },
    data() {
        return {
            item: null as null | ItemWithAssociations,
            tagId: null as null | string,
            toast: useToast(),
            filterPerformanceCards: ['identifiers'],
            performanceCardSorting: [
                'materials',
                'recycled-upcycled-content',
                'labels',
                'design-for-circularity',
                'durability',
                'co2',
                'degassing',
                'waste-generated',
                'general-info',
                'design',
                'model',
                'identifiers',
                'documents',
            ],
        };
    },
    computed: {
        video(): string | undefined {
            return this.item?.product?.documentations?.find((currentDocumentation) => currentDocumentation.type === 'video')?.url;
        },
    },
    watch: {
        tagId: {
            immediate: true,
            handler(newValue: string | null) {
                if (newValue) {
                    document.title = `Micollect - ${newValue}`;
                }
            },
        },
    },
    async created() {
        if (!this.$route.params.id) {
            this.$router.push({ name: 'Items' });
            return;
        }
        this.tagId = `${this.$route.params.id}`;
        try {
            this.item = await this.getPublicItem(this.tagId as string);
        } catch (error) {
            let errorMessage;
            switch (error.response?.status) {
                case 404: {
                    errorMessage = 'Item not found';
                    break;
                }
                case 409: {
                    errorMessage = 'Multiple items with the same tag ID found';
                    break;
                }
                default: {
                    errorMessage = 'Something went wrong';
                    break;
                }
            }
            this.toast.error(errorMessage);
        }
    },
    methods: {
        ...mapActions(useOverviewStore, ['getPublicItem']),
        onExpandCardClicked() {
            if ((this.$refs['full-screen-prompt'] as any)?.forceShow) {
                (this.$refs['full-screen-prompt'] as any)?.forceShow();
            }
        },
    },
});
