
import { defineComponent } from 'vue';
import { ColDef } from 'ag-grid-community';
import GenericTable from '@/components/tables/GenericTable.vue';
import { ItemWithCompany } from '@/types';

export default defineComponent({
    components: { GenericTable },
    data() {
        return {
            headers: [
                { headerName: 'Tag ID', field: 'tagId', sortable: false },
                { headerName: 'Product no.', field: 'product.number', sortable: false },
                {
                    headerName: 'Production date',
                    field: 'signOff',
                    sortable: false,
                    cellRenderer: 'DateCellRenderer',
                },
                { headerName: 'Sign off person', field: 'signOffId', sortable: false },
                {
                    headerName: 'Ownership',
                    sortable: false,
                    field: 'owner',
                    valueFormatter: (params) => {
                        if (params.value?.name) {
                            return params.value.name;
                        }
                        if (params.value?.firstName && params.value?.lastName) {
                            return `${params.value.firstName} ${params.value.lastName}`;
                        }
                        return '';
                    },
                },
                { headerName: 'Order nr.', field: 'orderNo', sortable: false },
                { headerName: '', field: 'action', sortable: false, width: 20, cellRenderer: 'ActionsCellRenderer' },
            ] as ColDef[],
        };
    },
    methods: {
        onItemClicked(item: ItemWithCompany) {
            this.$router.push({ name: 'Item', params: { id: item.id } });
        },
    },
});
