import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block text-left w-full h-full" }
const _hoisted_2 = { class: "relative h-full" }
const _hoisted_3 = { class: "relative z-30 origin-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TrapFocus = _resolveComponent("TrapFocus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.slotContainerClass, "w-full"])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      _createVNode(_component_TrapFocus, {
        disabled: !_ctx.computedExpand
      }, {
        default: _withCtx(() => [
          _createVNode(_Transition, {
            "enter-active-class": "transform transition ease-out duration-100",
            "enter-from-class": "transform opacity-0 scale-0",
            "enter-to-class": "transform opacity-100 scale-100",
            "leave-active-class": "transition ease-in duration-100",
            "leave-class": "transform opacity-100 scale-100",
            "leave-to-class": "transform opacity-0 scale-0"
          }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  ref: "initialFocus",
                  tabindex: "0",
                  onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.computedExpand = false), ["esc"]))
                }, null, 544),
                _createElementVNode("div", {
                  class: _normalizeClass([[_ctx.dropdownClass, _ctx.positionClasses], "absolute rounded-md shadow-lg bg-white"]),
                  role: "menu",
                  "aria-orientation": "vertical",
                  "aria-labelledby": "options-menu"
                }, [
                  _createElementVNode("div", null, [
                    _renderSlot(_ctx.$slots, "dropdown-header")
                  ]),
                  _createElementVNode("div", {
                    class: "overflow-y-auto",
                    style: _normalizeStyle(_ctx.dropdownContentStyle)
                  }, [
                    _renderSlot(_ctx.$slots, "dropdown-content")
                  ], 4)
                ], 2)
              ], 512), [
                [_vShow, _ctx.computedExpand]
              ])
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["disabled"])
    ]),
    _withDirectives(_createElementVNode("div", {
      style: {"background-color":"rgba(0, 0, 0, 0.1)"},
      class: "cursor-default z-20 fixed inset-0",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.computedExpand = false))
    }, null, 512), [
      [_vShow, _ctx.computedExpand]
    ])
  ]))
}