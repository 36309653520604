
import { computed, defineComponent, ref } from 'vue';
import { useToast } from 'vue-toastification';
import useSubmissionState from '@/util/composables/useSubmissionState';
import { useUserStore } from '@/plugins/store';
import CustomInput from '@/components/ui/CustomInput.vue';

export default defineComponent({
    components: { CustomInput },
    setup() {
        const [userStore, toast] = [useUserStore(), useToast()];

        const userEmail = ref('');
        const validForm = computed(() => Boolean(userEmail.value));

        const submissionState = useSubmissionState(async () => {
            try {
                await userStore.requestPasswordReset({ payload: { email: userEmail.value } });
                toast.success('Password reset email has been sent');
            } catch (error) {
                if (error.message.includes('auth/invalid-email')) {
                    toast.error('Invalid email');
                } else if (error.message.includes('auth/user-not-found')) {
                    toast.error('User not found');
                } else if (error.message.includes('auth/auth/too-many-requests')) {
                    toast.error('Too many requests');
                } else {
                    toast.error('Something went wrong');
                }
            }
        });

        return { userEmail, submissionState, validForm };
    },
});
