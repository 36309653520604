
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useMainStore, useUserStore } from '@/plugins/store';
import DropDown from '@/components/common/DropDown.vue';

export default defineComponent({
    components: { DropDown },
    data() {
        return {
            expandDropdown: false,
        };
    },
    computed: {
        ...mapState(useMainStore, ['isCompanyAdmin', 'profile']),
        ...mapState(useUserStore, ['impersonatingUser']),
        settings() {
            const result = [];
            if (this.isCompanyAdmin) {
                result.push(
                    ...[
                        {
                            name: 'Company settings',
                            type: 'link',
                            to: { name: 'Settings', hash: '#company' },
                        },
                        {
                            name: 'Enterprise management',
                            type: 'link',
                            to: { name: 'Settings', hash: '#users' },
                        },
                    ]
                );
            }
            result.push({
                name: 'Personal profile',
                type: 'link',
                to: { name: 'Settings', hash: '#profile' },
            });
            if (this.impersonatingUser) {
                result.push({
                    name: 'Stop impersonating',
                    type: 'action',
                    action: () => {
                        this.stopImpersonation();
                    },
                });
            }
            return result;
        },
    },
    created() {
        useMainStore().getProfile();
    },
    methods: {
        ...mapActions(useUserStore, ['stopImpersonation']),
    },
});
