import { ref } from 'vue';

export default function useSubmissionState() {
    const importTexts = ref({
        documentations: {
            name: 'documents',
            created: 'imported',
            updated: 'updated',
        },
        items: {
            name: 'items',
            created: 'created',
            updated: 'updated',
        },
        products: {
            name: 'product models',
            created: 'created',
            updated: 'updated',
        },
        materials: {
            name: 'bill of materials',
            created: 'imported',
            updated: 'updated',
        },
        ownerships: {
            name: 'ownerships',
            created: 'registered',
            updated: 'updated',
        },
        traceability: {
            name: 'traceabilities',
            created: 'created',
            updated: 'updated',
        },
    });

    return { importTexts };
}
