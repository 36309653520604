
import { defineComponent } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { mapState } from 'pinia';
import { routes } from '@/plugins/router/routes';
import { SideBarNavigationGroup } from '@/types';
import Company from '@/components/layouts/dashboard/sideBar/Company.vue';
import { useUserStore, useMainStore } from '@/plugins/store';

export default defineComponent({
    components: { Company },
    computed: {
        ...mapState(useMainStore, ['profile', 'isSuperAdmin', 'companyIsBrandOwner']),
        sideBarRoutes(): RouteRecordRaw[] {
            return routes
                .flatMap((currentRoute) => this.flattenRoutes(currentRoute))
                .filter(
                    (currentRoute) =>
                        Boolean(currentRoute.meta?.sideBarNavigationGroup) &&
                        (currentRoute.meta?.requiresRoles === undefined || (Array.isArray(currentRoute.meta?.requiresRoles) && currentRoute.meta?.requiresRoles?.includes(this.profile?.role))) &&
                        (typeof currentRoute.meta?.requiresMainStoreGetter !== 'string' || (useMainStore() as any)[currentRoute.meta?.requiresMainStoreGetter])
                );
        },
        routeGroups(): { id: SideBarNavigationGroup; name: string; children: RouteRecordRaw[] }[] {
            const result = [
                {
                    id: SideBarNavigationGroup.Import,
                    name: 'Import',
                    children: this.sideBarRoutes.filter((currentSideBarRoute) => currentSideBarRoute.meta?.sideBarNavigationGroup === SideBarNavigationGroup.Import),
                },
                {
                    id: SideBarNavigationGroup.Overview,
                    name: 'Manage (overview)',
                    children: this.sideBarRoutes.filter((currentSideBarRoute) => currentSideBarRoute.meta?.sideBarNavigationGroup === SideBarNavigationGroup.Overview),
                },
                {
                    id: SideBarNavigationGroup.Insights,
                    name: 'Learn (insights)',
                    children: this.sideBarRoutes.filter((currentSideBarRoute) => currentSideBarRoute.meta?.sideBarNavigationGroup === SideBarNavigationGroup.Insights),
                },
            ];
            if (this.isSuperAdmin) {
                result.push({
                    id: SideBarNavigationGroup.Administrate,
                    name: 'Administrate',
                    children: this.sideBarRoutes.filter((currentSideBarRoute) => currentSideBarRoute.meta?.sideBarNavigationGroup === SideBarNavigationGroup.Administrate),
                });
            }
            return result.filter((currentRouteGroup) => currentRouteGroup.children.length > 0);
        },
    },
    methods: {
        flattenRoutes(route: RouteRecordRaw): RouteRecordRaw | RouteRecordRaw[] {
            if (!route.children) {
                return route;
            }
            return [route, ...route.children.flatMap((currentRoute) => this.flattenRoutes(currentRoute))];
        },
        onLogoutClicked() {
            useUserStore().signOut();
        },
        routeIsActive(route: RouteRecordRaw) {
            return this.$route.name === route.name || this.$route.meta.parentRouteName === route.name;
        },
    },
});
