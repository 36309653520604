import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between flex-wrap" }
const _hoisted_2 = { class: "space-y-4" }
const _hoisted_3 = { class: "flex flex-col space-y-4 lg:flex lg:flex-row lg:space-x-4 lg:space-y-0" }
const _hoisted_4 = { class: "text-1.5xl font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlgoliaSearchInput = _resolveComponent("AlgoliaSearchInput")!
  const _component_AlgoliaFilterPicker = _resolveComponent("AlgoliaFilterPicker")!
  const _component_AlgoliaChosenFilters = _resolveComponent("AlgoliaChosenFilters")!
  const _component_AisStats = _resolveComponent("AisStats")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AlgoliaSearchInput, { "place-holder": "Search in items" }),
        _createVNode(_component_AlgoliaFilterPicker, {
          "hide-facet-type-index": _ctx.hideFacetTypeIndex,
          "initial-filters": _ctx.initialFilters,
          "facet-types": _ctx.facetTypes
        }, null, 8, ["hide-facet-type-index", "initial-filters", "facet-types"])
      ]),
      _createVNode(_component_AlgoliaChosenFilters, {
        "facet-texts": _ctx.facetTexts,
        onFilterDeactivated: _ctx.onFilterDeactivated
      }, null, 8, ["facet-texts", "onFilterDeactivated"]),
      _createVNode(_component_AisStats, { "class-names": {
                    'ais-Stats': 'h-full',
                } }, {
        default: _withCtx(({ nbHits }) => [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(nbHits) + " items found", 1)
        ]),
        _: 1
      })
    ])
  ]))
}