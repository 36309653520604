
import { defineComponent } from 'vue';
import * as sanitizeHtml from 'sanitize-html';

export default defineComponent({
    props: {
        icon: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        infoPoints: {
            type: Array as () => { id: string; title: string; content: any }[],
            default: [],
        },
        isPublic: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['expand-clicked'],
    computed: {
        filteredInfoPoints() {
            if (this.isPublic) {
                return this.infoPoints.filter((currentInfoPoint) => currentInfoPoint.title && (currentInfoPoint.content === 0 || currentInfoPoint.content)).slice(0, 1);
            }
            return this.infoPoints.filter((currentInfoPoint) => currentInfoPoint.title && (currentInfoPoint.content === 0 || currentInfoPoint.content));
        },
    },
    methods: {
        sanitizeHtml: sanitizeHtml as unknown as Function,
    },
});
