import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["tabIndex"]
const _hoisted_2 = ["tabIndex"]
const _hoisted_3 = ["tabIndex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      tabIndex: _ctx.disabled ? -1 : 0,
      style: _normalizeStyle(_ctx.hidden)
    }, null, 12, _hoisted_1),
    _createElementVNode("div", {
      tabIndex: _ctx.disabled ? -1 : 1,
      style: _normalizeStyle(_ctx.hidden)
    }, null, 12, _hoisted_2),
    _createElementVNode("div", {
      "data-lock": "",
      onFocusout: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 32),
    _createElementVNode("div", {
      tabIndex: _ctx.disabled ? -1 : 0,
      style: _normalizeStyle(_ctx.hidden)
    }, null, 12, _hoisted_3)
  ]))
}