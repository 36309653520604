
import { defineComponent } from 'vue';
import { mapState, mapWritableState } from 'pinia';
import { ProductWithReferences } from '@/types';
import { useOverviewStore } from '@/plugins/store';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';

export default defineComponent({
    components: { ExpandCollapseTransition },
    props: {
        product: {
            type: Object as () => ProductWithReferences,
            required: true,
        },
    },
    data() {
        return {
            expandedSections: {} as { [key: string]: boolean },
        };
    },
    computed: {
        ...mapWritableState(useOverviewStore, ['activeProduct']),
        sections() {
            let scope;
            switch (this.$props.product.co2Scope) {
                case '1':
                    scope = '1';
                    break;
                case '2':
                    scope = '1 - 2';
                    break;
                case '3':
                    scope = '1 - 3';
                    break;
                default:
                    scope = null;
            }
            return [
                {
                    name: 'Product details',
                    icon: '/assets/icons/ic_general_info.svg',
                    dataPoints: [
                        {
                            key: 'Product name',
                            value: this.product.name,
                        },
                        {
                            key: 'Primary material',
                            value: this.product.primaryMaterial && this.product.primaryMaterialVariant ? `${this.product.primaryMaterial}, ${this.product.primaryMaterialVariant}` : '',
                        },
                        {
                            key: 'Secondary material',
                            value: this.product.secondaryMaterial && this.product.secondaryMaterialVariant ? `${this.product.secondaryMaterial}, ${this.product.secondaryMaterialVariant}` : '',
                        },
                        {
                            key: 'Product number',
                            value: this.product.number,
                        },
                        {
                            key: 'EAN ID:',
                            value: this.product.GTIN,
                        },
                    ],
                },
                {
                    name: 'Weight and dimensions',
                    icon: '/assets/icons/ic_weight.svg',
                    dataPoints: [
                        {
                            key: 'Product weight',
                            value: this.product.weight && this.product.weightUnit ? `${this.product.weight} ${this.product.weightUnit}` : '',
                        },
                        {
                            key: 'Packaging weight',
                            value: this.product.weightPacking && this.product.weightPackingUnit ? `${this.product.weightPacking} ${this.product.weightPackingUnit}` : '',
                        },
                        {
                            key: 'Product dimensions',
                            value: this.product.dimensionsDiameter && this.product.dimensionsDiameterUnit ? `${this.product.dimensionsDiameter} ${this.product.dimensionsDiameterUnit}` : '',
                        },
                        {
                            key: 'Product height',
                            value: this.product.dimensionsHeight && this.product.dimensionsHeightUnit ? `${this.product.dimensionsHeight} ${this.product.dimensionsHeightUnit}` : '',
                        },
                        {
                            key: 'Product width',
                            value: this.product.dimensionsWidth && this.product.dimensionsWidthUnit ? `${this.product.dimensionsWidth} ${this.product.dimensionsWidthUnit}` : '',
                        },
                        {
                            key: 'Product depth',
                            value: this.product.dimensionsDepth && this.product.dimensionsDepthUnit ? `${this.product.dimensionsDepth} ${this.product.dimensionsDepthUnit}` : '',
                        },
                    ],
                },
                {
                    name: 'Design',
                    icon: '/assets/icons/ic_design.svg',
                    dataPoints: [
                        {
                            key: 'Designer',
                            value: this.product.designerName,
                        },
                        {
                            key: 'Design year',
                            value: this.product.designYear,
                        },
                    ],
                },
                {
                    name: 'Sustainability',
                    icon: '/assets/icons/ic_sustainability.svg',
                    dataPoints: [
                        {
                            key: 'CO2-e',
                            value: this.product.co2Total && this.product.co2TotalUnit ? `${this.product.co2Total} ${this.product.co2TotalUnit}` : '',
                        },
                        {
                            key: 'CO2 scope',
                            value: scope,
                        },
                        {
                            key: 'CO2 date',
                            value: this.product.co2Date ? (this as any).$format(new Date(this.product.co2Date), 'dd.MM.yyyy') : '',
                        },
                        {
                            key: 'CO2 method',
                            value: this.product.co2Method,
                        },
                        {
                            key: 'Degassing class',
                            value: this.product.degassingClass,
                        },
                        {
                            key: `TVOC${this.product.TVOCNumber || ''}`,
                            value: this.product.TVOC && this.product.TVOCUnit ? `${this.product.TVOC} ${this.product.TVOCUnit}` : '',
                        },
                        {
                            key: 'Formaldehyde',
                            value: this.product.formaldehyde && this.product.formaldehydeUnit ? `${this.product.formaldehyde} ${this.product.formaldehydeUnit}` : '',
                        },
                        {
                            key: 'Waste',
                            value: this.product.waste && this.product.wasteUnit ? `${this.product.waste} ${this.product.wasteUnit}` : '',
                        },
                        {
                            key: 'Waste description',
                            value: this.product.wasteDescription,
                        },
                        {
                            key: 'Durability',
                            value: this.product.durability && this.product.durabilityUnit ? `${this.product.durability} ${this.product.durabilityUnit}` : '',
                        },
                    ],
                },
                {
                    name: 'Circularity',
                    icon: '/assets/icons/ic_design_for_circularity.svg',
                    dataPoints: [
                        {
                            key: 'Design for reduce',
                            value: this.product.reducable ? 'Yes' : 'No',
                        },
                        {
                            key: 'Design for reuse',
                            value: this.product.reusable ? 'Yes' : 'No',
                        },
                        {
                            key: 'Design for recycle',
                            value: this.product.recyclable ? 'Yes' : 'No',
                        },
                        {
                            key: 'Design for recover',
                            value: this.product.recoverable ? 'Yes' : 'No',
                        },
                    ],
                },
            ].map((currentSection) => {
                return { ...currentSection, dataPoints: currentSection.dataPoints.filter((currentDataPoint) => currentDataPoint.value) };
            });
        },
    },
    methods: {
        onItemsClicked() {
            this.activeProduct = this.product;
        },
    },
});
