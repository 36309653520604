import { ref, watch, computed } from 'vue';
/* eslint-disable-next-line */
import { history } from 'instantsearch.js/es/lib/routers';
import { useRouter } from 'vue-router';
import { useMainStore } from '@/plugins/store';

export default function useAlgoliaHistoryMode(indexName: string, routeRegex: RegExp, hasPagination: boolean) {
    const [mainStore, router] = [useMainStore(), useRouter()];
    const profile = computed(() => mainStore.profile);
    const routing = ref<any>(null);

    watch(
        profile,
        (newValue) => {
            if (!newValue || routing.value) {
                return;
            }
            const prefixedIndexedName = `${newValue.keys.algolia.prefix}${indexName}`;

            routing.value = {
                router: history({
                    /* Push method is a new feature from vue-instantsearch 4.8.8, but their type definitions aren't up-to-date yet */
                    push: (url: string) => {
                        router.push(url);
                    },
                    createURL: ({ routeState, location }) => {
                        // If we do not create an exception, the Algolia router will override the Vue router and consume all nagivations from this view
                        if (!routeRegex.test(location.href)) {
                            return '';
                        }
                        const refinements = routeState.filters as { [key: string]: string[] };
                        const filtersString = Object.keys(refinements).length
                            ? `filters=${Object.keys(refinements)
                                .map((key) => {
                                    return `${key}:${encodeURIComponent(refinements[key].join('_'))}`;
                                })
                                .join('|')}`
                            : '';
                        const searchString = routeState.query ? `query=${routeState.query}` : '';
                        const pageString = hasPagination && routeState.page ? `page=${routeState.page}` : '';
                        const resultQueries = [filtersString, searchString, pageString].filter((currentQuery) => currentQuery).join('&');
                        return `${location.pathname}?${resultQueries}`;
                    },
                    parseURL({ qsModule, location }) {
                        const queryParams = qsModule.parse(location.search.slice(1));
                        const refinementList: { [key: string]: string[] } = {};

                        if (queryParams.filters) {
                            (queryParams.filters as string).split('|').forEach((filter) => {
                                const [key, value] = filter.split(':');
                                refinementList[key] = decodeURIComponent(value).split('_');
                            });
                        }
                        const result = {
                            filters: refinementList,
                            query: queryParams.query || '',
                            ...(hasPagination && { page: queryParams.page || 1 }),
                        };
                        return result as any;
                    },
                }),
                stateMapping: {
                    stateToRoute(uiState: any) {
                        const routeState = {
                            filters: uiState[prefixedIndexedName]?.refinementList || [],
                            query: uiState[prefixedIndexedName]?.query || '',
                            ...(hasPagination && { page: uiState[prefixedIndexedName]?.page || 1 }),
                        };
                        return routeState;
                    },
                    routeToState(routeState: any) {
                        const uiState = {
                            [prefixedIndexedName]: {
                                refinementList: routeState.filters || [],
                                query: routeState.query || '',
                                ...(hasPagination && { page: routeState.page || 1 }),
                            },
                        };
                        return uiState;
                    },
                },
            };
        },
        { immediate: true }
    );

    return { routing };
}
