import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserForm = _resolveComponent("UserForm")!

  return (_openBlock(), _createBlock(_component_UserForm, {
    "can-edit-role": false,
    "submit-button-text": "Update profile",
    "confirmation-modal-header": "Update profile?",
    "confirmation-modal-body": "Are you sure you wish to edit your profile?",
    user: _ctx.profile,
    onSubmit: _ctx.onSubmit
  }, null, 8, ["user", "onSubmit"]))
}