
import { defineComponent } from 'vue';

/* Stolen from https://tailwindcss.com/docs/animation */

export default defineComponent({
    props: {
        active: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:value'],
});
