
import { defineComponent } from 'vue';
import DropDown from '@/components/common/DropDown.vue';

export default defineComponent({
    components: { DropDown },
    props: {
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array,
            default: [],
        },
        itemText: {
            type: String,
            required: true,
        },
        itemValue: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
        width: {
            type: String,
            default: 'w-52',
        },
    },
    data() {
        return {
            uid: `input-${Math.random().toString(36).substring(6)}`,
            expandDropdown: false,
        };
    },
    computed: {
        computedValue: {
            get(): any {
                return this.$props.value;
            },
            set(newValue: number) {
                this.$emit('update:value', newValue);
            },
        },
    },
    methods: {
        onItemClicked(item: any) {
            this.computedValue = item;
            this.expandDropdown = false;
        },
    },
});
