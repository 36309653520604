
import { defineComponent } from 'vue';
import sanitizeHtml from 'sanitize-html';

export default defineComponent({
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        placeholder: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: null,
        },
        autocomplete: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showRequiredIndication: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uid: `input-${Math.random().toString(36).substring(6)}`,
        };
    },
    computed: {
        computedValue: {
            get(): number | string {
                return this.$props.value;
            },
            set(newValue: number) {
                this.$emit('update:value', newValue);
            },
        },
    },
    methods: {
        sanitizeHtml: sanitizeHtml as unknown as Function,
    },
});
