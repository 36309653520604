
import { defineComponent, unref } from 'vue';
import { mapActions, mapState } from 'pinia';
import { useImportsStore } from '@/plugins/store';
import useImportTexts from '@/views/dashboard/import/data/useImportTexts';

export default defineComponent({
    setup() {
        const { importTexts } = useImportTexts();
        return { importTexts: unref(importTexts) };
    },
    computed: {
        ...mapState(useImportsStore, ['imports']),
        sortedImports() {
            return (this.imports || []).sort((a, b) => {
                return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
            });
        },
    },
    created() {
        this.getImports();
    },
    methods: {
        ...mapActions(useImportsStore, ['getImports', 'downloadImport']),
    },
});
