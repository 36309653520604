
import { defineComponent, unref } from 'vue';
import sanitizeHtml from 'sanitize-html';
import { ExcelImportType, UploadDataImportResults } from '@/types';
import ExpandCollapseTransition from '@/components/common/ExpandCollapseTransition.vue';
import useImportTexts from '@/views/dashboard/import/data/useImportTexts';

export default defineComponent({
    components: { ExpandCollapseTransition },
    props: {
        uploadResults: {
            type: Object as () => UploadDataImportResults,
            required: true,
        },
        type: {
            type: String as () => ExcelImportType,
            required: true,
        },
    },
    emits: ['confirm-import', 'error-detected', 'success-detected', 'reset'],
    setup() {
        const { importTexts } = useImportTexts();
        return { importTexts: unref(importTexts) };
    },
    data() {
        return {
            expandedErrors: {} as { [key: string]: boolean },
        };
    },
    computed: {
        numberOfTotalErrorTypes(): number {
            if (!this.sheets) {
                return 0;
            }
            return Object.values(this.sheets).reduce((accum: number, current: any) => {
                accum += Object.keys(current.numberOfValidationErrors).length;
                return accum;
            }, 0);
        },
        sheets(): any {
            if (!this.uploadResults) {
                return null;
            }
            const validations =
                this.$props.type === ExcelImportType.Tags
                    ? [{ sheetName: 'Tag', ingestName: 'tags' }]
                    : [
                          { sheetName: 'Product', ingestName: 'products' },
                          { sheetName: 'Documentation', ingestName: 'documentations' },
                          { sheetName: 'Item', ingestName: 'items' },
                          { sheetName: 'Bill of Materials', ingestName: 'materials' },
                          { sheetName: 'Ownership', ingestName: 'ownerships' },
                          { sheetName: 'Traceability', ingestName: 'traceability' },
                      ];

            const validationErrors = validations.map((currentValidation) => {
                const sheetValidation = this.uploadResults!.validations.find((currentSheetValidation) => currentSheetValidation.sheet === currentValidation.sheetName);
                const numberOfSheetValidationErrors = sheetValidation!.validation.numberOfRuleViolations;
                const sheetRowErrors = sheetValidation?.validation.rowViolations;
                const ingestValidation = this.uploadResults?.effects
                    ? this.uploadResults?.effects[currentValidation.ingestName as 'products' | 'documentations' | 'items' | 'materials' | 'ownerships' | 'tags' | 'traceability']
                    : null;
                const numberOfIngestValidations = ingestValidation?.importValidationViolations.numberOfRuleViolations;
                const ingestRowErrors = ingestValidation?.importValidationViolations.rowViolations;
                const totalRowErrors = this.mergeRowErrors(sheetRowErrors, ingestRowErrors);

                return {
                    sheetName: currentValidation.sheetName,
                    ingestName: currentValidation.ingestName,
                    effectCounters: ingestValidation?.counter,
                    numberOfValidationErrors: this.mergeNumberErrors(numberOfSheetValidationErrors, numberOfIngestValidations),
                    rowErrors: totalRowErrors,
                    numberOfRowErrors: Object.keys(totalRowErrors || {}).length,
                };
            });
            return validationErrors;
        },
    },
    watch: {
        numberOfTotalErrorTypes: {
            immediate: true,
            handler(newValue: number) {
                if (newValue === 0) {
                    this.$emit('success-detected');
                } else {
                    this.$emit('error-detected');
                }
            },
        },
    },
    methods: {
        mergeRowErrors(a: { [key: string]: { [key: string]: string } } | undefined, b: { [key: string]: { [key: string]: string } } | undefined) {
            if (!a && !b) {
                return {};
            }
            if (!a) {
                return b;
            }
            if (!b) {
                return a;
            }
            const result: { [key: string]: { [key: string]: string } } = {};
            Object.keys(a).forEach((currentKey) => {
                if (b.currentKey) {
                    result[currentKey] = { ...a[currentKey], ...b[currentKey] };
                } else {
                    result[currentKey] = a[currentKey];
                }
            });
            Object.keys(b).forEach((currentKey) => {
                if (!result[currentKey]) {
                    result[currentKey] = b[currentKey];
                }
            });
            return result;
        },
        mergeNumberErrors(a: { [key: string]: number } | undefined, b: { [key: string]: number } | undefined) {
            if (!a && !b) {
                return {};
            }
            if (!a) {
                return b;
            }
            if (!b) {
                return a;
            }
            const result: { [key: string]: number } = {};
            Object.keys(a).forEach((currentKey) => {
                if (b.currentKey) {
                    result[currentKey] = a[currentKey] + b[currentKey];
                } else {
                    result[currentKey] = a[currentKey];
                }
            });
            Object.keys(b).forEach((currentKey) => {
                if (!result[currentKey]) {
                    result[currentKey] = b[currentKey];
                }
            });
            return result;
        },
        filterRowErrors(errors: { [key: string]: { [key: string]: any } }, errorType: string) {
            return Object.keys(errors).reduce((accum, current) => {
                if (errors[current][errorType]) {
                    accum[current] = `${errorType}: ${errors[current][errorType]}`;
                }
                return accum;
            }, {} as { [key: string]: any });
        },
        sanitizeHtml,
    },
});
