
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        steps: {
            type: Number,
            default: 1,
        },
        activeStep: {
            type: Number,
            default: 0,
        },
    },
});
