
import { defineComponent } from 'vue';
import { ColDef } from 'ag-grid-community';
import { mapActions, mapState } from 'pinia';
import GenericTable from '@/components/tables/GenericTable.vue';
import { useMainStore, useUserStore } from '@/plugins/store';
import { Profile } from '@/types';
import formatUserRole from '@/util/helpers/formatUserRole';
import InitialsCellRenderer from '@/components/tables/InitialsCellRenderer.vue';
import CustomButton from '@/components/ui/CustomButton.vue';
import InviteCompanyUserModal from '@/views/dashboard/settings/modals/InviteCompanyUserModal.vue';

type TableFormattedUser = {
    id: string;
    initials: string;
    fullName: string;
    role: string | null;
    email: string | null;
    phone: string | null;
};

export default defineComponent({
    /* eslint-disable vue/no-unused-components */
    components: { GenericTable, InitialsCellRenderer, CustomButton, InviteCompanyUserModal },
    data() {
        return {
            headers: [
                { headerName: '', field: 'initials', width: 60, cellRenderer: 'InitialsCellRenderer', sortable: false },
                {
                    headerName: 'Name',
                    field: 'fullName',
                    sortable: true,
                },
                { headerName: 'Role', field: 'role', sortable: true },
                { headerName: 'E-mail', field: 'email', sortable: true },
                { headerName: 'Phone', field: 'phone', sortable: true },
                { headerName: '', field: 'action', sortable: false, width: 20, cellRenderer: 'ActionsCellRenderer' },
            ] as ColDef[],
        };
    },
    computed: {
        ...mapState(useMainStore, ['profile']),
        ...mapState(useUserStore, ['companyUsers']),
        items(): TableFormattedUser[] {
            return (
                this.companyUsers?.map((currentCompanyUser) => {
                    let initials = currentCompanyUser.firstName ? currentCompanyUser.firstName.charAt(0) : '';
                    initials += currentCompanyUser.lastName ? currentCompanyUser.lastName.charAt(0) : '';
                    let fullName = currentCompanyUser.firstName ? currentCompanyUser.firstName : '';
                    if (currentCompanyUser.firstName && currentCompanyUser.lastName) fullName += ' ';
                    fullName += currentCompanyUser.lastName ? currentCompanyUser.lastName : '';
                    return {
                        initials,
                        fullName,
                        role: formatUserRole(currentCompanyUser.role),
                        email: currentCompanyUser.email,
                        phone: currentCompanyUser.phone,
                        id: currentCompanyUser.id,
                    };
                }) || []
            );
        },
    },
    watch: {
        profile: {
            immediate: true,
            deep: true,
            handler(newValue: Profile) {
                if (!newValue || !newValue.companyId) {
                    return;
                }
                this.getCompanyUsers(newValue.companyId);
            },
        },
    },
    created() {
        this.getProfile();
    },
    methods: {
        ...mapActions(useMainStore, ['getProfile']),
        ...mapActions(useUserStore, ['getCompanyUsers']),
        onUserClicked(user: TableFormattedUser) {
            this.$router.push({ name: 'User', params: { id: user.id } });
        },
    },
});
