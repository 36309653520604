import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentationList = _resolveComponent("DocumentationList")!

  return (_ctx.item.product?.documentations)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_DocumentationList, {
          documentations: _ctx.item.product.documentations
        }, null, 8, ["documentations"])
      ]))
    : _createCommentVNode("", true)
}