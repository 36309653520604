
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        params: {
            type: Object as () => { valueFormatted?: any; value: any },
            required: true,
        },
    },
});
