
import { computed, defineComponent, ref, watch, WritableComputedRef } from 'vue';
import TrapFocus from '@/components/common/TrapFocus.vue';
import useEscapable from '@/util/composables/useEscapable';

export default defineComponent({
    components: { TrapFocus },
    props: {
        expand: {
            type: Boolean,
            default: false,
        },
        dropdownClass: {
            type: String,
            default: '',
        },
        dropdownContentStyle: {
            type: String,
            default: '',
        },
        slotContainerClass: {
            type: String,
            default: '',
        },
        topbarOverlay: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String as () => 'left' | 'right',
            default: 'right',
        },
    },
    emits: ['update:expand'],
    setup(props, context) {
        const initialFocus = ref(null);
        const computedExpand: WritableComputedRef<boolean> = computed({
            get(): boolean {
                return props.expand;
            },
            set(newValue: boolean): void {
                context.emit('update:expand', newValue);
            },
        });
        const close = () => {
            computedExpand.value = false;
        };
        const positionClasses = computed(() => {
            if (props.position === 'right') {
                return 'origin-top-right right-0';
            }
            return 'origin-top-left left-0';
        });
        watch(
            () => props.expand,
            (newValue) => {
                if (newValue) {
                    (initialFocus.value as unknown as HTMLDivElement).focus();
                }
            }
        );

        const { onUnmounted } = useEscapable(close);
        return { onUnmounted, computedExpand, initialFocus, positionClasses };
    },
});
