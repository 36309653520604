import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-semibold flex rounded-lg border-default hover:bg-grey-200/30 px-3 py-4" }
const _hoisted_2 = { class: "my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}