import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex h-0.75" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (n) => {
      return (_openBlock(), _createElementBlock("div", {
        key: n,
        class: _normalizeClass(["h-full w-full transition-all duration-400", [{ 'bg-black': n === _ctx.activeStep + 1 }, { 'bg-gray-200': n !== _ctx.activeStep + 1 }]])
      }, null, 2))
    }), 128))
  ]))
}