import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '@/plugins/firebase';

export default class FirebaseClient {
    public static signIn(email: string, password: string) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    public static signOut() {
        return signOut(auth);
    }

    public static register(email: string, password: string) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    public static requestPasswordReset(email: string) {
        return sendPasswordResetEmail(auth, email);
    }
}
