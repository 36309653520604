
import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { useMainStore } from '@/plugins/store';
import { CompanyType } from '@/types';

export default defineComponent({
    data() {
        return {
            expandDropdown: false,
        };
    },
    computed: {
        ...mapState(useMainStore, ['profile']),
        truncateClass(): string {
            return /[ |-]/.test(this.profile?.company?.name || '') ? 'line-clamp-2' : 'truncate';
        },
        homeRouteName(): string {
            return this.profile?.company?.type === CompanyType.BrandOwner ? 'Products' : 'Items';
        },
    },
    created() {
        // TODO: Navigate to /items if company is not brand owner
        useMainStore().getProfile();
    },
});
