
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import useSubmissionState from '@/util/composables/useSubmissionState';
import CustomInput from '@/components/ui/CustomInput.vue';
import CustomCheckbox from '@/components/ui/CustomCheckbox.vue';
import AuthLayout from '@/components/layouts/auth/AuthLayout.vue';
import { useUserStore } from '@/plugins/store';
import { UserRole } from '@/types';

export default defineComponent({
    components: { CustomInput, CustomCheckbox, AuthLayout },
    setup(props, context) {
        const [toast, route, userStore] = [useToast(), useRoute(), useUserStore()];
        const [termsOfUseLink, privacyPolicyLink] = [ref(process.env.VUE_APP_TERMS_OF_USE_LINK), ref(process.env.VUE_APP_PRIVACY_POLICY_LINK)];
        const [email, phone, firstName, lastName, role, sign, password, companyId, confirmPassword, acceptTermsAndConditions, validInviteLink, expire, disableForm] = [
            ref(''),
            ref(''),
            ref(''),
            ref(''),
            ref(''),
            ref(''),
            ref(''),
            ref(''),
            ref(''),
            ref(false),
            ref(false),
            ref<number | null>(null),
            ref(false),
        ];
        const validForm = computed(() => {
            return (
                !disableForm.value &&
                validInviteLink.value &&
                acceptTermsAndConditions.value &&
                Boolean(email.value) &&
                Boolean(phone.value) &&
                Boolean(firstName.value) &&
                Boolean(lastName.value) &&
                typeof password.value === 'string' &&
                password.value.length >= 8 &&
                password.value === confirmPassword.value
            );
        });

        const submissionState = useSubmissionState(async () => {
            try {
                await userStore.acceptInvitationToCompany(
                    {
                        email: email.value,
                        phone: phone.value,
                        firstName: firstName.value,
                        lastName: lastName.value,
                        companyId: companyId.value!,
                        role: role.value as UserRole,
                        expire: expire.value!,
                        sign: sign.value,
                        password: password.value,
                    },
                    { skipErrorReporting: true }
                );
                toast.success('You have successfully registered');
                disableForm.value = true;
            } catch (error) {
                switch (error.response?.status) {
                    case 422: {
                        toast.error('User already exists');
                        break;
                    }
                    case 403: {
                        toast.error('Link has expired or is invalid');
                        break;
                    }
                    default: {
                        toast.error('Something went wrong');
                    }
                }
            }
        });
        const requiredQueryParameters = ['companyId', 'companyName', 'role', 'email', 'sign', 'expire'];
        if (!route.query.data) {
            toast.error('Invalid invite link');
        } else {
            const data = atob(route.query.data as string);
            const matches = requiredQueryParameters.map((currentRequiresParam, index) => {
                const regex = new RegExp(`${currentRequiresParam}=(.*${index < requiredQueryParameters.length - 1 ? '?)&' : ')'}`);
                const match = data.match(regex);
                if (match) {
                    return match[1];
                }
                return null;
            });

            if (matches.some((match) => match === null)) {
                toast.error('Invalid invite link');
            } else if (![UserRole.CompanyAdmin, UserRole.Member].includes(matches[2] as string as UserRole) || Number.isNaN(parseInt(matches[5] as string))) {
                toast.error('Invalid invite link');
            } else {
                validInviteLink.value = true;
                email.value = matches[3] as string;
                role.value = matches[2] as string;
                sign.value = matches[4] as string;
                companyId.value = matches[0] as string;
                expire.value = parseInt(matches[5] as string);
            }
        }

        return { email, acceptTermsAndConditions, submissionState, validForm, phone, firstName, lastName, password, confirmPassword, termsOfUseLink, privacyPolicyLink };
    },
});
