import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full relative" }
const _hoisted_2 = { class: "space-y-2" }
const _hoisted_3 = { class: "text-left my-auto text-3xl whitespace-nowrap uppercase font-semibold max-w-60" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "absolute right-0 bottom-0 top-0" }
const _hoisted_6 = { class: "my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _renderSlot(_ctx.$slots, "header", {}, () => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.heading), 1),
        (_ctx.subheading)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "max-w-[70%] line-clamp-1",
              innerHTML: _ctx.sanitizeHtml(_ctx.subheading, { allowedAttributes: { '*': ['class'] } })
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "top-right")
      ])
    ])
  ]))
}