
import { defineComponent } from 'vue';
import { ColDef } from 'ag-grid-community';
import { mapActions, mapState } from 'pinia';
import { useToast } from 'vue-toastification';
import GenericTable from '@/components/tables/GenericTable.vue';
import { useCompaniesStore, useUserStore, useMainStore } from '@/plugins/store';
import InitialsCellRenderer from '@/components/tables/InitialsCellRenderer.vue';
import Card from '@/components/common/Card.vue';
import formatUserRole from '@/util/helpers/formatUserRole';
import { Company, UserRole } from '@/types';
import ConfirmationModal from '@/components/common/ConfirmationModal.vue';

export default defineComponent({
    /* eslint-disable vue/no-unused-components */
    components: { GenericTable, InitialsCellRenderer, Card, ConfirmationModal },
    data() {
        return {
            headers: [
                { headerName: '', field: 'initials', width: 60, cellRenderer: 'InitialsCellRenderer', sortable: false },
                {
                    headerName: 'Name',
                    field: 'fullName',
                    sortable: true,
                },
                { headerName: 'Role', field: 'role', sortable: true },
                { headerName: 'E-mail', field: 'email', sortable: true },
                { headerName: 'Phone', field: 'phone', sortable: true },
                { headerName: '', field: 'actions', width: 40, cellRenderer: 'ActionsCellRenderer', sortable: false },
            ] as ColDef[],
            activeCompanyUser: null as null | { [key: string]: any },
            toast: useToast(),
        };
    },
    computed: {
        ...mapState(useCompaniesStore, ['companies']),
        ...mapState(useMainStore, ['profile', 'isSuperAdmin']),
        switchToUserConfirmationModalBody(): string {
            return this.activeCompanyUser
                ? `Are you sure you wish to switch to <span class="font-semibold text-green-600">${this.activeCompanyUser.fullName}</span>?`
                : 'Are you sure you wish to switch to this user?';
        },
        companiesWithFormattedUsers(): (Company & { formattedUsers: { [key: string]: any } })[] {
            return (
                this.companies?.map((currentCompany) => {
                    return {
                        ...currentCompany,
                        formattedUsers: currentCompany.users.map((currentCompanyUser) => {
                            let initials = currentCompanyUser.firstName ? currentCompanyUser.firstName.charAt(0) : '';
                            initials += currentCompanyUser.lastName ? currentCompanyUser.lastName.charAt(0) : '';
                            let fullName = currentCompanyUser.firstName ? currentCompanyUser.firstName : '';
                            if (currentCompanyUser.firstName && currentCompanyUser.lastName) fullName += ' ';
                            fullName += currentCompanyUser.lastName ? currentCompanyUser.lastName : '';
                            return {
                                initials,
                                fullName,
                                role: formatUserRole(currentCompanyUser.role),
                                email: currentCompanyUser.email,
                                phone: currentCompanyUser.phone,
                                id: currentCompanyUser.id,
                            };
                        }),
                    };
                }) || []
            );
        },
    },
    created() {
        this.getCompanies();
    },
    methods: {
        ...mapActions(useCompaniesStore, ['getCompanies']),
        ...mapActions(useUserStore, ['impersonateUser']),
        onItemClicked(companyUser: { [key: string]: any }, open: CallableFunction) {
            this.activeCompanyUser = companyUser;
            open();
        },
        async onConfirmSwitchToUser() {
            if (!this.activeCompanyUser) {
                this.toast.error('No user has been selected. Please reload the page and try again.');
            }
            if (!this.isSuperAdmin) {
                this.toast.error('This action is only available to super admins');
            }
            this.impersonateUser(this.activeCompanyUser!.email);
        },
    },
});
