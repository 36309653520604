import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = {
  class: "space-x-10 border-b-default text-[18px]",
  style: {"padding-bottom":"1.5px"}
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: tab.id,
            class: _normalizeClass(["inline-block opacity-70 font-medium", [{ '!opacity-100 text-green-600': index === _ctx.currentActiveIndex }, _ctx.tabPadding]]),
            style: _normalizeStyle(index === _ctx.currentActiveIndex ? 'box-shadow: 0 4px 0px -0.5px #5e7a6f' : '')
          }, [
            (_ctx.createHashRoutes)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.onTabClicked(index))
                }, [
                  _renderSlot(_ctx.$slots, `tab-${tab.id}`, _normalizeProps(_guardReactiveProps({ isActive: index === _ctx.currentActiveIndex })), () => [
                    _renderSlot(_ctx.$slots, "tab", _normalizeProps(_guardReactiveProps(tab)), () => [
                      _createVNode(_component_router_link, {
                        to: { name: _ctx.parentHashRouteName, params: _ctx.parentHashRouteParams, hash: `#${tab.id}` }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tab.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ])
                  ])
                ], 8, _hoisted_3))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  onClick: ($event: any) => (_ctx.onTabClicked(index))
                }, [
                  _renderSlot(_ctx.$slots, `tab-${tab.id}`, {}, () => [
                    _renderSlot(_ctx.$slots, "tab", _normalizeProps(_guardReactiveProps(tab)), () => [
                      _createElementVNode("div", null, _toDisplayString(tab.name), 1)
                    ])
                  ])
                ], 8, _hoisted_4))
          ], 6))
        }), 128))
      ])
    ]),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tab.id
        }, [
          (_ctx.animate)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_Transition, { name: "tab-fade" }, {
                  default: _withCtx(() => [
                    (_ctx.currentActiveIndex === index)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _renderSlot(_ctx.$slots, `content-${tab.id}`, {}, () => [
                            _createElementVNode("div", null, _toDisplayString(`Tab #${index} (${tab.name}) content`), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.currentActiveIndex === index)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _renderSlot(_ctx.$slots, `content-${tab.id}`, {}, () => [
                        _createElementVNode("div", null, _toDisplayString(`Tab #${index} (${tab.name}) content`), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
        ]))
      }), 128))
    ])
  ]))
}